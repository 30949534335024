import App from '../../../App'
import {render, html} from 'lit/html.js'
import Data from '../../../Data'


class TopicView {
  init(){    
    document.title = 'Forum Topics'    
    this.render()  
  }


  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>
        <div class="content-panel">      
          
          <p class="app-info para">
            Select the topic of your choice.
          </p>

          ${Data.topics == null ? html`
            <br>
            <br>
            <div class="spinner sp-sml"></div>
          `:html`
            ${Data.topics.map(topic => html `
              <my-accordian accordianType="forum" accordianName="${topic.topicTitle}" dataId="${topic.topicId}"></my-accordian>
            `)}
          `}
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new TopicView()