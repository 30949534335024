[{
  "level": 1,
  "levelTitle": "Star",
  "color": "#ED5DED"
},{
  "level": 2,
  "levelTitle": "Boss",
  "color": "#CCB28F"
},{
  "level": 3,
  "levelTitle": "Chief",
  "color": "#4DDB68"
},{
  "level": 4,
  "levelTitle": "Champion",
  "color": "#E2E22B"
},{
  "level": 5,
  "levelTitle": "Master",
  "color": "#86A4F7"
},{
  "level": 6,
  "levelTitle": "Hero",
  "color": "#F7A014"
},{
  "level": 7,
  "levelTitle": "Elite",
  "color": "#24EACE"
},{
  "level": 8,
  "levelTitle": "Supreme Rival",
  "color": "#BC0AE8"
},{
  "level": 9,
  "levelTitle": "Grand Master",
  "color": "#FF0000"
},{
  "level": 10,
  "levelTitle": "Legend",
  "color": "#000000"
}]