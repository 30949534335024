import {LitElement, html} from 'lit'
import Utils from '../../Utils'
import Nstat from '../../Nstat'

customElements.define('cmi-editcasewarn', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: {
        type: String
      }
    }
  }

  firstUpdated(){
    super.firstUpdated()
  }

  showTab(){
    Nstat.showEditTab(4)
    Utils.closeModal()
  }

  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .mi-box { text-align: center; font-size: 1.1em; }
        .mi-text { margin-bottom: 30px;  width: 250px;  }

        .redirect-button {
          color: white;
          border: none;
          border-radius: 5px;
          padding: 7px;
          margin: 20px auto;
          text-decoration: none;
          width: 200px; 
          cursor: pointer;
          font-size: 1.1em;
        }

        .primary { background-color: var(--brand-color); }
        .action { background-color: var(--action-color) ; }
        .warning { background-color: red;  }
        .disabled { background-color: var(--disabled-color); cursor: none; }


        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }
        

      </style>
      <div class="mi-box">
          <p class="mi-text">
            ${this.modalType == "pprequired" ? html`
              Please update the perpetrator's position.
              <br><br>
            <div class="redirect-button action" @click=${this.showTab}>Show tab</div>
            <div class="redirect-button primary" @click=${Utils.closeModal.bind(Utils)}>Cancel</div>

            `:``}
            ${this.modalType == "nochanges" ? html`
              No changes were made.
              <br><br>
            <div class="redirect-button primary" @click=${Utils.closeModal.bind(Utils)}>Cancel</div>
            `:``}
          </p>
      </div>
    `
  }
  
})
