import App from './App.js'
import splash from './views/partials/splash'
import {html, render } from 'lit-html'
import Element from './functions/Element'

// components (custom web components)  
import './components/main-app-header'
import './components/side-widget'
import './components/feedback-widget'
import './components/lesson-nav'

import './components/board-users'
import './components/leader-level'

import './components/my-accordian'
import './components/stat-data-btn'
import './components/forum/forum-subtopics'
import './components/forum/forum-thread'
import './components/forum/forum-post' 
import './components/forum/forum-btn' 
import './components/forum/new-post'


//STATUS 
import './components/status/c-spinner'
import './components/status/c-error'
import './components/status/c-success'
import './components/status/c-waiting'
import './components/status/mi-notice'

//MODALS
import './components/modals/main-modal'
import './components/modals/mi-purchase'
import './components/modals/mi-prevquiz'
import './components/modals/mi-forum-rules'
import './components/modals/mi-privacy-policy'

import './components/modals/confirm-modal'
import './components/modals/cmi-editcasewarn'
import './components/modals/cmi-casefunction'

import './components/modals/cmi-purchase'
import './components/modals/cmi-submit'



// styles
import './scss/master.scss'

// app.init
Element.removeAllChildren(App.rootEl)
render(splash, App.rootEl)

document.addEventListener('DOMContentLoaded', () => {
  App.init()
})

window.addEventListener("resize", () => {
  App.resizeListener()
})



// all imports available to all other pages