import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class TodoView { 
  init(){    
    document.title = 'What to do'  
    this.currentlesson = 6 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }



  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/6-do.svg"> 
          </div>
          <section class="lesson-section">
            
            <p class="app-info para">
              The best thing to do depends on the type of assault and the options will vary depending on the situation and ability.   
              In this lesson we cover some general suggestions which have been recommended.
            </p>

            <p class="app-info para">
              <ol class="para">
                <li>Ongoing Abuse
                  <ul>
                    <li>Stuck in abuse</li>
                    <li>Sex trafficking/ sex slavery/sex coercion</li>
                    <li>Spouse abuse</li>
                    <li>Building a new life</li>
                  </ul>
                </li>
                <li>One time abuse
                  <ul>
                    <li>Rape</li>
                    <li>Covert (hidden) assault</li>
                    <li>Overpower assault</li>
                  </ul>
                </li>
                <li>Don’t blame yourself</li>
                <li>Trauma recovery
                  <ul>
                    <li>Therapy methods</li>
                  </ul>
                </li>
              </ol> 
            </p>


            <h2 class="para">ONGOING ABUSE</h2>

            <p class="app-info para">
              <strong>STUCK IN ABUSE</strong>
            </p>
            <p class="app-info para">
              If you are in a situation where you are unable to escape, it is very common for survivors to “go away” in their mind.  
              Imagining different scenarios or even pretending that the event is happening to another person where the survivor needs to help them through it. 
              Making up multiple personalities can help us to cope with horrible events that we cannot really deal with.  
              Some of us are also able to bring on amnesia (make ourselves forget) and block out the memory of the event so we don’t need to remember it.  
              But we have to try and make a plan, think about how we can get out. 
              Our escape day will come.
            </p>

            <p class="app-info para">
              <strong>SEX TRAFFICKING/ SEX SLAVERY/SEX COERCION</strong>
            </p>
            <p class="app-info para">
              The first thing to do is get out.  
              Never give up, keep looking for a way. Be smart and on the lookout for the right opportunity. 
              Such as faking extreme pain one day and asking for the hospital.  
              Keep planning and thinking how you can get away.
            </p>
            <p class="app-info para">
              When you are able to get out, look for organisations that support survivors.  
              They will help you get back on your feet and many survivors go on to help others who have been abused.
            </p>

            <p class="app-info para">
              <strong>SPOUSE ABUSE</strong>
            </p>
            <p class="app-info para">
              Breaking away from a loved one, or a controlling partner, can be very difficult. 
              And if you are in a strange country with language barriers it can make it even harder.  
              But be strong, you are special and deserve the utmost respect.  
              Make the decision to leave and plan it secretly.  
              Then when you have the support and strength, make the move.  
              In most marriages it is recommended to try to reconcile issues, however, if partner rape has become a part of daily life, then this abuse is not acceptable at all.  
              No one deserves to be abused.  
              You are worth so much more than that!
            </p>
            <p class="app-info para">
             *It has been recommended to pack an “emergency bag” (Really your “escape bag” for when you are ready to leave).  
              The “emergency bag” would contain important papers and medications etc. so that there is no need to return. 
              There should be one for you and possibly your partner to reduce suspicion.  
              Then when the time is right, you take a walk with it and don’t go back.
            </p>

            <p class="app-info para">
              <strong>BUILDING A NEW LIFE</strong>
            </p>
            <p class="app-info para">
              After breaking out of ongoing abuse, it is important that you surround yourself with positive people and rebuild not only your life, but also your self-esteem.  
              People who are abusive tend to use demoralising techniques to keep their victims under their control. 
              They will put us down constantly and it is hard for us sometimes not to think bad about ourselves and self-worth.  
              However, we are all special and deserve to be treated well. Yes, it is true, we make mistakes, and we ALL make mistakes.  
              But we can’t let that stop us from rising up, learning from our mistakes and becoming the wonderful people we really are.
            </p>

            <h2 class="para">ONE TIME ABUSE</h2>

            <p class="app-info para">
              <strong>RAPE</strong>
            </p>

            <p class="app-info para">
              <strong>Before rape:</strong>
              If you get the slightest feeling that someone might be about to attack you, try to get to safe area quickly.  
              Try to move to a place where there are other people, get out in the open (middle of the street) or quickly make a call and talk to someone until you reach safety.  
              Sometimes preventative measures can deter an attack.
            </p>

            <p class="app-info para">
              <strong>Pre-rape: </strong>
              If the attack has started and you have been grabbed, then try to prevent a full assault by trying to escape. 
              Hurting the attacker in a vulnerable place may give you time to get away:
              <ul class="para">
                <li>Try to kick him in the groins / or punch her in the breasts</li>
                <li>Hit or poke the attacker in a sensitive area like the eyes, nose or throat</li>
                <li>If attacked from behind, use the back of your shoes to scrape/gauge the length of his shin (this will be more painful with sharp high heels)</li>
              </ul> 
            </p>

            <p class="app-info para">
              <strong>Mid rape: </strong>
              If you were unable to break away from the attacker do your best to leave a mark or a scar.  
              This can be done be fiercely biting or scratching their skin etc.
            </p>

            <p class="app-info para">
              <strong>After rape: </strong>
              Survivors are recommended to report their attacks and take a few health checks.  
              Even though this can be very difficult, it may help prevent others from also being attacked.  
              But after an assault we feel so ashamed and humiliated that we can hardly cope with simple things, let alone reliving the torment.  
              But we are survivors and we can make a difference. 
            </p>

            <p class="app-info para">
              However, if you feel unable to report the rape, do not feel bad.  
              Only a few survivors are actually able to come out and report the incident. 
              For the rest of us it is just too humiliating.
            </p>

            <p class="app-info para">
              <strong>Checklist of recommended follow up steps</strong>
              <ul class="para">
                <li>Reporting the incident to the police:
                  <ul>
                    <li>If you are able to report the incident to the police immediately, there is a much higher chance that the attacker will be jailed as the evidence such as DNA will be fresh.</li>
                  </ul> 
                </li>
                <li>Health checks (Check your area for specialist doctors who assist survivors):
                  <ul>
                    <li>DNA samples to assist with police reports</li>
                    <li>Pregnancy</li>
                    <li>Sexually transmitted diseases</li>
                  </ul> 
                </li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              <strong>COVERT ASSAULT</strong>
            </p>

            <p class="app-info para">
              <strong>Hidden assault: </strong>
              There is generally no sign of a covert assault as a pre-warning like in the case of rape where you can see your attacker coming for you.  
              In many cases a covert attack will cause the survivor to even doubt that the attack is taking place.  
              But keep in mind our “one second” count, if someone is touching or stimulating a “sex button” for longer than it takes to sound out the phrase “one second” in your head, then pull the alarm bell.
            </p>

            <p class="app-info para">
              Some people will enter the "freeze" state where they cannot bring themselves to do anything.  
              This is a normal survival mechanism which many people experience.  
              However, if you are able to break out of the "freeze" state, try to say something to deter the perpetrator.  
              Tell them “It is hurting” or “You are hurting me”.  
              Any phrase which will interrupt the act is beneficial, such as pretending and saying you feel sick and want to vomit. 
              You could also try to cover your sensitive area with your hand.  
            </p>

            <p class="app-info para">
              <strong>Overpower assault:</strong>
              When you are put into a situation where you are clearly overpowered and demanded to perform certain actions, you may also enter a “freeze” like state called the “resign” state.  
              You are physically unable to object although your mind might be protesting and telling you to look for a way out.  
              Understand that this is a survival mechanism and that you are not responsible for your actions under these circumstances.  
              Don’t blame yourself and if needed seek counselling. 
            </p>

            
            <p class="app-info para">
              <strong>DON’T BLAME YOURSELF</strong>
            </p>

            <p class="app-info para">
              After being abused it is natural that we put blame on ourselves… “What did I do to deserve this?”  
              But always remember that no-one deserves to be abused in anyway, not physically, not mentally and not sexually.  
            </p>

            <p class="app-info para">
              Be positive and let us work together for a better future for everyone.
              We are “The Survivors”!
            </p>

            <p class="app-info para">
              <strong>TRAUMA RECOVERY</strong>
            </p>

            <p class="app-info para">
              After surviving a traumatic event, there will come a time when we need to process it and understand it from an “I’m safe now” perspective.  
              Sometimes this can be as difficult as the events themselves.  
              This is especially true in the case of flashbacks as every detail, thought and sensation is relived.  
              They do fade over time, but we need to learn to deal with them.  
              The healing process takes time and there are several ways we can go about it:
              <ol class="para">
                <li>Private therapy sessions with a specialised counsellor</li>
                <li>Group therapy sessions with a specialised moderator</li>
                <li>Talking to other survivors</li>
                <li>Talking to someone trusted</li>
              </ol> 
            </p>

            <p class="app-info para">
              Here we have a safe platform for survivors to talk to each other.  
              But if you feel you need specialised therapy, then you will need to contact an organisation that offers counselling for sexual assault.  
              However, helping others to deal with their traumatic experiences can also help our own recovery process.
            </p>

            <p class="app-info para">
              <strong>THERAPY METHODS</strong>
            </p>

            <p class="app-info para">
              Just like everybody has different ways of coping, they also have different ways of healing.  
              Here are a couple different techniques, depending on your needs and preferences you can see what works for you.
            </p>

            <p class="app-info para">
              <ol class="para">
                <li> <span> Cognitive Processing Therapy: </span>
                  This is where you identify your negative thought patterns (such as low self-esteem / deserving abuse etc.) and change them into positive thoughts. 
                  This is a rational method of restructuring your understanding of events.  
                </li>
                <li><span>Prolonged Exposure: </span>
                  This is where you revisit the traumatic experience continuously until you are desensitized to the impact it has on you.  
                  Following are some methods to achieve “prolonged exposure”:
                  <ul>
                    <li><span>Talk about it:</span> Sharing your experience with others.</li>
                    <li><span>Write about it:</span> Journaling your experiences and connected emotions.</li>
                    <li><span>Think about it:</span> Going over the event in your mind also contributes to this method.</li>
                  </ul>
                </li>
                <li><span>Trigger Reprocessing: </span>
                  For some survivors, there are events or places which trigger negative emotions or flashbacks. 
                  Trigger reprocessing involves thinking about the triggers while adding a new “safe” interpretation for the trigger. 
                  Reprogramming yourself to connect the new safe meanings or associations to the trigger can help you deal with triggers.
                </li>
                <li><span>Eye Movement Desensitisation and Reprocessing (EMDR) or tapping:</span>
                  This method is the same as the previous one; however, the eye movement is used to assist with the cognitive reprocessing of the trauma.  
                  Here the survivor will be told to watch the rapid finger movements of someone else, while they revisit their trauma following the movements from left to right.  
                  Eventually the survivor may disconnect themselves from the trauma using this method.  
                  Likewise tapping the body (left and right arm) to break the physical memory stored from the event can also contribute to the healing process.
                </li>
                <li><span>Physical methods: </span>
                  Role play, dancing, vigorous exercise and self-defence.
                  Acting out different realities and learning self-defence techniques can make you feel empowered and transform your perspective.  
                  Trauma memory is stored in the body so it can also be soothed through physical means just as it can be triggered through physical means.
                </li>
              </ol> 
            </p>

            <p class="app-info para">
              <strong>If you feel you need additional help, please look for a qualified specialist in your area.</strong>
            </p>

          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new TodoView()