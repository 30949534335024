import {LitElement, html} from 'lit'
import {render} from 'lit/html.js'
import {anchorRoute, gotoRoute} from '../../Router'
//import Auth from '../Auth' // for user auth
import App from '../../App' // for backend images
import Data from '../../Data' // for app variables

customElements.define('mi-prevquiz', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: {type: String}
    }
  }

  firstUpdated(){
    super.firstUpdated()
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .mi-box { text-align: center; font-size: 1.1em; }
        .mi-text { margin-bottom: 30px;  width: 250px;  }


        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }

      </style>
      <div class="mi-box">
          <p class="mi-text">Complete the previous quiz to get access.</p>
      </div>
    `
  }
  
})
