import App from '../App'
import Auth from '../Auth'
import Toast from '../Toast'
import Data from '../Data'

// ----- Functions ----- /case
    // post('/' 
    // get('/mycases/:id'  -- (my cases)
    // get('/:id' -- get single case - for update
        //Get by id not used - instead we find it from users cases /mycases
    // delete('/:id' 
    // put('/:id'
    // get('/'  -- (all cases for stats)


class CaseApi {

  async newCase (caseData, fail = false){
      try{
          const response = await fetch(`${App.apiBase}/case/`, {
              method: 'POST',      
              headers: {
                      "Authorization": `Bearer ${localStorage.accessToken}`,
                      "Content-Type" : "application/json", 
                      "Origin": App.origin
                  },
              body: caseData
          })
          if(!response.ok){      
            const err = await response.json()
            if(err) console.log(err)  
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          console.log(data)
          return true
      } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
      }
  }

  // get('/mycases/:id'  -- (my cases) = profile access
  async getCasesbyUserId (userId, fail = false){
      try{
          const response = await fetch(`${App.apiBase}/case/mycases/${userId}`, {
              headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
            })
          if(!response.ok){      
            const err = await response.json()
            if(err) console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          return data
      } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
      }
  }

  // my cases set as object in Auth.myCases - use find(id) to get specific object
  getThisCase(caseId){
      const thisCase = Auth.myCases.find(acase => acase._id == caseId)
      return thisCase
  }


  async deleteCase(caseId, fail = false){
    if(!caseId) return false
    try{
      let responseHeader
      responseHeader = {
        method: "DELETE",    
        headers: {
            "Authorization": `Bearer ${localStorage.accessToken}`,
            "Origin": App.origin
        },
      }
      const response = await fetch(`${App.apiBase}/case/${caseId}`, responseHeader)
      if(!response.ok){
        const err = await response.json()
        if(err) console.log('not ok' + err)
        console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const message = await response.json()
      console.log(message)
      Toast.show(`Incident deleted`) 
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }


  async getAllCases(){
    try{
        const response = await fetch(`${App.apiBase}/case`, {
            headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
          })
        if(!response.ok){      
          const err = await response.json()
          if(err) console.log(err)
          Toast.show(`${response.status}: ${err.message}`, 'error')   
          if(typeof fail == 'function') fail()
          return false
        }
        const data = await response.json()
        Data.cases = data
        return true
    } catch(err) {
        console.log(err)
        Toast.show(err, 'error')
        return false
    }
  }
   
  async updateCase(caseId, caseData, fail = false){
      if(!caseData || !caseId) return false
      try{
        let responseHeader
        responseHeader = {
          method: "PUT",    
          headers: {
              "Authorization": `Bearer ${localStorage.accessToken}`,
              "Content-Type" : "application/json", 
              "Origin": App.origin
          },
          body: caseData
        }
        const response = await fetch(`${App.apiBase}/case/${caseId}`, responseHeader)
        if(!response.ok){
          const err = await response.json()
          if(err) 
          console.log(err)
          Toast.show(`${response.status}: ${err.message}`, 'error')   
          if(typeof fail == 'function') fail()
          return false
        }
        const data = await response.json()
        Toast.show(`Incident updated`) 
        return data
      } catch(err) {
        console.log(err)
        Toast.show(err, 'error')
        return false
      }
  }


}

export default new CaseApi()