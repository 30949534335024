import App from '../App'
import Auth from '../Auth'
import Toast from '../Toast'
import Data from '../Data'



// ----------- Functions ---------------------------
        // updateEmailLink() Send Email Update Link with token
        // validateUpdateEmail() Update User Email via token
        // updateUser() 
        // getLeaderUsers() to populate leaderboards 
        // getStatUsers() for statistics
        

class UserApi {

    async updateEmailLink(userData, fail = false) {
        try{
          const response = await fetch(`${App.apiBase}/user/`, {
            method: 'POST',      
            headers: {"Content-Type" : "application/json", "Origin": App.origin},
            body: userData
          })
          if(!response.ok){
            const err = await response.json()
            if(err) console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            // run fail() functon if set
            if(typeof fail == 'function') fail()
            return false // 
          }
          const mailMessage = await response.json()
          //Toast.show(`${mailMessage.message}`)
          return true
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }

//from backend validate and update email
    async validateUpdateEmail(token, fail = false){
        try{
          const response = await fetch(`${App.apiBase}/updateemail`, { 
            method: 'PUT', headers: { "Authorization": `Bearer ` + token } 
          })
          if(!response.ok){      
            const err = await response.json()
            if(err) console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          if (!localStorage.accessToken){
            Toast.show(`Welcome  ${data.user.userName}`)
          }
          localStorage.setItem('accessToken', data.accessToken)
          Auth.currentUser = data.user
          return true
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }

    async updateUser(userId, userData, fail = false){
        if(!userData) return false
        try{
          let responseHeader
          responseHeader = {
            method: "PUT",    
            headers: {
                "Authorization": `Bearer ${localStorage.accessToken}`,
                "Content-Type" : "application/json", 
                "Origin": App.origin
            },
            body: userData
          }
          const response = await fetch(`${App.apiBase}/user/${userId}`, responseHeader)
          if(!response.ok){
            const err = await response.json()
            if(err) console.log('not ok' + err)
            console.log(err)
            Toast.show(`${response.status}: ${err.message}`, 'error')   
            if(typeof fail == 'function') fail()
            return false
          }
          const data = await response.json()
          Toast.show(`Profile updated`) 
          //console.log(data.user)
          Auth.currentUser = data.user   
          return true
        } catch(err) {
          console.log(err)
          Toast.show(err, 'error')
          return false
        }
    }

//getLeaderUsers - id score userName
    async getLeaderUsers(){
      try {
        const response = await fetch(`${App.apiBase}/user/leaders`, {
          headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
        })
        if(!response.ok){
          const err = await response.json()
          if(err) console.log('not ok' + err)
          console.log(err)
          Toast.show(`${response.status}: ${err.message}`, 'error')   
          if(typeof fail == 'function') fail()
          return false
        }
        const data = await response.json() 
        return data
      } catch(err) {
        console.log(err)
        Toast.show(err, 'error')
        return false
      }
    }

//getLeaderUsers - id score userName
    async getStatUsers(){
      try {
        const response = await fetch(`${App.apiBase}/user/stats`, {
          headers: { "Authorization": `Bearer ${localStorage.accessToken}`}
        })
        if(!response.ok){
          const err = await response.json()
          if(err) console.log('not ok' + err)
          console.log(err)
          Toast.show(`${response.status}: ${err.message}`, 'error')   
          if(typeof fail == 'function') fail()
          return false
        }
        const data = await response.json() 
        Data.users = data
        return true
      } catch(err) {
        console.log(err)
        Toast.show(err, 'error')
        return false
      }
    }
 

}

export default new UserApi()
