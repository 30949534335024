class Images {

//Considerations: will improve initial appearances - but will bloat file size
//possibly use for logos and main user icons only
//test emails via backend
    constructor(){

      this.logo = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.736 48.2684"><defs><style>.cls-1{fill:#00afc6;}.cls-2{fill:#fff;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_2-2" data-name="Layer 2"><path class="cls-1" d="M26.368,48.2684A33.3754,33.3754,0,0,1,37.1117,36.7706C44.1406,32.3956,55.46,25.3516,52.14,10.1431,49.9941.3089,34.5683-5.69,26.368,8.025,18.9487-5.69,2.7419.3089.5956,10.1431c-3.3192,15.2085,8,22.2525,15.0286,26.6275A33.3763,33.3763,0,0,1,26.368,48.2684Z"/><path class="cls-2" d="M33.7783,19.4558,32.446,24.1384h2.9471q2.1,0,2.1,2.2206a2.5877,2.5877,0,0,1-.5455,1.7559,1.9487,1.9487,0,0,1-1.5544.6264H31.1136l-.7663,2.5827a3.4513,3.4513,0,0,1-1.1912,1.797,2.8609,2.8609,0,0,1-1.756.6263,2.0969,2.0969,0,0,1-1.453-.5455,1.93,1.93,0,0,1-.6059-1.5134,3.6765,3.6765,0,0,1,.1207-.8882l.6059-2.0589H21.3442l-.7676,2.5827a3.4491,3.4491,0,0,1-1.19,1.797,2.8665,2.8665,0,0,1-1.7571.6263,2.0992,2.0992,0,0,1-1.4531-.5455,1.9338,1.9338,0,0,1-.6058-1.5134,3.6771,3.6771,0,0,1,.1219-.8882l.6046-2.0589H15.1265a2.0113,2.0113,0,0,1-1.5738-.6264,2.5223,2.5223,0,0,1-.566-1.7559,2.2429,2.2429,0,0,1,.566-1.6558,2.1277,2.1277,0,0,1,1.5738-.5648H17.63l1.3734-4.6826H16.7412a2.0111,2.0111,0,0,1-1.5737-.6264,2.522,2.522,0,0,1-.566-1.7559,2.2424,2.2424,0,0,1,.566-1.6558,2.1275,2.1275,0,0,1,1.5737-.5648h3.594l.7664-2.6648a3.0527,3.0527,0,0,1,1.1513-1.7559,2.9907,2.9907,0,0,1,1.756-.5854,2.3958,2.3958,0,0,1,1.5544.5045,1.65,1.65,0,0,1,.6252,1.3529,2.2882,2.2882,0,0,1-.1207.7265l-.6867,2.4222h4.7236l.7675-2.6648a3.0514,3.0514,0,0,1,1.15-1.7559,2.99,2.99,0,0,1,1.7559-.5854,2.3959,2.3959,0,0,1,1.5545.5045,1.6478,1.6478,0,0,1,.6263,1.3529,2.2873,2.2873,0,0,1-.1219.7265l-.6855,2.4222h1.8562q2.1,0,2.1,2.2206a2.5878,2.5878,0,0,1-.5455,1.7559,1.9488,1.9488,0,0,1-1.5544.6264Zm-5.006,0H24.0089l-1.3324,4.6826H27.4Z"/></g></g></svg>'

    } //end of constructor

  }
  
  export default new Images()