import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Stat from '../../../Stat'
import UserApi from '../../../auth/UserApi'


class UserStatsView {
  init(){    
    document.title = 'User Statistics'   
    this.statArray = null // update and render when array is complete with valueName colour percent
    this.render()  
    this.userStatInit()
    Utils.initFont()   
  }
  async userStatInit(){
    if(Data.ustatField == null){
      gotoRoute('/stats/usermenu')
      return
    }
    await this.getUserStat() 
    const newArray = Stat.getStatArray() 
    //cancel render function if user navigated away
    if(document.title !== 'User Statistics' ){return}
    // newArray.sort((a, b) => b.valuePercent - a.valuePercent)
    newArray
    .forEach(item => {
      //create the stat divs and assign classes
      const newDiv = document.createElement('div')
      newDiv.className = "stat-display"
      
      const statLabel = document.createElement('div')
      statLabel.classList = "stat-label para"
      statLabel.innerHTML = '' + item.valueName + ''
      newDiv.appendChild(statLabel)

      const statBar = document.createElement('div')
      statBar.className = "stat-bar"
      statBar.style.backgroundColor = ''+ item.valueColour + ''
      statBar.style.width = ''+ item.valuePercent + '%'
      newDiv.appendChild(statBar)
      //hide spinner
      const wait = document.getElementById('wait')
      const statDiv = document.getElementById('stat-items')
      wait.className = "hidden"
      statDiv.classList.remove('hidden')
      //append the stats
      document.getElementById('stat-items').appendChild(newDiv)
    })
    Utils.initFont()
  }
  

  async getUserStat(){
    if(Data.users == null){
      const mainTab = document.getElementById('main-tab')
      const statusTab = document.getElementById('status-tab')
      try{
        const usersStatus = await UserApi.getStatUsers()
        if(usersStatus == false){
          mainTab.classList.add('hidden')
          statusTab.innerHTML = '<c-error errorSource="tryagain"></c-error>'
        } 
      } catch(err) {
        console.log(err)
        mainTab.classList.add('hidden')
        statusTab.innerHTML = '<c-error errorSource="tryagain"></c-error>'
      }
    }
  }


  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">  
          <div class="stat-title">
            ${Data.statName}
          </div>
          
          <div id="main-tab"> 
            

            <br><br>

            <div id="wait">
              <p class="app-info para">
                Retrieving data and calculating...
              </p>
              <br><br>
              <div class="spinner sp-sml"></div> 
              <br><br>
            </div>
            
            <div id="stat-items" class="hidden">
              <div class="stat-display">
                <div class="stat-label para">All</div>
                <div class="stat-bar stat-all"></div>
              </div>
            </div>

            <div class="divide-line"></div>
          </div>

          <div id="status-tab"></div>

          <a href="/stats/usermenu" class="forum-button forum-btn primary" @click=${anchorRoute}>
            Back to Menu
            <img class="back-img" alt="back" src="./../images/icons/sw00-back.svg">
          </a>

        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new UserStatsView()