import { duration } from 'moment'
import App from './App.js'
import Auth from './Auth'
import Data from './Data'
import Utils from './Utils'


// TODO
//1) UPDATE THE DATA FROM Database and not test data


/*
//Case Fields ---- impactType[] / ongoingDuration / caseState, subdivision /  -- version 2...
User (impactType [], survivorAge, survivorGender, survivorDisability, survivorImpact, )
Incident (caseYear, caseReported, caseFrequency, caseType, ongoingDuration, caseReaction )
Place ( caseCountry, caseState, caseLocation, subdivision, locationSector )
Perpetrator ( perpAge, perpPosition, perpKnown, perpGender )
*/


class Stat {

  constructor(){

    //user variables
    this.userStats = []
    //case variables
    this.caseStats = []

    this.total = null
    this.colours = null
    this.mainArray = [] // USED TO PASS THE UPDATED ARRAY INTO (GENDER/AGE)

  }


  getStatArray(){

    let firstArray = null
    if(Data.statType == "users"){
      firstArray = Data.users.map(o => { return o[Data.ustatField] })
    } else {
      firstArray = Data.cases.map(o => { return o[Data.statTitle.toLowerCase()][Data.ustatField] })//here target the object first
    }
    this.total = firstArray.length
    this.setColours() // sets randomized colours

    //if cases = then replace the array with a count array
    if(Data.ustatField == "cases"){ 
      const casesArray = this.caseCountArray(firstArray)
      this.mainArray = casesArray
    } else if ( Data.ustatField == "survivorAge" || Data.ustatField == "age" ){
      const ageArray = this.groupAges(firstArray)
      this.mainArray = ageArray
    } else {
      this.mainArray = firstArray
    }
    const newObjectArray = this.statObject()
    return newObjectArray
  }


  setColours(){
    const newColours = Utils.randomizer(Data.colours)
    this.colours = newColours
  }

  caseCountArray(array){
    const arrayCount = []
      array.forEach(item => {
        if(!item) { arrayCount.push(0) } 
        else { arrayCount.push(item) }
      })
    return arrayCount
  }

  groupAges(array){
    const agegroups = []
    array.forEach(age => {
      if(age <= 9) { agegroups.push("Aged 0 - 9") } 
      if(age >= 10 && age <= 19) { agegroups.push("Aged 10 - 19") } 
      if(age >= 20 && age <= 29) { agegroups.push("Aged 20 - 29") } 
      if(age >= 30 && age <= 39) { agegroups.push("Aged 30 - 39") } 
      if(age >= 40 && age <= 49) { agegroups.push("Aged 40 - 49") }
      if(age >= 50 && age <= 59) { agegroups.push("Aged 50 - 59") }
      if(age >= 60 && age <= 69) { agegroups.push("Aged 60 - 69") }
      if(age >= 60 && age <= 79) { agegroups.push("Aged 60 - 79") }
      if(age >= 80) { agegroups.push("Aged 80 or older") }
    })
    return agegroups
  }

  //run the function to get the new arrays (fields & fieldsCount)
  statObject(){
    let myObject = []
    // get distinct items:
    let distinctValues = this.getDistinctValues()
    // -- -- Get count for each value & Convert to percentages
    const percentages = this.getPercent(distinctValues, this.total)

    // if ... Data.ustatField
    if(Data.ustatField == "usergroup" || Data.ustatField == "survivorGender"){
      const newValues = this.groupGenders(distinctValues)
      //console.log(newValues)
      distinctValues = newValues
    }

    if(Data.ustatField == "countryCode"){
      const newValues = this.nameCountries(distinctValues)
      //console.log(newValues)
      distinctValues = newValues
    }

    let c = 0;
    percentages.sort((a, b) => b.valuePercent - a.valuePercent)
      .forEach((item, i) => {
        if(c == this.colours.length ){c = 0}
        myObject.push({"valueName": distinctValues[i], "valueColour": this.colours[c], "valuePercent": item.valuePercent })
        c++
      })

    return myObject
  }

  getDistinctValues(){
    const distinctValues = [...new Set(this.mainArray.map(x => x))] 
    return distinctValues
  }

  getPercent(array, total){
    const valueCounts = []
    array.forEach(value => {
      const count = this.mainArray.filter(x => x == value).length;
      const percent = ((count/total) * 100).toFixed(0)
      valueCounts.push({"valuePercent": percent})
    })
    return valueCounts
  }

  groupGenders(array){
    array.forEach((value, i) => {
      if(value == "peach"){ array[i] = "Female" }
      if(value == "azure"){ array[i] = "Male" }
      if(value == "forrest"){ array[i] = "Other" }
    });
    return array
  }

  convertGender(item){
    if(item == "peach"){ return "Female" }
    if(item == "azure"){ return "Male" }
    if(item == "forrest"){ return "Other" }
  }

  nameCountries(array){
    array.forEach((value, i) => {
      const mycountry = Data.allCountries.find(country => country.countryCode == value)
      array[i] = mycountry.countryName
    });
    return array
  }


}

export default new Stat()