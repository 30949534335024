import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class ConsentView { 
  init(){    
    document.title = 'Consent'
    this.currentlesson = 2 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }


  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/2-consent.svg"> 
          </div>
          <section class="lesson-section">
            <p class="app-info para">
              Consent is the agreement between two or more people to do something.  
              Any sexual act without consent is sexual assault.  
              Common sexual acts include kissing, fondling (touching), oral sex and intercourse.
              Other less obvious  sexual acts include showing or looking at one’s private parts.  Due to the rise in sexual abuse around the world, many governments have analysed consent and stipulated conditions which must be observed.
            </p>

            <p class="app-info para space-above">
              <strong>Conditions of Consent:</strong>
              <ol class="para">
                <li>
                  <strong>Above the age of consent – </strong>
                  Younger children are not aware of what sexual activities involve or how it might impact them.  
                  They don’t know what they are really being lured into.  
                  In most countries the age of consent is 16 and it is not permissible for younger children to engage in sexual activities of any form.
                </li>
                <li>
                <strong>Intellectual ability – </strong>
                  Someone must have the mental capacity to decide.  
                  A person who has a mental health issue or related illness is unable to make a choice.
                </li>
                <li>
                  <strong>Awareness – </strong>
                  Someone must be conscious of what is happening in order to give their consent.  
                  No one can give consent if they are asleep, unconscious or too drunk etc.  
                </li>
                <li>
                  <strong>Continuous act of consent – </strong>
                  This may include saying “yes”, or by demonstrating actions of encouragement.  
                  If someone freezes or changes their mind by saying “stop” or “no” etc, then the consent is considered withdrawn and it is no longer permissible to continue.  
                  If consent is withdrawn it must be sought again before continuing. 
                  If meeting the same person again, then consent must be given again.
                </li>
              </ol> 
            </p>

            <p class="app-info para space-above">
              <strong>Prohibitions:</strong>
              <ol class="para">
                <li>
                  <strong>Force – </strong>
                  It is not permissible to hold someone back or force them into a sexual act.
                </li>
                <li>
                  <strong>Persistence – </strong>
                  It is not permissible to nag or pressure someone to take part in a sexual act until they give in.
                </li>
                <li>
                  <strong>Coercion, threats or tricks – </strong>
                  It is not permissible to force someone into a sexual act by threatening in any manner.  
                  Coercion might include the threat to inform on someone for a misbehaviour, to harm them or someone they love, to send revealing images, to threaten their job position or any other form of threat. 
                  It is not permissible to manipulate or trick someone into taking part in any sexual act.
                </li>
              </ol> 
            </p>

            <p class="app-info para space-above">
              <strong>Examples of consent:</strong>
              <ul class="para">
                <li>“Yes”</li>
                <li>“Keep going”</li>
                <li>“More”</li>
                <li>“I like this”</li>
                <li>Undressing</li>
                <li>Returning touches to the other person</li>
                <li>Smiling</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              <strong>Examples of NOT consenting:</strong>
              <ul class="para">
                <li>"No"</li>
                <li>“Stop”</li>
                <li>“I’m not sure”</li>
                <li>“I’ve changed my mind”</li>
                <li>Silence / Not speaking (unless another consent indicator is present as mentioned previously)</li>
                <li>Not moving</li>
                <li>Moving away</li>
                <li>Crying</li>
                <li>Slurring words</li>
                <li>Covering themselves up</li>
                <li>Putting their clothes on</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              <strong>If unsure, check if they are still consenting:</strong>
              <ul class="para">
                <li>“Do you like this?”</li>
                <li>“Do you want to … ?”</li>
                <li>“Would you like to continue?”</li>
                <li>“Are you OK?”</li>
              </ul> 
            </p>

            <p class="app-info para">
              If a sexual act takes place and the conditions of consent are not met it is sexual assault. 
              If consent is not given, or someone is pressured or forced, it is a punishable crime.
            </p>

          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new ConsentView() 