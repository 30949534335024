import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class MyPostsView {
  init(){    
    document.title = 'My Posts'    
    this.render()  
    Utils.initFont()
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">      
          show posts - most recent first.  And give option to sort by threads (populate)
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new MyPostsView()