import {LitElement, html} from 'lit'
import {gotoRoute} from '../Router'
import Data from '../Data' // for app variables

customElements.define('stat-data-btn', class StatData extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      statType: { type: String },
      statField: { type: String },
      statName: { type: String },
      statTitle: { type: String }
    }
  }

 
  firstUpdated(){
    super.firstUpdated()
  }


  openStats(){
    Data.statTitle = this.statTitle
    Data.ustatField = this.statField
    Data.statName = this.statName
    Data.statType = this.statType
		gotoRoute('/stats/' + this.statType)
  }

  

  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .stats-btn {
          margin: 20px auto;
          width: 300px;
          padding: 12px;
          color: white;
          font-size: 1.5em;
          height: 58px;
          position: relative;
          cursor: pointer;
          transition:all .5s;
          user-select: none;
          background-color: var(--two-color);
        }
        .stats-btn:hover { background-color: var(--three-color); }

        .sbi-box {
          height: 100%;
          width: 50px;
          margin-left: 10px;
          display: flex; align-items: center; justify-content: center;
        }
        img { max-width: 100%; max-height: 100%;}
        .sb-title {
          height: 58px;
          width: 260px;
          text-align: center;
          position: absolute;
          display: flex; align-items: center; justify-content: center;
          top: 0;
          right: 0;
        }


        @media all and (max-width: 350px){ 
                  
          .stats-btn {
            margin: 10px auto;
            width: 250px;
            padding: 12px;
            font-size: 1.2em;
            height: 50px;
          }
          .sbi-box { height: 27px; }
          .sb-title { height: 50px; width: 210px; }
          
        }
        
      </style>

      <nav class="stats-btn" @click="${this.openStats}">
        <div class="sbi-box r-marg">
          <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
        </div>
        <div class="sb-title">${this.statName}</div>
      </nav>

    `
  }
  
})
