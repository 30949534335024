import App from '../../../App'
import {render, html} from 'lit/html.js'
import { anchorRoute } from '../../../Router'
import Utils from '../../../Utils'

class ResponseView { 
  init(){    
    document.title = 'Responses'  
    this.currentlesson = 3 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/3-response.svg"> 
          </div>
          <section class="lesson-section">

            <p class="app-info para">
              People respond to sexual assault differently and the circumstances of the abuse can also affect how a person responds.  
              The following are commonly recognised responses when someone anticipates or experiences sexual assault:
            </p>

            <p class="app-info para">
              <ol class="para">
                <li>Fight</li>
                <li>Flight</li>
                <li>Deter</li>
                <li>Call for Help</li>
                <li>Freeze</li>
                <li>Resign & Dissociation</li>
              </ol> 
            </p>


            <h2 class="para">Fight</h2>

            <p class="app-info para">
              Fight is where someone who is attacked responds by force or aggression. 
              This may happen when someone does not feel overly threatened by the perpetrator or someone who tries their best to stop the aggression such as in the event of attempted rape. 
              If the person attacked gets enraged, sometimes the aggression will even be turned back onto the attacker.  
              It is common that people under threat get adrenalin rushes, which boosts their strength under stressful circumstances. 
            </p>

            <p class="app-info para">
              Examples of the “fight” response:
            </p>

            <p class="app-info para">
              <strong>Rape: </strong>
              An example of fight is where a person intends to rape someone but their intended victim knees them brutally in the groins. 
              This caused the attacker to reel in pain, which may allow time for the victim to escape.
            </p>

            <p class="app-info para">
              <strong>Sexual Assault: </strong>
              One narration describes how someone’s boss touched their private parts while pretending it was an accident and hiding it from other customers in the bar.  
              The intended victim turned and aggressively pushed back her boss against the wall, while grabbing him viciously by his precious gems, threatening him if he ever did it again.  
              Although unreported, he never attempted to touch her again. 
            </p>

            <p class="app-info para">
              <strong>Sexual Assault: </strong>
              Another narration tells of how a fellow night-clubber grabbed her private area from beneath her short dress in packed room.  
              She grabbed the hand from between her legs and used force to pull her attacker near to her to scream at him. 
              (She also wished she left a few facial scratches as an additional gift)
            </p>

            <p class="app-info para">
              Some people are more inclined to act with aggression than others. 
              This is seen in cases where people have been sexually assaulted previously or those who grew up in rough environments (such as with few older brothers).  
              Also people who have taken self-defence courses or training are more likely to respond with the fight instinct as their body is prepared to react against aggression.  
            </p>

            <h2 class="para">Flight</h2>

            <p class="app-info para">
              Flight will generally take place when a person feels threatens and sees an opportunity to escape.  
              A trigger warning may be felt when the aggressor is perceived as stronger or larger accompanied with a sense of threat. 
              This sense of threat may appear as a sinister look in the eye or an act of sexual harassment 
              (see <a href="/lesson/4" @click="${anchorRoute}">harassment</a> section).  
            </p>

            <p class="app-info para">
              Examples of the “flight” response:
            </p>

            <p class="app-info para">
              <strong>At a friend’s house: </strong>
              While their friend was out another visitor makes an inappropriate comment while approaching, sensing danger the “would be victim” runs out of the house to seek protection from a neighbour.
            </p>

            <p class="app-info para">
              <strong>At a party: </strong>
              The party was raging and most attendees were too drunk, the “would be victim” went up to a room for a rest, however, she was followed up unknowingly.  
              The man’s intentions were clear as he pushed her onto the bed.  
              Quickly jumping to her feet she was able to make for the door and run out of the house escaping.
            </p>


            <h2 class="para">Call for Help</h2>

            <p class="app-info para">
              When sexual assault takes place in a house with other occupants, a call for help works wonders.  
            </p>

            <p class="app-info para">
              Examples of the “call for help” response:
            </p>

            <p class="app-info para">
              <strong>Sleepover: </strong>
              The survivor was staying at her friend’s and that same night the parents had a few friends over for drinks.  
              She went to sleep in her friend’s room, but was awoken to find one of the visitors with his hands down her pants!  
              Calling out for help brought the parents running to remove the perpetrator.
            </p>
            
            <p class="app-info para">
              <strong>Another sleepover: </strong>
              While staying over at friend’s house, the survivor awoke to find her friend’s roommate in bed with her with his hands down her pants!  
              After calling out for help, everyone in the house was alerted to the perpetrator and new sleeping arrangements were made. 
            </p>

            <p class="app-info para">
              Unfortunately these incidents are not uncommon, but hardly ever reported.
            </p>


            <h2 class="para">Deter</h2>

            <p class="app-info para">
              Deterring will take place if there is no-one else there or the survivor doesn’t want to draw attention to the reality of what is happening.  
              Lying about needing to vomit, diarrhoea or pain can sometimes distract the perpetrator.
            </p>

            <p class="app-info para">
              Examples of the “deter” response:
            </p>

            <p class="app-info para">
              <strong>A hitched ride: </strong>
              One narrator tells of how she had hitched a ride and half way to the destination the driver had pulled over and she feared for her safety.  
              To distract him from doing anything, she complained of having a sore stomach and feeling like she was going to vomit.  
              She started asking for fresh air, so he drove on until they reached the nearest station.
            </p>

            <p class="app-info para">
              <strong>Internal examination: </strong>
              A survivor was at a birthing centre with her husband in their private room. 
              A nurse came in to check her internal progress, however, the nurse was stimulating an area she was not supposed to be touching!  
              When the survivor realised what was taking place, she deterred her by saying that she was hurting her.  
              And this prompted the nurse to stop.
            </p>


            <h2 class="para">Freeze</h2>

            <p class="app-info para">
              Freezing generally occurs in a state of shock and confusion during severe sexual assault incidents.  
              During the “freeze” state a mental dialog takes place but the body stops responding to mental commands. 
              If the incident (stimulation of any sex button) lasts longer than 1 second (sound it out in your head), then it is definitely sexual assault. 
              In the event someone wasn’t able to respond and try to stop the assault, it is likely their body has entered the “freeze” state.
            </p>

            <p class="app-info para">
              The following are some thoughts recorded by those who entered the “freeze” state:
              <ul class="para">
                <li>“This isn’t really happening…”</li>
                <li>“Scream so someone can come...”</li>
                <li>“Say no, tell them to stop…”</li>
                <li>“Hit them, smash their face...” </li>
                <li>“Just wait for it to be over...”</li>
                <li>“Do something…”</li>
                <li>“It’s almost over…”</li>
                <li>“Just play along until you can escape…”</li>
                <li>“God please help me...”</li>
                <li>“Put my hand to stop them from touching…”</li>
                <li>“Fight back...”</li>
                <li>“Why won’t my body work...”</li>
              </ul> 
            </p>

            
            <h2 class="para">Resign & Dissociation</h2>

            <p class="app-info para">
              Resigning takes place when the survivor realises that they are overpowered or helpless to take any action. 
              This may occur if someone has been coerced, after trying to fight without success, or after a survivor realises they have entered the freeze state and cannot function normally.  
              The “resign” state is where the survivor lets the abuser do what they want and is mostly accompanied by dissociation.  
              Dissociation is where the survivor mentally disconnects from the physical situation.  
            </p>

            <p class="app-info para">
             Examples of the “dissociation”:
              <ul class="para">
                <li>Feeling you are in another room</li>
                <li>Watching yourself from above and not feeling anything</li>
                <li>The world looks fake or unreal</li>
                <li>Everything lost its colour or seems foggy</li>
                <li>Feeling that time has slowed down or sped up</li>
                <li>Imagining other scenarios</li>
                <li>Focusing on other details in the environment to numb the reality</li>
              </ul> 
            </p>

            <p class="app-info para">
              Dissociation is a coping mechanism to help survivors deal with a traumatic experience they have no control over.
            </p>

          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div>  
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new ResponseView() 