import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import Utils from '../../Utils'

customElements.define('mi-purchase', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
  }


  signInButton(){
    gotoRoute('/signin')
    Utils.closeModal()
  }

  purchaseButton(){
    gotoRoute('/purchases')
    Utils.closeModal()
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .mi-box { text-align: center; font-size: 1.1em; }
        .mi-text { margin-bottom: 30px;  width: 250px;  }

        .redirect-button {
          color: white;
          border: none;
          border-radius: 5px;
          padding: 7px;
          margin: 20px auto;
          text-decoration: none;
          width: 200px; 
          cursor: pointer;
          font-size: 1.1em;
        }

        .primary { background-color: var(--brand-color); }
        .action { background-color: var(--action-color) ; }
        .disabled { background-color: var(--disabled-color); cursor: none; }

        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }
        
      </style>
      
      <div class="mi-box">
        <p class="mi-text">
          ${this.modalType == "quizlead" ? html`
            Buy access to the quizzes and leader boards ($20) to support this project.
          `:``}
          ${this.modalType == "forum" ? html`
            Buy a subscription* for the moderated chat forums and help each other to heal. 
            ($15 per month or discounted $150 per year)
          `:``}
          ${this.modalType == "stats" ? html`
            Get free access to the statistics with any other purchase.
          `:``}
        </p>
        
        ${localStorage.accessToken == null ? html`
          <div class="redirect-button action" @click=${this.signInButton}>1. Sign in</div>
          <div class="redirect-button disabled" >2. Purchase access</div>
        `:html`
          <div class="redirect-button action" @click=${this.purchaseButton}>Purchase access</div>
        `}
      </div>
    `
  }
  
})
