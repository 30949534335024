//import App from './App'
//import {html, render } from 'lit-html'


export default class Toast {

  static init(){
    this.showDuration = 3.2
    // create container element
    this.containerEl = document.createElement('div')
    this.containerEl.id = 'toasts'    
    // append to <body>
    document.body.appendChild(this.containerEl)    
  }

  static show(content, type = ''){        
    if(!content) return
    // create element
    const toastEl = document.createElement('div')
    toastEl.className = 'toast-entry'
    if(type != "") toastEl.classList.add('is-error')
    toastEl.innerText = content
    
    // appened to container
    this.containerEl.appendChild(toastEl)
    
    setTimeout(() => {  toastEl.remove(); }, 4000);
    
  }
}
