import { duration } from 'moment'
import App from './App.js'
import Auth from './Auth'
import Data from './Data'
import Utils from './Utils'


//If revising code to add return to previous tabs functioning
  //... then don't forget to reset all validation consts for each tab

class Nstat {

  constructor(){

    //variables
    this.doneBtns = []
    this.mscMenu = null
    this.mscInput = null
    this.impacted = []
    this.userValidated = false
    this.caseValidated = false
    this.placeValidated = false
    this.perpValidated = false
    this.formValidated = false
    this.loctnValidation = false
    this.tabValidated = false

    this.caseData = {
      "caseTitle": "",
      "survivor": {},
      "incident": {},
      "place": {},
      "perpetrator": {}
    }
    
    //variables for edit case page
    this.caseUpdates = {}
    this.pprequired = false //Modal insert condition
 
  }

  resetCase(){
    this.doneBtns = []
    this.mscMenu = null
    this.mscInput = null
    this.impacted = []
    this.userValidated = false
    this.caseValidated = false
    this.placeValidated = false
    this.perpValidated = false
    this.formValidated = false
    this.loctnValidation = false
    this.tabValidated = false
    this.pprequired = false
    this.caseUpdates = {}
    this.caseData = {
      "survivor": {},
      "incident": {},
      "place": {},
      "perpetrator": {}
    }
  }

  getoriginalCase(){
    Nstat.caseData = Auth.myCases.find(mycase => mycase._id == Auth.caseId )
  }

/* impactType  impactType
//-- version 2...---- impactType[] / ongoingDuration / caseState, subdivision /  -- version 2 filters...
User (impactType [], survivorAge, survivorGender, survivorDisability, survivorImpact, )
Incident (caseYear, caseReported, caseFrequency, caseType, ongoingDuration )
Place ( caseCountry, caseState, caseLocation, subdivision, locationSector )
Perpetrator ( perpAge, perpPosition, perpKnown, perpGender )
*/


  //Navigation Functions:
  //These navigation function still include the functioning
  // - for the progress bar click, however, it was removed because
  // - the conditional functions didn't work when backtracking.
  // == the issue was possibly because the global variables needed to get reset.
  nextTabHandler(e){
    e.preventDefault()
    const thisTab = e.target.closest('div').getAttribute('id')
    const lastChar = thisTab.slice(-1)
    const n = this.doneBtns.length + 1
    const next = this.doneBtns.length + 2
    const buttonMatch = n.toString()
    if(lastChar != buttonMatch && !this.doneBtns.includes(thisTab)){
      return
    }
    if(this.doneBtns.includes(thisTab)){
      const num = Number(lastChar) + 1
      this.showNextTab(num)//working 
      Utils.scrollToTop()
      return
    } 
    this.validateTab(thisTab)
    if(this.tabValidated == true){ 
      this.doneBtns.push(thisTab)
      this.nextStep(lastChar, next)
      this.showNextTab(next)
      Utils.scrollToTop()
     }
  }
  
  nextStep(a, b){
    //so here run line styles
    const myLine = document.getElementById('stepl'+ b)
    myLine.classList.remove('sl-ready')
    myLine.classList.add('sl-done')
    //also active next circle
    const myActive = document.getElementById('stepc'+ b)
    myActive.classList.remove('sc-ready')
    myActive.classList.add('sc-current')
    //and text for next
    const myActiveText = document.getElementById('stept'+ b)
    myActiveText.classList.remove('st-ready')
    myActiveText.classList.add('st-current')
    // and update text for prev
    const doneText = document.getElementById('stept'+ a)
    doneText.classList.remove('st-current')
    doneText.classList.add('st-done')
    // and done circle
    const doneCircle = document.getElementById('stepc'+ a)
    doneCircle.classList.remove('sc-current')
    doneCircle.classList.add('sc-done')
    //show next button as active
    const nextButton = document.getElementById('next'+ b)
    nextButton.classList.remove('disabled')
    nextButton.classList.add('action')
    nextButton.classList.add('forum-action-btn')
  }

  showNextTab(t){
    this.hideAllTabs()
    const nextTab = document.getElementById('tab-' + t)
    nextTab.classList.remove('hidden')
  }

  hideAllTabs(){
    const allTabs = document.querySelectorAll('.tab-section')
    allTabs.forEach(tab => {
      if(tab.classList.contains('hidden')){return}
      tab.classList.add('hidden')
    })
  }

  showEditTab(tab){
    const allTabs = document.querySelectorAll('.tb-btns')
    allTabs.forEach(tab => {
      if(tab.classList.contains('tb-active')){tab.classList.remove('tb-active')}
    })
    const nextTab = document.getElementById('tb-' + tab)
    nextTab.classList.add('tb-active')
    this.showNextTab(tab)
    Utils.scrollToTop()
  }


  //---VALIDATION FUNCTIONS ----


  validateTab(thisTab){
    //reset if previously entered
    this.tabValidated = false
    let valid = false
    if (thisTab == "next1"){
      this.userValidation()
      valid = this.userValidated
    }
    if (thisTab == "next2"){
      this.caseValidation()
      valid = this.caseValidated
    }
    if (thisTab == "next3"){
      this.placeValidation()
      valid = this.placeValidated
    }
    if(valid == false){ Utils.scrollTopWarning() }
    //run sanitization for input text 
    this.tabValidated = valid
  }

  impactCheck(){
    const multiOne = document.getElementById('multione').querySelectorAll('.multi-tick')
    this.impacted = []
    multiOne.forEach(box => {
      if(box.classList.contains('multi-check')){
        const item = box.parentElement.nextElementSibling
        this.impacted.push(item.innerText)
      }
    })
  }

  radioHandler(e){
    const radioId = e.target.getAttribute('id')
    const radioName = e.target.getAttribute('name')
    if(radioId == 'survivorImpactn'){
      this.removeWarn("impactType")
      this.impacted = []
    }
    this.removeWarn(radioName)
  }


  userValidation(){
    //reset from previous
    this.userValidated = false
    Utils.warnItems = []

    let validated = true
    const sAge = document.forms["case-form"]["survivorAge"].value;

    const sDisability = document.forms["case-form"]["survivorDisability"].value;
    const sImpact = document.forms["case-form"]["survivorImpact"].value;
    const caseTitle = document.forms["case-form"]["caseTitle"].value;
    //This added after the form is complete - not needed in update case/incident
    const sGender = document.forms["case-form"]["survivorGender"].value;
    
    if (isNaN(sAge) || sAge < 1 || sAge > 110) {
      this.inputWarn("survivorAge")
      Utils.warnItems.push("survivorAge")
      validated = false
    } 
    if (sDisability == "") {
      this.inputWarn("survivorDisability")
      Utils.warnItems.push("survivorDisability")
      validated = false
    }
    if (sImpact == "") {
      this.inputWarn("survivorImpact")
      Utils.warnItems.push("survivorImpact")
      validated = false
    }
    this.impactCheck()
    if (sImpact == "Yes, I was impacted by it") {
      if(this.impacted.length == 0){
        this.inputWarn("impactType")
        Utils.warnItems.push("impactType")
        validated = false
      }
    }
    if (sImpact == "No, I wasn't") {
      if(this.impacted.length > 0){
        this.inputWarn("survivorImpact")
        Utils.warnItems.push("survivorImpact")
        validated = false
      }
    }
    
    this.userValidated = validated 
    if(this.userValidated == true){
      this.caseData.caseTitle = caseTitle
      console.log('new case data')
      console.log(this.caseData)
      this.caseData.survivor.survivorAge = Number(sAge)
      this.caseData.survivor.survivorDisability = sDisability
      this.caseData.survivor.survivorGender = sGender
      this.caseData.survivor.survivorImpact = sImpact
      if (sImpact == "Yes, I was impacted by it") {
        this.caseData.survivor.impactType = this.impacted
      } else {
        this.caseData.survivor.impactType = []
      }
    }
  }

  caseValidation(){
    //reset 
    this.caseValidated = false
    Utils.warnItems = []

    let validated = true
    const yearValue = document.forms["case-form"]["caseYear"].value;
    const repValue = document.forms["case-form"]["caseReported"].value;
    const frqValue = document.forms["case-form"]["caseFrequency"].value;
    const ongValue = document.forms["case-form"]["ongoingDuration"].value;
    const typValue = document.forms["case-form"]["caseType"].value;
    const responseValue = document.forms["case-form"]["caseReaction"].value;
    const thisYear = new Date().getFullYear()
    if (isNaN(yearValue) || yearValue < 1930 || yearValue > thisYear) {
      this.inputWarn("caseYear")
      validated = false
    } 
    if(repValue.includes('Select')){
      this.inputWarn("caseReported")
      Utils.warnItems.push("caseReported")
      validated = false
    }
    if(responseValue.includes('Select')){
      this.inputWarn("caseReaction")
      Utils.warnItems.push("caseReaction")
      validated = false
    }
    if(frqValue.includes('Select')){
      this.inputWarn("caseFrequency")
      Utils.warnItems.push("caseFrequency")
      validated = false
    }
    if(frqValue.includes('Ongoing')){
      if(ongValue.includes('Select')){ 
        this.inputWarn("ongoingDuration")
        Utils.warnItems.push("ongoingDuration")
        validated = false
      }
    }
    if(typValue.includes('Select')){
      this.inputWarn("caseType")
      Utils.warnItems.push("caseType")
      validated = false
    }
    this.caseValidated = validated
    if(this.caseValidated == true){
      this.caseData.incident.caseYear = Number(yearValue)
      this.caseData.incident.caseReported = repValue
      this.caseData.incident.caseFrequency = frqValue
      this.caseData.incident.caseType = typValue 
      this.caseData.incident.caseReaction = responseValue 
      if(frqValue.includes('Ongoing')){
        this.caseData.incident.ongoingDuration = ongValue
      }
    }
  }

  placeValidation(){
    // reset
    this.placeValidated = false
    Utils.warnItems = []

    let validated = true
    let stateField = false
    let subField = false
    let sectorField = false
    const cntryValue = document.forms["case-form"]["caseCountry"].value;
    const stValue = document.forms["case-form"]["caseState"].value;
    const lctnValue = document.forms["case-form"]["caseLocation"].value;
    const subValue = document.forms["case-form"]["subdivision"].value;
    const sctrValue = document.forms["case-form"]["locationSector"].value;
    
    if(cntryValue.includes('Select') ){ 
      this.inputWarn("caseCountry")
      Utils.warnItems.push("caseCountry")
      validated = false
    } else { 
      let myCountry = Data.allCountries.find(field => field.countryName == cntryValue )
      if(myCountry.state){
        stateField = true
        if(stValue.includes('Select')){
          this.inputWarn("caseState")
          validated = false
        }
      }
    }
    if(lctnValue.includes('Select')){
      this.inputWarn("caseLocation")
      Utils.warnItems.push("caseLocation")
      validated = false
    } else {
      const locationArray = Data.statisticsOptions
      .find(field => field.fieldName  == "caseLocation" )
      .options.find(field => field.optionText == lctnValue )
      const subarray = locationArray.subOptions.find(field => field.soField  == "subdivision" )
      const sectorarray = locationArray.subOptions.find(field => field.soField  == "locationSector" )
      if(subarray){
        subField = true
        if(subValue.includes('Select')){
          this.inputWarn("subdivision")
          Utils.warnItems.push("subdivision")
          validated = false
        }
      }
      if(sectorarray){
        sectorField = true
        if(sctrValue.includes('Select')){
          this.inputWarn("locationSector")
          Utils.warnItems.push("locationSector")
          validated = false
        }
      }
    }
    this.placeValidated = validated

    if(this.placeValidated == true){
      this.caseData.place.caseCountry = cntryValue
      if(stateField == true){ this.caseData.place.caseState = stValue }
      this.caseData.place.caseLocation = lctnValue
      if(subField == true){ this.caseData.place.subdivision = subValue }
      if(sectorField == true){ this.caseData.place.locationSector = sctrValue }
    }
  }

  perpValidation(){
    // reset
    this.perpValidated = false
    Utils.warnItems = []
    let validated = true
    const paValue = document.forms["case-form"]["perpAge"].value;
    const pgValue = document.forms["case-form"]["perpGender"].value;
    const ppValue = document.forms["case-form"]["perpPosition"].value;
    const pkValue = document.forms["case-form"]["perpKnown"].value;
    if(paValue.includes('Select')){
      this.inputWarn("perpAge")
      validated = false
    }
    if(pgValue.includes('Select')){
      this.inputWarn("perpGender")
      Utils.warnItems.push("perpGender")
      validated = false
    }
    if(ppValue.includes('Select')){
      this.inputWarn("perpPosition")
      Utils.warnItems.push("perpPosition")
      validated = false
    }
    if(pkValue.includes('Select')){
      this.inputWarn("perpKnown")
      Utils.warnItems.push("perpKnown")
      validated = false
    }
    this.perpValidated = validated
    // perpAge, perpPosition, perpKnown, perpGender
    if(this.perpValidated == true){
      this.caseData.perpetrator.perpAge = paValue
      this.caseData.perpetrator.perpGender = pgValue
      this.caseData.perpetrator.perpPosition = ppValue
      this.caseData.perpetrator.perpKnown = pkValue 
    }
  }

  formValidtion(){
    // reset
    this.formValidated = false
    let formValid = false

    this.perpValidation()
    formValid = this.perpValidated 
    this.formValidated = formValid
  
    if(this.perpValidated == true){
      // add the user id to the form here
      const sUser = document.forms["case-form"]["user"].value;
      this.caseData.user = sUser
      //now run add new function

    }
  }

  inputWarn(id){
    const thisWarn = document.getElementById(id + '-wrn')
    thisWarn.style.maxHeight = Math.max(thisWarn.scrollHeight) + "px"
  }

  removeWarn(id){
    const thisWarn = document.getElementById(id + '-wrn')
    if(thisWarn.style.maxHeight == null){return}
    else{thisWarn.style.maxHeight = null}
  }

  //--- CUSTOM SELECT FUNCTIONS -----------
  mcsOpenMenu(e){
    e.preventDefault()
    this.mscInput = e.target.closest('select')
    if (this.mscInput.disabled == true){
      if(this.mscInput.id == 'caseType'){
        this.inputWarn('caseFrequency')
      }
      if(this.mscInput.id == 'perpPosition'){
        this.inputWarn('caseLocation')
      }
      return
    }
    this.removeWarn(this.mscInput.id)
    this.mscMenu = e.target.parentNode.nextElementSibling
    //const myOptions = this.mscMenu.querySelectorAll('li')
    this.mscMenu.classList.remove('hidden')
    const myMenu = this.mscMenu
    const myDoc = document
    myDoc.addEventListener('click', function abc() {
      myMenu.classList.add('hidden')
      myDoc.removeEventListener('click', abc, true)
    }, true)
  }

  mcsSelectOption(e){
    const selected = e.target
    const newText = e.target.innerText
    // remove class from all other items
    if(selected.classList.contains('msc-selected')){
      return
    }else{
      const parent = selected.parentElement.parentElement.id
      const children = document.getElementById(parent).querySelectorAll("p")
      children.forEach(child => {
        if(child.classList.contains('msc-selected'))
        child.classList.remove('msc-selected')
      })
      selected.classList.add('msc-selected')
    }
    this.mscInput.options[0].text = newText
    this.mscInput.options[0].value = newText
    this.dynamicData(e)
  }

  dynamicData(){
    if(this.mscInput.id == "caseFrequency"){
      // show "ongoingDuration"
      const ongoingDiv = document.getElementById('ongoing')
      const cfSelect = document.getElementById('caseType')
      if (this.mscInput.options[0].value == "Ongoing"){
        ongoingDiv.classList.remove('hidden')
      } else {
        if(!ongoingDiv.classList.contains('hidden')){
          ongoingDiv.classList.add('hidden')
          this.resetSelect('ongoingDuration')
        }
      }
      // populate and ENABLE "caseType"
      const cfValue = this.mscInput.options[0].value
      cfSelect.disabled = false
      const cfOptions = Data.statisticsOptions
        .find(field => field.fieldName  == "caseFrequency" )
        .options.find(field => field.optionText == cfValue )
        .subOptions.find(field => field.soField  == "caseType" )
        .soText
      this.populateOptions('caseType-l', cfOptions)
      this.resetSelect('caseType')
    }// END CASE fREQUENCY CONDITIONS
    if(this.mscInput.id == "caseCountry"){
      const ccValue = this.mscInput.options[0].value
      const myCountry = Data.allCountries.find(field => field.countryName == ccValue )
      const statesDiv = document.getElementById('states')
      this.resetSelect('caseState')
      if(myCountry.state == null){
        if(!statesDiv.classList.contains('hidden')){
          statesDiv.classList.add('hidden')
        }
      } else {
        this.populateOptions('caseState-l', myCountry.state)
        if(statesDiv.classList.contains('hidden')){
          statesDiv.classList.remove('hidden')
        }
      }
    }// END CASE COUNTRY CONDITIONS
    if(this.mscInput.id == "caseLocation"){
      // "perpPosition" condition
      const cfSelect = document.getElementById('perpPosition')
      const cfValue = this.mscInput.options[0].value
      cfSelect.disabled = false
      const locationArray = Data.statisticsOptions
        .find(field => field.fieldName  == "caseLocation" )
        .options.find(field => field.optionText == cfValue )
      const cfOptions = locationArray
        .subOptions.find(field => field.soField  == "perpPosition" )
        .soText
      this.populateOptions('perpPosition-l', cfOptions)
      this.resetSelect('perpPosition')
      // "subdivision" condition
      const myLocation = locationArray.subOptions.find(field => field.soField == 'subdivision' )
      const subDiv = document.getElementById('subdiv')
      this.resetSelect('subdivision')
      if(myLocation == null){
        if(!subDiv.classList.contains('hidden')){
          subDiv.classList.add('hidden')
        }
      } else {
        this.populateOptions('subdivision-l', myLocation.soText)
        if(subDiv.classList.contains('hidden')){
          subDiv.classList.remove('hidden')
        }
      }
      // "sectortype" condition
      const mySector = locationArray.subOptions.find(field => field.soField == 'locationSector' )
      const sectDiv = document.getElementById('sector')
      this.resetSelect('locationSector')
      if(mySector == null){
        if(!sectDiv.classList.contains('hidden')){
          sectDiv.classList.add('hidden')
        }
      } else {
        this.populateOptions('locationSector-l', mySector.soText)
        if(sectDiv.classList.contains('hidden')){
          sectDiv.classList.remove('hidden')
        }
      }
    }// END caseLocation CONDITIONS
  }// END dynamicData function


  populateOptions(id, array){
    const Ul = document.getElementById(id)
    Ul.innerHTML = ''
    array.forEach(option => {
      const liBtn = document.createElement('li')
      const pBtn = document.createElement('p')
      pBtn.innerText = option
      liBtn.appendChild(pBtn)
      Ul.appendChild(liBtn)
      pBtn.onclick = this.mcsSelectOption.bind(this)
    })  
  }

  resetSelect(id){
    const reSelect = document.getElementById(id)
    reSelect.options[0].text = 'Select ... '
    reSelect.options[0].value = 'Select ... '
  }

  preventSelect(e){ // prevent default select menu
    e.preventDefault()
  }

  multiCheck(e){
    const clicked = e.target
    this.impacted = []
    if (clicked.classList.contains('multi-tick')){
      clicked.classList.toggle('multi-check')
    } 
    if (clicked.classList.contains('agree-box')){
      const multiBox = clicked.children[0]
      multiBox.classList.toggle('multi-check')
    }
    this.removeWarn('impactType')
  }


}

export default new Nstat()