import App from '../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../Router'
import Auth from '../../Auth'
import Data from '../../Data'
import Utils from '../../Utils'
import Nstat from '../../Nstat'

class PurchasesView {
  init(){    
    document.title = 'Purchases'  
    this.render()  
    Utils.initFont()  
    //reset initiated to prevent the function from activating twice
    Data.initiated = false
  }


  forumRulesModal(){ Utils.modalRules() }
  
  agreeCheck(){
    const agreeBox = document.getElementById('agree1')
    agreeBox.classList.toggle('agree-check')
    Nstat.removeWarn('agree')
  }

  checkoutSubmitHandler(e){
    e.preventDefault()
    //reset values
    Data.purchase = [] 
    Data.purchaseTotal = 0

    const selectedItems = []
    const radios = document.querySelectorAll('.select-item')
    const agreeBox = document.getElementById('agree1')
    radios.forEach(radiobtn => {
      if(radiobtn.classList.contains('select-active')){
        const thisValue = radiobtn.getAttribute('value')
        selectedItems.push(thisValue)
      }
    })
    if(selectedItems.length < 1){
      Data.notice = "Please make a selection to purchase"
      Utils.modalNotice()
      return
    }
    const forumM = Data.inArray('month', selectedItems)
    const forumY = Data.inArray('year', selectedItems)
    if(forumM == true || forumY == true){
      if(!agreeBox.classList.contains('agree-check')){
        Nstat.inputWarn('agree')
        return
      } 
    }
    Data.purchaseCodes = selectedItems
    selectedItems.forEach((pitem) => {
      const purchItem = Data.itemPrices.find(items => items.item  == pitem )
      Data.purchase.push(purchItem)
      Data.purchaseTotal += purchItem.price
    })
    Utils.cmodalPurchase()
  }

  quizHandler(e){
    const selectnew = e.target.closest('.select-item')
    if (selectnew.classList.contains('select-active')) { selectnew.classList.remove('select-active') }
    else { selectnew.classList.add('select-active') }
  }

  forumHandler(e){
    const thisBtn = e.target.closest('.select-item')
    const thisId = thisBtn.getAttribute('id')
    const aRadio = document.getElementById('monthOrder')
    const bRadio = document.getElementById('yearOrder')
    if (thisBtn.classList.contains('select-active')) { thisBtn.classList.remove('select-active') }
    else { 
      thisBtn.classList.add('select-active') 
      if(thisId == 'monthOrder'){ 
        if(bRadio.classList.contains('select-active')) { bRadio.classList.remove('select-active') }
      } 
      if(thisId == 'yearOrder'){ 
        if(aRadio.classList.contains('select-active')) { aRadio.classList.remove('select-active') }
      } 
    }
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">      
          ${Auth.currentUser._id ? html`
            <form id="formElem" class="form-center" action="#" method="post">
              
              <h2><strong>Forum Access</strong></h2>

              <div class="p-select-two h-center"> 
                <div class="p-radio">
                  <label>1 Month <br>
                    <div class='radio-holder'> 
                      <div id="monthOrder" class="select-item" value="month" @click="${this.forumHandler}"><div class="s-point"></div></div>
                    </div>
                  </label>
                  <div class="price"><strong>$15 </strong>USD</div>
                </div>

                <div class="p-radio">
                  <label>1 Year<br> 
                    <div class='radio-holder'> 
                      <div id="yearOrder" class="select-item" value="year" @click="${this.forumHandler}"><div class="s-point"></div></div>
                    </div>
                  </label>
                  <div class="price"><strong>$150 </strong>USD</div>
                </div>
              </div>
                ${Auth.currentUser.forumSubscription ? html``: html`
                <div class="newsub">
                  <div class="agree-box" @click="${this.agreeCheck}"><div id="agree1" ></div></div>
                  I agree to the <span id="forum-modal" @click="${this.forumRulesModal}">Forum Rules</span>
                </div>
                <p id="agree-wrn" class="input-require">* You must agree to the forum rules to purchase access.</p>
              `}

              ${Auth.currentUser.score ? html``: html`
                <div class="divide-line"></div>

                <h2><strong>Unlock Quiz and Leader boards</strong></h2>
                <div class="p-select-two h-center"> 
                  <div class="radio">
                    <div class='radio-holder'>
                      <div id="c-quiz" class="select-item" value="quiz" @click="${this.quizHandler}"><div class="s-point"></div></div>
                    </div>
                    <div class="price"><strong>$20 </strong>USD</div>
                  </div>
                </div>
              `}

              <br>
              <p>*Payments are non-refundable</p>
            
              <button class="redirect-button action forum-action-btn" submit @click=${this.checkoutSubmitHandler}>Pay</button>
            </form>
          `: html`

            <div class="h-center">
              <p class="app-info para">Please sign in to make a purchase:</p>
              <p class="app-info para"></p>
                <a href="/signin" class="home-button action" @click=${anchorRoute}>Sign In</a>
              </p>
            </div>
          `}
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new PurchasesView()