import { duration } from 'moment'
import App from './App.js'
import Auth from './Auth'


// ------------------------CONTENTS------------------------
  // inputWarn
  // removeWarn
  // clearWarning
  // validEmail
  // urlCheck
  // safeCheck
  // usernameCheck
  // passwordCheck

// ---- NOT USED ---------------
  // removeSpaces
  // sanitize
  // 


class Validate {

  constructor(){

    this.newToken = null
 
  }

  inputWarn(id, warning){
    const thisWarn = document.getElementById(id + '-wrn')
    thisWarn.style.maxHeight = Math.max(thisWarn.scrollHeight) + "px"
    thisWarn.innerHTML = warning
  }
  
  removeWarn(id){
    const thisWarn = document.getElementById(id + '-wrn')
    if(thisWarn.style.maxHeight == null){return}
    else{thisWarn.style.maxHeight = null}
  }

  //this function gets the id to pass to removewarn
  clearWarning(e){
    e.preventDefault()
    const myInput = e.target.getAttribute('id')
    this.removeWarn(myInput)  
  } 

  validEmail(email){
    const mailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(email.match(mailFormat)){
      //console.log('mail format is good')
      return true
    } else {
      //console.log('mail format is NOT good')
      return false
    }
  }

  urlCheck(input){
    const reg=/[^a-zA-Z0-9\=\.\-\&\_]+/;
    //console.log(reg.test(input)) //this returns false if it meets the conditions
    if(reg.test(input)){              
      return false
    }  else {
      return true
    }
  }
  
  safeCheck(input){
    const reg=/[^a-zA-Z0-9 '"\!\@\#\$\%\^\*\_\+\=\:\;\&\.\,\[\]\(\)\?\r\n\-]+/;
    //replace the curly quotes, else they throw an error
    const refinedInput = input.replace(/”/g, "\"").replace(/“/g, "\"").replace(/’/g, "'").replace(/‘/g, "'") //‘.?,!:;-[]()’:
    if(reg.test(refinedInput)){ 
      //this.inputWarn(input, '*Please avoid special characters')
      return false }  
    else { return true }
  }

  //dash not working - https://stackoverflow.com/questions/11829131/regular-expression-allow-dashes
  usernameCheck(input){
    const reg=/[^a-zA-Z0-9 \!\@\#\$\%\^\*\_\+\=\:\&\'\.\,\"\(\)\-]+/;
    const regNum=/[\d]{5}/
    //console.log(reg.test(input.value))
    if(reg.test(input.value)){ return false }  
    else { 
      if(regNum.test(input.value)){ return false } 
      else { return true }
    }
  }

  passwordCheck(input){
    const reg=/[^a-zA-Z0-9\!\@\#\$\%\^\*\&]+/;
    if(reg.test(input.value)){ 
      //this.inputWarn(input, '*Only letters, numbers and !@#$%^&* allowed')
      return false }  
    else { return true }
  }


//no need for the code below ------------------------------
  removeSpaces(input){
    return input.replace(/\s/g, '');
  }


  // SANITIZE - use sanitizeInput for URL params and user input (except email)  --------
  sanitize(input){
    if(input == null){
      return null
    } else {
      const lt = /</g, gt = />/g, ap = /'/g, ic = /"/g;
      const updatedInput = input.toString().replace(lt, "&lt;").replace(gt, "&gt;").replace(ap, "&#39;").replace(ic, "&#34;");
      return updatedInput
    }
  }
    /* SANITIZE - send all input data except emails -------------

    Try this one for my purposes:
    a shorter version of replace: 
    str.replace(/[\\$'"<]/g, "\\$&")

    https://stackoverflow.com/questions/3561493/is-there-a-regexp-escape-function-in-javascript

    function escapeRegExp(text) { // I added ><"'
      return text.replace(/[-[\]{}()*+?.,\\^><"'$|#\s]/g, '\\$&');
    }

    */

    

// injection string example
// removing <  and  =" ${  ){ /> combinations would kill the code
//<img src="http://unsplash.it/100/100?random" onload="alert('you got hacked');" /> <script>alert('you got hacked!')</script>

// valdiate on input
// sanitize on output
//meta characters used in script   & ; ` ' \ " | * ? ~ < > ^ ( ) [ ] { } $ \n \r



}

export default new Validate()