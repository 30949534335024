import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class ProgressView {
  init(){    
    document.title = 'My Progress'    
    this.render()  
    Utils.initFont()
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">      
          Quizzes completed and level badge
          Version 2
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new ProgressView()