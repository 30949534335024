[{
  "fieldName": "impactType",
  "options": [
    "Interrupted work or studies", 
    "Self-blame",
    "Depression",
    "Amnesia",
    "Flashbacks",
    "Shock",
    "Behaviour disorders",
    "Suicidal thoughts",
    "Attempted Suicide",
    "Other"
  ]
},{
  "fieldName": "perpAge",
  "options": [
    "Aged 0 - 9", 
    "Aged 10 - 19",
    "Aged 20 - 29",
    "Aged 30 - 39",
    "Aged 40 - 49",
    "Aged 50 - 59",
    "Aged 60 - 69",
    "Aged 60 - 79",
    "Aged 80 or older",
    "Unsure"
  ]
},{
  "fieldName": "perpGender",
  "options": [
    "Male", 
    "Female",
    "Other",
    "Unsure"
  ]
},{
  "fieldName": "perpKnown",
  "options": [
    "Well known", 
    "Known by their position", 
    "An online friend",
    "Unknown"
  ]
},{
  "fieldName": "caseReaction",
  "options": [
    "Didn't Realize",
    "Resign & Dissociation",
    "Freeze",
    "Flight",
    "Call for Help",
    "Deter",
    "Fight"
  ]
},{
  "fieldName": "caseReported",
  "options": [
    "Not reported", 
    "Awaiting trial",
    "Fined or other penalty",
    "Jailed",
    "Not convicted"
  ]
},{
  "fieldName": "caseFrequency",
  "options": [{
    "optionText": "One time",
    "subOptions": [{
      "soField": "caseType",
      "soText": ["Looking at private parts", "Groping or touching", "Rape", "Gang rape", "Major sexual Assault",  "Revenge porn (images/video)", "Averted sexual Assault"]
      }]
  },{
    "optionText": "Ongoing",
    "subOptions": [{
      "soField": "ongoingDuration",
      "soText": ["Days", "Weeks", "Months", "Years", "Still ongoing"]
      },{
      "soField": "caseType",
      "soText": ["Groping or touching", "Rape", "Gang rape", "Sexual assault", "Partner abuse", "Sex trafficking (slavery)", "Sex coercion",  "Revenge porn (images/video)", "Porn content coercion"]
      }]
  }]
},{
  "fieldName": "caseLocation",
  "options": [{
    "optionText": "Education or childcare facilities",
    "subOptions": [{
        "soField": "subdivision",
        "soText": ["Childcare", "Primary school", "Elementary school", "High school", "University or College", "Other education facilities"]
      },{
        "soField": "locationSector",
        "soText": ["Public", "Private"]
      },{
        "soField": "perpPosition",
        "soText": ["Teacher", "Staff", "Another student", "Visitor", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Healthcare and medical facilities or services",
    "subOptions": [{
        "soField": "subdivision",
        "soText": ["Hospital", "Medical clinic", "Specialist (dentist)", "Elderly residential facilities", "Medical home visit", "Other"]
      },{
        "soField": "locationSector",
        "soText": ["Public", "Private"]
      },{
        "soField": "perpPosition",
        "soText": ["Medical staff", "Other staff", "Another patient", "Visitor", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Work places",
    "subOptions": [{
        "soField": "subdivision",
        "soText": ["Government or military", "Public sector", "Private"]
      },{
        "soField": "perpPosition",
        "soText": ["Manager or boss", "Co-worker", "Visitor", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Public places or events (i.e. shops, clubs or public transport",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Meeting an online date", "Staff or organiser", "Another attendee", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Services and companies (i.e. accountants or floor polishers)",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Staff", "Another customer", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      },{
        "soField": "locationSector",
        "soText": ["Public", "Private"]
      }]
  },{
    "optionText": "Party or private social events",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Event organiser", "Another attendee", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Prison or correctional facilities",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Staff", "Another inmate", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Faith communities",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Faith leader", "General staff", "Another attendee", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Sports or recreational events",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Coach or manager", "General staff", "Another attendee", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "House of friend or relative",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Partner", "Family member", "Friend", "Another visitor or worker", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  },{
    "optionText": "Home",
    "subOptions": [{
        "soField": "perpPosition",
        "soText": ["Partner", "Family member", "Family Friend", "Visitor or worker", "Friend", "Medical staff", "Police or Security", "Emergency Responder", "Other"]
      }]
  }]
}]
