import App from '../../../App'
import {render, html} from 'lit/html.js'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Nstat from '../../../Nstat'

  //UX: If revising code to add return to previous tabs functioning
    //... then don't forget to reset all validation consts for each tab

class NewCaseView {
  init(){    
    document.title = 'Add New Incident'  
    this.render()  
    Utils.initFont()  
    const ageInput = document.getElementById("survivorAge")
    ageInput.onfocus = function() { Nstat.removeWarn("survivorAge") }.bind(Nstat)
    Nstat.resetCase()
  }


  submitNewCaseHandler(){
    Nstat.formValidtion()
    if(Nstat.formValidated == true) {
      Utils.cmodalAddCase()
    }
  }

  //--- FUNCTIONS ----see Nstat ---------------
  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel" id="contentpanelid">  
          
          <div class="step-bar"> 
            <div class="steps-wrapper">
              <div class="step1-holder">
                <nav id="step-1" class="step-first-holder" >
                  <div id="stepc1" class="step-circle sc-current">1</div>
                  <div id="stept1" class="step-text st-current">
                    User
                  </div>
                </nav>
              </div>
              <div class="step-box" >
                <div id="stepl2" class="step-line sl-ready"></div>
                <nav id="step-2" class="step-circle-pos">
                  <div id="stepc2" class="step-circle sc-ready">2</div>
                  <div id="stept2" class="step-text st-ready">
                    Incident
                  </div>
                </nav>
              </div>
              <div class="step-box">
                <div id="stepl3" class="step-line sl-ready"></div>
                <nav id="step-3" class="step-circle-pos" >
                  <div id="stepc3" class="step-circle sc-ready">3</div>
                  <div id="stept3" class="step-text st-ready">
                    Place
                  </div>
                </nav>
                
              </div>
              <div class="step-box">
                <div id="stepl4" class="step-line sl-ready"></div>
                <nav id="step-4" class="step-circle-pos" >
                  <div id="stepc4" class="step-circle sc-ready">4</div>
                  <div id="stept4" class="step-text st-ready">
                    Perpetrator
                  </div>
                </nav>
               
              </div>


            </div>
          </div>

          <br>
          <form class="form-full" name="case-form" method="post">
            <input id="user" type="hidden" name="user" value="${Auth.currentUser.id}" />
            <input id="survivorGender" type="hidden" name="survivorGender" value="${Auth.currentUser.usergroup}" />

<!--  USER TAB 1 -->
            <div id="tab-1" class="tab-section">
              <h3 class="case-h2">User</h3>

<!-- caseName -->
              <label class="para label">Incident title:</label> (For your reference only)
              <div class="input-group">
                <input class="para" name="caseTitle" placeholder="Incident title ...">
              </div>

  <!-- survivorAge -->
              <div class="input-group">
                <label class="para label">Age at time of Incident:</label>
                <p id="survivorAge-wrn" class="input-require">* Valid age required</p>
                <input title="Survivor's age" class="para input-number-year" type="number" id="survivorAge" name="survivorAge" max="110" min="0">
              </div>

  <!-- survivorDisabilityy /n-->
              <label class="para label">Did you have a visibility Disability?</label>
              <p id="survivorDisability-wrn" class="input-require">* Selection required</p>
              <div class="select-two h-center"> 
                <div class="radio">
                  <input title="Yes Disability" id="survivorDisabilityy" name="survivorDisability" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="With visible disability">
                  <label for="toggle-on">Yes</label>
                </div>
                <div class="radio">
                  <input title="No Disability" id="survivorDisabilityn" name="survivorDisability" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="No visible disability">
                  <label for="toggle-off">No</label>
                </div>
              </div>

  <!-- survivorImpacty -->
              <label class="para label">Were you impacted by the incident?</label>
              <p id="survivorImpact-wrn" class="input-require">* Selection required</p>
              (or no choices selected below)
              <div class="select-two h-center"> 
                <div class="radio">
                  <input title="Yes Impact" id="survivorImpacty" name="survivorImpact" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="Yes, I was impacted by it">
                  <label for="toggle-on">Yes</label>
                </div>
                <div class="radio">
                  <input title="No Impact" id="survivorImpactn" name="survivorImpact" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="No, I wasn't">
                  <label for="toggle-off">No</label>
                </div>
              </div>


  <!-- impactType -->
              <div id="multione" class="input-group">
                <label class="para label">If yes, how were you impacted?</label>
                (Select one or more)
                <p id="impactType-wrn" class="input-require">* Selection required if impacted</p>
                  ${Data.statisticsOptions.find(field => field.fieldName == "impactType" )
                    .options
                    .map(option => html `
                      <div class="multi-select">
                        <div class="agree-box" @click="${Nstat.multiCheck.bind(Nstat)}">
                          <div class="multi-tick"></div> 
                        </div> 
                        <p class="multi-text">${option}</p>
                      </div>
                  `)}
              </div><!-- end multione-->

              <div id="next1" class="redirect-button forum-action-btn action" @click=${Nstat.nextTabHandler.bind(Nstat)}><strong>Next</strong></div>
            </div>

<!-- INCIDENT TAB 2   -->
            <div id="tab-2" class="tab-section hidden">
              <h3 class="case-h2">Incident</h3>

  <!-- caseYear -->
              <div class="input-group">
                <label class="para label">Year incident took place:</label>
                <p id="caseYear-wrn" class="input-require">* Valid year required</p>
                <input title="Year" class="para input-number-year" type="number" id="caseYear" name="caseYear" min="1930" max="${new Date().getFullYear()}" value="${new Date().getFullYear()}">
              </div>

  <!-- caseReported -->
              <div class="input-group">
                <label class="para label">Was it reported?</label>
                <p id="caseReported-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Reported" id="caseReported" class="para msc-input" type="text" name="caseReported" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select status ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseReported-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "caseReported" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>
              
<!-- Condition 2A1) for duration and type -->
   <!-- caseFrequency -->
              <div class="input-group">
                <label id="freq-label" class="para label">How often?</label>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                <p id="caseFrequency-wrn" class="input-require">* Selection required</p> 
                  <select title="Frequency" id="caseFrequency" class="para msc-input" type="text" name="caseFrequency" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select frequency ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseFrequency-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "caseFrequency" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option.optionText}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

 <!-- Condition 2A2) depending on frequency - ongoing -->
   <!-- ongoingDuration -->
              <div id="ongoing" class="input-group hidden">
                <label class="para label">How long did it go on for?</label>
                <p id="ongoingDuration-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Duration" id="ongoingDuration" class="para msc-input" type="text" name="ongoingDuration" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select duration ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="ongoingDuration-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName  == "caseFrequency" )
                        .options.find(field => field.optionText == "Ongoing" )
                        .subOptions.find(field => field.soField  == "ongoingDuration" )
                        .soText
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

<!-- Condition 2A3) depending on frequency -->
  <!-- caseType -->
              <div class="input-group">
                <label class="para label">What type of incident?</label>
                <p id="caseType-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Case Type" disabled id="caseType" class="para msc-input" type="text" name="caseType" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select type ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseType-l" class="msc-ul para"></ul> 
                  </div>
                </div>
              </div>

    <!-- caseReaction -->
              <div class="input-group">
                <label class="para label">Main response:</label>
                <p id="caseReaction-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Main Response" id="caseReaction" class="para msc-input" type="text" name="caseReaction" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select response ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseReaction-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "caseReaction" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

              <div id="next2" class="redirect-button disabled" @click=${Nstat.nextTabHandler.bind(Nstat)}><strong>Next</strong></div>
            </div>

<!-- PLACE TAB 3 -->
            <div id="tab-3" class="tab-section hidden">
              <h3 class="case-h2">Place</h3>

<!-- CONDITION 3A1) for states .... -->
  <!-- caseCountry -->
              <div class="input-group">
                <label class="para label">Which country?</label>
                <p id="caseCountry-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Country" id="caseCountry" class="para msc-input" type="text" name="caseCountry" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select country ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseCountry-l" class="msc-ul para">
                    ${Data.priority.map(country => html `
                      <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                      `)}
                      <div class="priority"></div>
                    ${Data.allCountries.map(country => html `
                        <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                      `)}
                    </ul> 
                  </div>
                </div>
              </div>

<!-- CONDITION 3A2) if country has states .... -->
  <!-- caseState -->
              <div id="states" class="input-group hidden">
                <label class="para label">Which state?</label>
                <p id="caseState-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="State" id="caseState" class="para msc-input" type="text" name="caseState" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select state ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseState-l" class="msc-ul para">
 
                    </ul> 
                  </div>
                </div>
              </div>

<!-- CONDITION 3B1) for subdivision & locationSector -->
<!-- CONDITION 4A1) for perpPosition .... -->
  <!-- caseLocation -->
              <div class="input-group">
                <label id="loc-label" class="para label">Which place?</label>
                <p id="caseLocation-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Location" id="caseLocation" class="para msc-input" type="text" name="caseLocation" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select place ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseLocation-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "caseLocation" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option.optionText}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

<!-- CONDITION 3B2) depending on place -->
  <!-- subdivision -->
              <div id="subdiv" class="input-group hidden">
                <label class="para label">Which subdivision?</label>
                <p id="subdivision-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Place subdivision" id="subdivision" class="para msc-input" type="text" name="subdivision" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select section ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="subdivision-l" class="msc-ul para"></ul> 
                  </div>
                </div>
              </div>

<!-- CONDITION 3B3) depending on place -->
  <!-- locationSector -->
              <div id="sector" class="input-group hidden">
                <label class="para label">Which sector?</label>
                <p id="locationSector-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Sector" id="locationSector" class="para msc-input" type="text" name="locationSector" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="locationSector-l" class="msc-ul para"></ul> 
                  </div>
                </div>
              </div>   


              <div id="next3" class="redirect-button disabled" @click=${Nstat.nextTabHandler.bind(Nstat)}><strong>Next</strong></div>
            </div>

<!-- PERP TAB 4 -->
            <div id="tab-4" class="tab-section hidden">
              <h3 class="case-h2">Perpetrator</h3>

  <!-- perpAge -->
              <div class="input-group">
                <label class="para label">What was the age group of the perpetrator?</label>
                <p id="perpAge-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Age" id="perpAge" class="para msc-input" type="text" name="perpAge" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select age group ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="perpAge-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "perpAge" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

  <!-- perpGender -->
              <div class="input-group">
                <label class="para label">What was their gender?</label>
                <p id="perpGender-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Gender" id="perpGender" class="para msc-input" type="text" name="perpGender" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select gender ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="perpGender-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "perpGender" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

<!-- CONDITION 4A2) ... depending on place -->
  <!-- perpPosition -->
              <div class="input-group">
                <label class="para label">What was their position?</label>
                <p id="perpPosition-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Position" disabled id="perpPosition" class="para msc-input" type="text" name="perpPosition" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select position ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="perpPosition-l" class="msc-ul para"></ul> 
                  </div>
                </div>
              </div>

  <!-- perpKnown -->
              <div class="input-group"> <!-- perpKnown -->
                <label class="para label">Were they known to you?</label>
                <p id="perpKnown-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Familiarity" id="perpKnown" class="para msc-input" type="text" name="perpKnown" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="perpKnown-l" class="msc-ul para">
                      ${Data.statisticsOptions.find(field => field.fieldName == "perpKnown" )
                        .options
                        .map(option => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                        `)}
                    </ul> 
                  </div>
                </div>
              </div>

              <div id="next4" class="redirect-button disabled" @click=${this.submitNewCaseHandler.bind(this)}><strong>Submit</strong></div>
            </div>
<!-- end tabs -->

          </form>
        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new NewCaseView()