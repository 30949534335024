import App from './App.js'
import Auth from './Auth'

// ---CONTENTS---
// isMobile
// formatParagraphText
// scrollToTop
// scrollTopSmooth
// scrollTopWarning
// checkonlinestatus() - not working...
// randomizer
// modals
// Update font size

class Utils {

  constructor(){
    // Font codes
    this.myfont = 1.2
    this.updatedfont = null
    this.warnItems = []
    this.modalDiv = document.getElementById('modal-div')

    //Emitter worked but not only global functions (not page render...)
    const EventEmitter = require('events')
    this.eventEmitter = new EventEmitter ()
    /*
    // add the listener event
    Utils.eventEmitter.once('deleted', () => {
      console.log('Hello world!');
      this.render()//not a function??
    })
    //add the emit event
    Utils.eventEmitter.emit('deleted');
    */

  }

  isMobile(){
    let viewportWidth = window.innerWidth
    if(viewportWidth <= 768){ return true }
    { return false }
  }


  formatParagraphText(text, type){
     //1) remove the quotation marks
     let breaker = null
     if(type == 'textarea'){ breaker = '&#13;&#10;' } 
     else if(type == 'reply'){ breaker = ' ' } 
     else { breaker = '<br>' } 
     const pTextRefine = text.slice(1, -1); 
     //2) split into array
     const pParts = pTextRefine.split("\\")
     //3) create the final entry
     let finalEntry = null
     if(pParts.length > 1){
       pParts.forEach((part, i) =>{
         if(i == 0){
           finalEntry = part  + breaker
         } else if( i > 0  && i < pParts.length-1){
           const newpart = part.substring(1)
           finalEntry += newpart + breaker
         } else if(i == pParts.length-1) {
           const newpart = part.substring(1)
           finalEntry += newpart
         }
       })
     } else {
      //here there was only one string so display as is
       finalEntry = pParts
     }
     return finalEntry
  }

  scrollToTop(){
    const myDiv = document.getElementById('contentpanelid');
    myDiv.scrollTop = 0;
  }

  scrollTopSmooth(){
    const myDiv = document.getElementById('contentpanelid');
    myDiv.scrollTo( {top: 0, behavior: 'smooth'})
  }

  scrollTopWarning(){
    const container = document.getElementById('contentpanelid');
    if(this.warnItems.length > 0){
      const topWarn = document.getElementById(this.warnItems[0] + '-wrn') 
      topWarn.scrollIntoView({
        top: -20,
        behavior: 'smooth'
      })
    }
  }



 //not working in sequence... see Auth line 170
  async checkOnlineStatus(){
    try {
      const online = await fetch(`${App.apiBase}/topic/online`, 
      { method: 'GET',})
      if (online.status >= 200 && online.status < 300); // either true or false
      { console.log('online') }
      //console.log(online.status)
    } catch (err) {
      localStorage.removeItem('accessToken')
      Auth.currentUser = {}
      //console.log('offline')
      return false; // definitely offline
    }
  }

  randomizer(array){
    const randomArray = array //shuffle the questions
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    return randomArray
  }

 
  //------Notification Modal Links ---------------class = mi-name-------------------------------
  //this should be one function mainModal with class and info passed through = better programming
  modalPquizlead(){
      this.modalDiv.innerHTML = `<main-modal modalClass="purchase" modalInfo="quizlead"></main-modal>` 
  }
  modalPforum(){
    this.modalDiv.innerHTML = `<main-modal modalClass="purchase" modalInfo="forum"></main-modal>` 
  }
  modalPstats(){
    this.modalDiv.innerHTML = `<main-modal modalClass="purchase" modalInfo="stats"></main-modal>` 
  }
  modalPrevQuiz(){
    this.modalDiv.innerHTML = `<main-modal modalClass="prevquiz" modalInfo="prevquiz"></main-modal>` 
  }
  modalRules(){
    this.modalDiv.innerHTML = `<main-modal modalClass="forum-rules" modalInfo="forum-rules"></main-modal>` 
  }
  modalPrivacy(){
    this.modalDiv.innerHTML = `<main-modal modalClass="privacy-policy" modalInfo="privacy-policy"></main-modal>` 
  }
  modalNotice(){
    this.modalDiv.innerHTML = `<main-modal modalClass="notice" modalInfo="notice"></main-modal>` 
  }


  //------Confirmation Modal Links ------------class = mi-name----------------------------------
  cmodalNoChange(){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="editcasewarn" modalInfo="nochanges"></confirm-modal>` 
  }
  cmodalPpRequired(){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="editcasewarn" modalInfo="pprequired"></confirm-modal>` 
  }
  cmodalEditCaseReady(){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="casefunction" modalInfo="editIncident"></confirm-modal>` 
  }
  cmodalAddCase(){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="casefunction" modalInfo="addIncident"></confirm-modal>` 
  }

  cmodalPurchase(){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="purchase"></confirm-modal>` 
  }

  //reformat all previous modals as follows - only add data as required
  confirmModal(modalClass, modalSource, modalInfo){
    this.modalDiv.innerHTML = `<confirm-modal modalClass="${modalClass}" modalSource="${modalSource}" modalInfo="${modalInfo}"></confirm-modal>`
  }

  closeModal(){ this.modalDiv.innerHTML = `` }

  // Font Settings ---------------------------------------------------------

  //add if statement to see if the user has a saved font value
  initFont(){
    //if user && user.fontPref ....
    if (!Auth.currentUser.fontPref){
      if (this.updatedfont == null ){
        return
      } else {
        this.fontUpdate()
      }
    } else {
      this.myfont = Auth.currentUser.fontPref
      this.updatedfont = this.myfont + 'em'
      this.fontUpdate()
    }
  }

  fontUpdate(){
    const fontPrefs = document.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = this.myfont +'em';
    })
  }

  

  //sizes in change are overridden if user has a saved "fontPref"
  //need to add button to save font sizes
  downFont(){
    if (this.myfont <= 0.8) {
      this.myfont = 0.8  
      //console.log(this.myfont + " is too small")
     } else {
      this.myfont -= 0.2 
       const newfont = this.myfont.toFixed(1)
       this.myfont = newfont
       this.updatedfont = this.myfont + 'em'
     }
  }

  upFont(){
    if (this.myfont >= 5) {
      this.myfont = 5  
      //console.log(this.myfont + "is too big")
     } else {
       const newvalue = (+this.myfont)+0.2
       const newfont = newvalue.toFixed(1)
       this.myfont = newfont
       this.updatedfont = this.myfont + 'em'
     }
  }
  // End Font Settings ---------------------------------------------------------



}


export default new Utils()