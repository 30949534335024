import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Nstat from '../../../Nstat'
import Validate from '../../../Validate'
import RegisterApi from '../../../auth/RegisterApi'

//Note: removed form and Name fields as an error blocked the browser!

class RegisterView {
  init(){    
    document.title = 'Register'    
    this.userData = {}
    this.render()  
    this.initRegister()
    Utils.initFont() 
    document.addEventListener('keyup', (e) => {
      this.enterBtn(e)
    })
  }

  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      this.registerHandler()
    }
  }

  initRegister(){
    if(Validate.newToken == null){
      console.log('no token... redirecting...')
      gotoRoute('/signup')
    }
  }

  async registerHandler(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    const formstatus = this.validateForm()
    if(formstatus == false){ return }
    else {
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting waitSource="register"></c-waiting>'
      try {
        const newUser = JSON.stringify(this.userData);
        const registered = await RegisterApi.registerNewUser(newUser)
        if(registered == false){
          statusTab.innerHTML = '<br><br><br><c-error errorSource="validating" errorInfo="register"></c-error>'
        } else { return }
      }catch(err){
        statusTab.innerHTML = '<br><br><br><c-error errorSource="validating" errorInfo="register"></c-error>'
      }
    }
  }


  ugSelect(){
    Validate.removeWarn('usergroup')
  }


  //Utils.warnItems.push("survivorAge") 
  //Reset
  //Utils.warnItems = []
  //
  //Utils.scrollTopWarning()  

  validateForm(){ 
    console.log('validating form')
    Utils.warnItems = []
    const uninput = document.getElementById("user-name")
    const coinput = document.getElementById("caseCountry")
    const steinput = document.getElementById("caseState")
    const ageinput = document.getElementById("age")
    const uginput = document.getElementById("usergroup")
    const pwinput = document.getElementById("password")

    let validated = true
    let stateField = false
    let thisCountry = null
    
    if(uninput.value == ""){
      Validate.inputWarn(uninput.id, "*Required")
      Utils.warnItems.push(uninput.id)
      validated = false
    } else {
      const unStatus = Validate.usernameCheck(uninput)
      if (unStatus == false){
        Validate.inputWarn(uninput.id, "*Only 4 digits & no special characters")
        Utils.warnItems.push(uninput.id)
        validated = false
      } else if (uninput.value.length < 4 || uninput.value.length > 20 ){
        Validate.inputWarn(uninput.id, "*Enter 4 to 20 characters")
        Utils.warnItems.push(uninput.id)
        validated = false
      } 
    } 
    //Password validation 
    if(pwinput.value == ""){
      Validate.inputWarn(pwinput.id, "* Required")
      Utils.warnItems.push(pwinput.id)
      validated = false
    } else {
      const pwStatus = Validate.passwordCheck(pwinput)
      if (pwinput.value.length < 6 || pwinput.value.length > 20 ){
        Validate.inputWarn(pwinput.id, "*Enter 6 to 20 characters")
        Utils.warnItems.push(pwinput.id)
        validated = false
      } else if (pwStatus == false){
        Validate.inputWarn(pwinput.id, "*Only letters, numbers and !@#$%^&* allowed")
        Utils.warnItems.push(pwinput.id)
        validated = false
      }
    }
    //age validation
    if (isNaN(ageinput.value) || ageinput.value < 1 || ageinput.value > 110) {
      Validate.inputWarn(ageinput.id, "*Please enter a valid age")
      Utils.warnItems.push(ageinput.id)
      validated = false
    } 
    // usergroup validation
    if(uginput.value.includes('Select')){
      Validate.inputWarn(uginput.id, "*Selection required")
      Utils.warnItems.push(uginput.id)
      validated = false
    }
    // country and state (if)
    if(coinput.value.includes('Select')){
      Validate.inputWarn(coinput.id, "*Selection required")
      Utils.warnItems.push(coinput.id)
      validated = false
    } else { 
      let myCountry = Data.allCountries.find(field => field.countryName == coinput.value )
      thisCountry = myCountry
      if(myCountry.state){
        stateField = true
        if(steinput.value.includes('Select')){
          Validate.inputWarn(steinput.id, "*Selection required")
          Utils.warnItems.push(steinput.id)
          validated = false
        }
      }
    }

    if(validated == true){ 
      const user = {}
      user.userName = uninput.value
      user.age = ageinput.value
      user.flagStatus = thisCountry.flag
      user.countryCode = thisCountry.countryCode
      user.usergroup = uginput.value
      user.password = pwinput.value
      if(stateField == true){ user.state = steinput.value }
      this.userData.user = user
      this.userData.token = Validate.newToken
      return true } 
     else { 
      //scroll to top
      Utils.scrollTopWarning() 
      return false }
  }

  
  render(){
    const template = html`
      
      <div class="content-panel h-center">
        <div class="sign-full"> 
          <div id="main-tab" >
            <img class="signinup-logo-mobile" alt="${Data.apptitle}" class="logo-home" src="/images/icons/1-logo-${Data.appcode}.svg" >
            <img class="signinup-logo-mobile" alt="${Data.apptitle}" src="/images/icons/2-apptext-${Data.appcode}.svg" ><br>
            <p class="form-name">Add your details</p>  
            <div class="form-left">
              <div class="input-group">
                <label class="para label">User name:</label>
                <p id="user-name-wrn" class="input-require">* User name required (max 20 characters)</p>
                <input id="user-name" class="para" placeholder="A unique user name ..." @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
              </div>

              <div class="input-group">
                <label class="para label">Which country?</label>
                <p id="caseCountry-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="Country" id="caseCountry" class="para msc-input" type="text" name="caseCountry" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select country ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseCountry-l" class="msc-ul para">
                    ${Data.priority.map(country => html `
                      <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                      `)}
                      <div class="priority"></div>
                    ${Data.allCountries.map(country => html `
                        <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                      `)}
                    </ul> 
                  </div>
                </div>
              </div>

              <div id="states" class="input-group hidden">
                <label class="para label">Which state?</label>
                <p id="caseState-wrn" class="input-require">* Selection required</p>
                <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <select title="State" id="caseState" class="para msc-input" type="text" name="caseState" @mousedown="${Nstat.preventSelect}">
                    <option disabled selected value="Select">Select state ...</option>
                  </select>
                </div>
                <div class="msc-wrap hidden">
                  <div class="msc-list-box">
                    <ul id="caseState-l" class="msc-ul para">

                    </ul> 
                  </div>
                </div>
              </div>

              <div class="input-group">
                <label class="para label">Age:</label>
                <p id="age-wrn" class="input-require">* Valid age required</p>
                <input title="User's age" class="input-number-year para" type="number" id="age" max="110" min="0" @keyup=${Validate.clearWarning.bind(Validate)}>
              </div>

              <div class="input-group">
                <label class="para label">Your gender:</label>
                <p id="usergroup-wrn" class="input-require">* Selection required</p> 
                <div class="msc-wrap" @click="${this.ugSelect.bind(Validate)}">
                  <select title="Gender" id="usergroup" class="para" type="text" name="usergroup">
                    <option disabled selected hidden value="Select">Select ...</option>
                    <option value="azure">Male</option>
                    <option value="peach">Female</option>
                    <option value="forrest">Other</option>
                  </select>
                </div>
              </div>

              <div class="input-group">
                <label id="freq-label" class="para label">Password:</label>
                <p id="password-wrn" class="input-require">* Password required</p>
                <input id="password" class="para" type="password" placeholder="Password" @keyup=${Validate.clearWarning.bind(Validate)} maxlength="20">
              </div>

              
              <button class="submit-btn" @click=${this.registerHandler.bind(this)}>Register</button>
            </div>
            <br>
            <a href="/" class="home-button primary" @click=${anchorRoute}>Home</a>
          </div>
          <div id="status-tab"></div>
        </div>
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new RegisterView()