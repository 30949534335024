import {LitElement, html} from 'lit'
import {anchorRoute, gotoRoute} from '../Router'
import Auth from '../Auth' // for user auth
import Data from '../Data' // for app variables
import Utils from '../Utils'

customElements.define('main-app-header', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      pagename: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.navActiveLinks()
  }

  navActiveLinks(){	
    const currentPath = window.location.pathname
    const navLinks = this.shadowRoot.querySelectorAll('.app-top-nav a, .app-side-menu-items a')
    navLinks.forEach(navLink => {
      if(navLink.href.slice(-1) == '#') return
      if(navLink.pathname === currentPath){			
        navLink.classList.add('active')
      }
    })
  }

  
  hamburgerClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    holder.style.width = "100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.5)"
    appMenu.style.left = "0"
  }


  closeMenuClick(){  
    const appMenu = this.shadowRoot.getElementById('ps-drawer')
    const holder = this.shadowRoot.getElementById('drawer-backdrop-holder')
    const backdrop = this.shadowRoot.getElementById('drawer-backdrop')
    appMenu.style.left = "-100%"
    backdrop.style.backgroundColor = "rgb(0,0,0,0.0)"
    // wait half a second
        setTimeout(function () {
          holder.style.width = "0"
      }, 500);
  }
  
  menuClick(e){
    e.preventDefault()
    const currentPath = window.location.pathname
    const pathname = e.target.closest('a').pathname
    if(currentPath == pathname){return}
    else { 
      const mobile = Utils.isMobile()
      if (mobile == true){
        this.closeMenuClick()
      }
      gotoRoute(pathname)
    }
  }

  signOutHandler(e){
    e.preventDefault()
    Auth.signOut()
  }


  quizleadModal(){ Utils.modalPquizlead() }
  forumModal(){ Utils.modalPforum() }
  statsModal(){ Utils.modalPstats() }

//          height: var(--app-header-height);
  render(){    
    return html`
      <style>      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .app-header {
          position: fixed;
          top: 0;
          right: 0;
          left: 0;
          height: 60px; 
          color: var(--three-color);
          z-index: 15;
        }
        
        #burger-div, #logo-div { width: 19vw; align-items: center; display: inline-block;}
        #title-div { border-bottom: 1px solid var(--two-color);  width: 60vw; 
          padding: 0px; display: inline-block;  height: 100%; }
        h1 { padding-top: 5px; height: 100%; 
          font-family: 'bree', Cambria, Georgia, Times, 'Times New Roman', serif, sans-serif; 
          display: flex; align-items: center; justify-content: left; }

        #burger-div { justify-content: left; }
        #logo-div {  height: 20px; justify-content: right; }

        #burger-icon { width: 35px; cursor: pointer; top: 10px; position: absolute;  left: 8vw; }
        .page-title { color: var(--app-header-txt-color);  font-size: var(--app-header-title-font-size); }
        .logo-home { transition: 0.6s ease-in-out; width: 40px; top: 10px; position: absolute;  right: 8vw; }

        .logo-home:hover { transform: rotate(20deg);}

        #drawer-backdrop-holder{
          position: absolute;
          top: 0;
          left: 0;
          width: 0;  
          height: 100vh;
          z-index: 10;
        }
        #drawer-backdrop{
          position: relative;
          width: 100%;  
          height: 100%;
          background-color: rgb(0,0,0,0.0);
          transition: background-color .5s ease-out;
        }

        #ps-drawer {
          position: absolute;
          z-index: 999;
          top: 0;
          height: 100vh;
          width: 50vw;
          background-color: white;
          box-shadow: 4px 0px 5px rgba(0,0,0,0.3);
          padding: 0 5vw;
          transition: .5s ease-out;
          left: -100%;
        }

        #nav-wrapper {
          position: absolute;
          top: 0;
          left: 0;
          padding:  3vh 3vh;
          width: 100%;
          height: 100vh;
          overflow: auto;
        }

        #drawer-header { width: 100%; }

        .close-drawer {
          width: 25px;
          position: absolute;
          cursor: pointer;
          right: 15px;
          top: 15px;
        }

        .ltext-icon { width: 150px; margin: auto; }

        .app-side-menu-items { display: block; }
        .app-side-menu-items a {
          display: block;
          padding: .5em;
          text-decoration: none;
          font-size: 1em;
          color: var(--three-color);
          text-align: left;
        } 
        .app-side-menu-items a:hover { color: var(--two-color); }

     /* active nav links */
        .app-side-menu-items a.active {
          font-weight: bold;
          border: 1px solid var(--two-color);
        }

        .disabled { color: #5c5b5b;  cursor: pointer;
          padding: 0em  .5em  .5em .5em ; 
          font-size: 0.8em; }
        .disabled:hover {color: #3c3b3b;}
        .m-locked { width: 18px; margin-top: 5px; transition: 0.4s ease-in-out; }
        .disabled:hover .m-locked { transform: rotate(30deg);}

        ::-webkit-scrollbar {width: 15px;}
        ::-webkit-scrollbar-track { background: var(--bginfo-color); border-radius: 500px; }
        ::-webkit-scrollbar-thumb { background: var(--brand-color); border-radius: 500px; }
        ::-webkit-scrollbar-thumb:hover { background: var(--two-color); }


        @media all and (max-width: 768px){   
          
          .app-header { height: 50px; }
          .page-title { font-size: 1.2em; }
          #nav-wrapper { padding:  1vh 3vh; overflow: auto; }
          .mobile-hide { display: none; }
          #drawer-header { width: 100%; margin-top: 20px;}
          .app-side-menu-items {
            margin-top: 15px;
          }

        }

        @media all and (max-width: 500px){ #ps-drawer { width: 80vw; } }

        @media all and (max-width: 450px){ 
          #burger-div, #logo-div { align-items: center; }
          #burger-icon { left: 6vw; }
          .logo-home {  right: 6vw; }
        }

        @media all and (max-width: 350px){ 

          #title-div { font-size: .9em; height: 90%; }
          .logo-home {  right: 4vw; }
          #burger-icon {  left: 4vw; }
          #burger-div, #logo-div { width: 16vw; }
          
        }

        @media all and (min-width: 769px){  

          .feedback-mobile { display: none; }
          .desktop-hide { display: none; }
          #burger-div { display: none; }
          #title-div { margin-left: 30vw; width: 47vw; align-items: left; justify-content: left;}
          .logo-home { width: 80px;  }
          .ltext-icon { width: 150px; margin: auto; }

          #ps-drawer {
            position: fixed;
            width: 25vw;
            height: 100%;
            bottom: 0;
            left: 0;
            padding: 0;
            overflow: hidden;
          }

          #side-banner  {
            min-width: 25vw;
            min-height: 100vh;
            pointer-events: none;
          }
        }

      </style>

      <header class="app-header">
        <div id="burger-div" class="header-items"> 
          <img id="burger-icon" class="desktop-hide" alt="Menu" @click="${this.hamburgerClick}" src="/images/icons/${Data.appcode}04-menu.svg">
        </div>

        <div id="title-div" class="header-items">
          <h1 class="page-title">${this.pagename ? html`${this.pagename}`:``}</h1>
        </div>
        <div id="logo-div" class="header-items">
          <a href="/" @click="${anchorRoute}"><img class="logo-home" alt="Home Icon" src="/images/icons/1-logo-${Data.appcode}.svg"></a>
        </div>

        <div id="drawer-backdrop-holder"><div id="drawer-backdrop"></div></div>
        <div id="ps-drawer">

          <img id="side-banner" class="mobile-hide" alt="Side Banner"  src="/images/icons/${Data.appcode}-desktop-menu.png">
          <feedback-widget class="feedback-mobile"></feedback-widget>
          <div id="nav-wrapper"> 
            <div id="drawer-header">
              <img class="close-drawer desktop-hide" alt="Close"  src="/images/icons/${Data.appcode}07-close.svg" @click="${this.closeMenuClick}">

              <div class="header-items"> 
                <img class="ltext-icon desktop-hide" alt="${Data.apptitle}" src="/images/icons/2-apptext-${Data.appcode}.svg">
                <img class="ltext-icon mobile-hide" alt="${Data.apptitle}" src="/images/icons/${Data.appcode}-title-wh.svg">
              </div>

            </div>
            

            <nav class="app-side-menu-items">
              <a href="/" @click="${this.menuClick}">Home</a>
              <a href="/info" @click="${this.menuClick}">Information</a>
              <a href="/lessons" @click="${this.menuClick}">Lessons</a>
<!-- Quiz and Leaderboards access -->
              ${Auth.currentUser.quizLevel? html`
                <a href="/quiz/menu" @click="${this.menuClick}">Quizzes</a>
                <a href="/quiz/leaderboards" @click="${this.menuClick}">Leader boards</a>
              ` : html`
                <p class="disabled" @click="${this.quizleadModal}">Quizzes 
                  <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
                </p>
                <p class="disabled" @click="${this.quizleadModal}">Leader boards 
                  <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
                </p>
              `}
<!-- Forum access Auth.currentUser.forumSubExpiry && Auth.currentUser.forumSubExpiry >= Auth.thisDay  --> 
              ${Auth.currentUser.forumSubExpiry && Auth.currentUser.forumSubExpiry >= Auth.thisDay ? html` 
                <a href="${Data.forumlink}" @click="${this.menuClick}">Forum</a>
              `:html`
                <p class="disabled" @click="${this.forumModal}">Forum 
                  <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
                </p>
              `}
<!-- Stats access -->
              ${!Auth.currentUser.score && !Auth.currentUser.forumSubExpiry ? html`
                <p class="disabled" @click="${this.statsModal}">Statistics 
                <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
                </p>
              `:html`
                <a href="/stats/main" @click="${this.menuClick}">Statistics</a>
              `}

              <a href="/purchases" @click="${this.menuClick}">Purchases</a>
              <a href="/settings" @click="${this.menuClick}">Settings</a>

              ${localStorage.accessToken == null? html`
                <a href="/signin" @click="${this.menuClick}">Sign In</a>
              ` : html`
                <a href="/user/profile" @click="${this.menuClick}">Profile</a>
                <a href="#" @click="${this.signOutHandler}">Sign Out</a>
              `}
            
            </nav> 
          </div>
        </div>

      </header>

    `
  }
  
})
