import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, navClick } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import UserApi from '../../../auth/UserApi'

// VERSION 2 NOTES BELOW

class LeaderboardsView {
  init(){    
    document.title = 'Leader Boards'   
    this.level = null
    this.allusers = null
    this.tiervalue = null
    this.render()  
    this.initLevel()
  }

  // POSSIBLE VERSION 2 SOLUTIONS
    // ------- if too many users -------
    // -- if in current users group, show another 19 with a random array.
    // or SCROLL to position of current user - add id to the button...
    // --show first 20 of each group


  async getusers(){
    if(Data.leaderUsers == null){
      Data.leaderUsers = await UserApi.getLeaderUsers()
    }
    const tieronevalue = this.level * 10
    this.tiervalue = tieronevalue
    if(this.tiervalue == 100){
      const highusers = Data.leaderUsers.filter(user => user.score >= tieronevalue ) 
      this.allusers = highusers
    } else {
      const levelusers = Data.leaderUsers.filter(user => user.score >= tieronevalue && user.score <= tieronevalue + 9 ) 
      this.allusers = levelusers
    }
    this.render()
  }


  async initLevel(){
    let url = window.location.href
    if(url.includes('?')){
      const urlParams = new URLSearchParams(location.search)
      const pLevel = urlParams.get('level') 
      const numLevel = parseInt(pLevel)
      if (isNaN(numLevel) || numLevel < 1 || numLevel > 10){
        console.log('Level not valid... redirecting')
        gotoRoute('/quiz/leadermain')
      } else {
        this.level =  numLevel
        this.getusers()
        this.render() 
      }
    }else{
      this.getMyLevel()
    }
  }


  getMyLevel(){ 
    const highCheck = Auth.currentUser.score
    if(highCheck >= 109){
      this.level = 10
    } else {
      const dividedscore = Auth.currentUser.score / 10
      this.level = Math.trunc(dividedscore * 1) / 1 
    }
    this.getusers()
    if( document.title !== 'Leader Boards' ){return}
    this.render()
  }


  filterTierOne(){
    const thistier = this.allusers.filter( user => user.score == this.tiervalue )
    this.tierone = thistier
    this.render()
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

          <div class="content-panel"> 

            <nav class="home-btn primary" nav-link="/quiz/leadermain" @click="${navClick}">
              <div class="hbi-box">
                <img class="nav-img" alt="Leaderboards link" src="./../images/icons/sw08- lead-levels.svg">
              </div>
              <div class="hb-title">Leader Levels</div>
            </nav>

            ${this.allusers == null? html`
              <br>
              <br>
              <div class="spinner sp-sml"></div>
            `:html`

              <div id="lead-box" class="lead${this.level}">

                <section> 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Crown" src="./../images/quiz-badges/a-crown-a${this.level}.svg">
                      <h3>The Rulers</h3>
                    </div>
                  </div>

                  <div class="user-group">

                    ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`

                      ${this.tiervalue == 100? html`
                        ${this.allusers
                          .filter(user => user.score >= this.tiervalue + 9 )
                          .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>

                        `)}

                      `:html`
                        ${this.allusers
                          .filter(user => user.score == this.tiervalue + 9 )
                          .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>

                        `)}
                      `}


                    `}
                       
                  </div> 
                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Cup" src="./../images/quiz-badges/b-cup-a${this.level}.svg">
                      <h3>The Holders</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 8 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Medal" src="./../images/quiz-badges/c-medal-a${this.level}.svg">
                      <h3>The Owners</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 7 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Flag" src="./../images/quiz-badges/d-flag-a${this.level}.svg">
                      <h3>The Bearers</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 6 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Feather" src="./../images/quiz-badges/e-feather-a${this.level}.svg">
                      <h3>The Distinguished</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 5 )
                        .map(user => html `
                            <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Badge" src="./../images/quiz-badges/f-badge-a${this.level}.svg">
                      <h3>The Commanders</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 4 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Sheild" src="./../images/quiz-badges/g-sheild-a${this.level}.svg">
                      <h3>The Protectors</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 3 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier-banner" alt="Banner" src="./../images/quiz-badges/h-banner-a${this.level}.svg">
                      <h3>The Leaders</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 2 )
                        .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Card" src="./../images/quiz-badges/i-card-a${this.level}.svg">
                      <h3>The Passers</h3>
                    </div>
                  </div>

                  <div class="user-group">

                    ${this.allusers == null? html`
                      <div class="spinner sp-sml"></div>
                    `:html`
                      ${this.allusers
                        .filter(user => user.score == this.tiervalue + 1 )
                        .map(user => html `
                            <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                      `)}
                    `}
                  </div>

                  <div class="lead-break"></div>
                </section>
                <section > 
                  <div class="lead-head">
                    <img class="tier-l" alt="The Tier Holder" src="./../images/quiz-badges/badge-head-${this.level}.svg">
                    <div class="tier-holder">
                      <img class="tier" alt="Ribbon" src="./../images/quiz-badges/j-ribbon-a${this.level}.svg">
                      <h3>The Runners</h3>
                    </div>
                  </div>

                  <div class="user-group">
                  ${this.allusers == null? html`
                     <div class="spinner sp-sml"></div>
                  `:html`
                    ${this.allusers
                      .filter(user => user.score == this.tiervalue )
                      .map(user => html `
                           <board-users userName="${user.userName}" flagStatus="${user.flagStatus}" countryCode="${user.countryCode}" userId="${user._id}"> </board-users>
                    `)}
                  `}
                  </div>

                  <div class="lead-break"></div>
                </section>

              </div> 
            `}
            
          </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new LeaderboardsView()