import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Validate from '../../../Validate' 
import ThreadAPI from '../../../api/ThreadAPI' 
import PostAPI from '../../../api/PostAPI' 

//Version 2 - add the event listener to active events array via Events
class PostsView {
  init(){    
    document.title = 'Forum Posts'  
    this.thread = null
    Data.myreplypost = null //reset so it doesn't get called in a regular new post
    //this.threadTitle = null
    this.posts = null // Data.posts
    this.render() 
    this.initPage()
    Utils.initFont() 
    document.addEventListener('keydown', (e) => {
      this.tabBtn(e)
    })
  }

  tabBtn(e){
    if (e.keyCode === 9) { e.preventDefault() }
  }


  async initPage(){
    if(!Data.myThread._id){
      const urlParams = new URLSearchParams(location.search)
      const testidtext = urlParams.get('id')
      if(testidtext == null){ gotoRoute(Data.forumlink); return }
      const threadId = await Validate.sanitize(testidtext)
      //if(threadId )
      this.getThreadandPosts(threadId)
    } else {
      this.thread = Data.myThread
      this.getPosts(this.thread._id)
      this.render()
      this.threadUpdate()
      Utils.fontUpdate()  
    }
  }


  async getThreadandPosts(id){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      this.thread = await ThreadAPI.getThread(Auth.currentUser.usergroup, id)
      this.posts = await PostAPI.getPostbyThreadId(Auth.currentUser.usergroup, id)
      if(document.title !== 'Forum Posts' ){return}
      this.render()
      this.threadUpdate()
      Utils.fontUpdate()
      Data.myThread = this.thread
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="posts"></c-error>'
    }
  }

  threadUpdate(){
    const tTextDiv = document.getElementById('thread-text-formatted')
    const formattedText = Utils.formatParagraphText(this.thread.threadText)
    tTextDiv.innerHTML = formattedText
  }


  async getPosts(id){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      this.posts = await PostAPI.getPostbyThreadId(Auth.currentUser.usergroup, id)
      if(document.title !== 'Forum Posts' ){return}
      this.render()
      Utils.fontUpdate()  
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="posts"></c-error>'
    }
  }

  // <div class="forum-thread-title">${this.thread.threadTitle}</div>                    
  // <div class="thread-title-wrap">
  //   <div class="thread-title-name"> ${this.thread.user.userName} </div>
  //   <div class="thread-title-date">  ${new Date(this.thread.createdAt).toLocaleDateString()} </div>
  //   <p id="thread-text-formatted" class="thread-title-text para"></p>
  // </div >  

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">  
          <div id="main-tab">  
 
            ${this.thread == null ? html``:html`
              <div class="forum-thread-title">${this.thread.threadTitle}</div>                    
              <div class="thread-title-wrap">
                <div class="thread-title-name"> ${this.thread.user.userName} </div>
                <p id="thread-text-formatted" class="thread-title-text para"></p>
              </div >  
            `}                      

            ${this.posts == null? html`
              <br><div class="spinner sp-sml"></div> 
            `:html`
              ${this.posts.length == 0 ? html`
                <br><br>
                <p class="welcome-text"> <strong>Welcome ${Auth.currentUser.userName}! </strong> </p>
                <p class="welcome-text"> Be the first to join this discussion.  </p>
              `:html``}
              ${this.posts.map(post => html`
                ${post.approved == true ? html`
                  <div id="${post._id}">
                    <forum-post post="${JSON.stringify(post)}"></forum-post>
                  </div>
                `:html`
                  ${Auth.currentUser._id == post.user._id ? html`
                    <div id="${post._id}">
                      <forum-post 
                        post="${JSON.stringify(post)}"
                        typeInfo="this-user">
                      </forum-post>
                    </div>
                  `:html``} 
                `}
              `)}
            `}


            ${this.thread == null ? html``:html`
              <new-post typeInfo="thread-post"></new-post>
            `}

            <forum-btn btnType="main-menu"></forum-btn> 
          </div>
          <div id="status-tab"></div>
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new PostsView()