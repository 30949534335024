import App from './App'
import Router, { gotoRoute } from './Router'
import splash from './views/partials/splash'
import {html, render } from 'lit-html'
import Toast from './Toast'


// ---------------Functions ---------------
    // Sign in
    // userCheck
    // Sign out

// Sign up -- via "RegisterAPI" ------
// Reset Password -- via "ForgoAPI" ---------

class Auth {

  constructor(){
    this.forumAccess = false
    this.currentUser = {}
    this.myCases = null
    this.caseId = null
    // Compare forumSubExpiry with Auth.thisDay to confirm user forum access
    this.theDate = new Date();
    this.thisDay = this.theDate.getTime()
    //for resetting the password
    this.forgotUser = null
  }

  
  async signIn(userData, fail = false){
    try{
      const response = await fetch(`${App.apiBase}/auth/signin`, {
        method: 'POST',      
        headers: {"Content-Type" : "application/json", "Origin": App.origin},
        body: userData
      })
      if(!response.ok){// catches any status not between 200-299
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`Problem signing in: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      // sign in success
      const data = await response.json()
      Toast.show(`Welcome  ${data.user.userName}`)
      localStorage.setItem('accessToken', data.accessToken)
      this.currentUser = data.user      
      gotoRoute('/')
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }

  //This function is called when the app starts  
  // --automatic sign in if token is valid
  async userCheck(success){ 
    render(splash, App.rootEl)
    if(!localStorage.accessToken){ success(); return }
    try {
      // token must exist - validate token via the backend
      const response = await fetch(`${App.apiBase}/auth/validate`, {
        method: 'GET',
        headers: { "Authorization": `Bearer ${localStorage.accessToken}` }
      })
      if(!response.ok){             
        const err = await response.json()
        if(err) console.log(err)
        localStorage.removeItem('accessToken')
        Router.init()
        return false
      }
      // token is valid!
      const data = await response.json()
      this.currentUser = data.user
      Toast.show("Welcome "+ this.currentUser.userName)   
      success()
      return 
    }
    catch (err) {
      console.log(err)
      Toast.show("Not signed in", 'error') 
      localStorage.removeItem('accessToken')
      Router.init()
      return false
    }
  }

  signOut(){
    localStorage.removeItem('accessToken') 
    gotoRoute('/signin')
    Toast.show(`Signed out`)
    this.currentUser = {}
  }
}

export default new Auth()