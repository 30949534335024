import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class HarassView { 
  init(){    
    document.title = 'Sexual Harassment' 
    this.currentlesson = 4 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }


  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/4-harassment.svg"> 
          </div>
          <section class="lesson-section">

            <p class="app-info para">
              Sexual Harassment is inappropriate behaviour which is suggestively or explicitly sexual.  
              It is unwanted behaviour which makes you feel uncomfortable.  
              Sexual Harassment is often a pre-cursor to sexual assault and should not be tolerated.  
              It may come in the form of words, gestures, texts, touches or repetitive invites.
            </p>

            <p class="app-info para">
              Examples of sexual harassment:
              <ol class="para">
                <li>Commenting, teasing or joking about private parts or sex</li>
                <li>Unwanted flirting</li>
                <li>Relating sexual jokes</li>
                <li>Name calling which is sex or gender based </li>
                <li>Any messaging containing sexually related content</li>
                <li>Sexual movements or touching themselves in a sexual manner</li>
                <li>Exposing their private parts </li>
                <li>Following someone around </li>
                <li>Staring or looking their body up and down</li>
                <li>Repeatedly asking for dates or inappropriate pictures</li>
                <li>Catcalling or whistling</li>
                <li>Investigative questions about one’s sexual life</li>
              </ol> 
            </p>

            <p class="app-info para">
              If possible, sexual harassment should not be ignored. 
              The person harassing should be told to stop and the harassment should be reported to an adult, security or an authority so the relevant legal procedures can be addressed.  
              Sexual harassment is punishable by law and depending on the degree of severity it can earn someone a hefty fine or even a jail sentence.
            </p>

          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new HarassView() 