import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute} from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Validate from '../../../Validate' 
import ThreadAPI from '../../../api/ThreadAPI'


class ThreadsView {
  init(){    
    document.title = 'Forum Threads'   
    this.subtopicId = null
    this.subtopicTitle = null
    this.threads = null 
    this.render()  
    this.getThreads() 
  }


  async getThreads(){
    //check which version of the app
    if(Data.forumlink == "/forum/threads"){
      await this.getAllThreads()
    } else {
      const urlParams = new URLSearchParams(location.search)
      const testidtext = urlParams.get('id')
      if(testidtext == null){ gotoRoute('/forum/topics'); return }
      else {
        this.subtopicId = await Validate.sanitize(testidtext)
        const subtopic = Data.subTopics.find(subtopic => subtopic.subtopicId == this.subtopicId)
        this.subtopicTitle = subtopic.subtopicTitle
        if( document.title !== 'Forum Threads' ){return}
        this.render()
        this.getThreadsbySub(this.subtopicId)
      }
    }
  }


  async getAllThreads(){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      this.threads = await ThreadAPI.getAllThreads(Auth.currentUser.usergroup)
      if(document.title !== 'Forum Threads' ){return}
      this.render()
      Utils.initFont() 
      this.approvedOnlyCheck()
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="info-error"></c-error>'
    }
  }


  approvedOnlyCheck(){
    if(this.threads !== null && this.threads.length > 0){
      let onlyApprovals = true
      this.threads.forEach(thread => {
        if(thread.approved == true){ onlyApprovals = false }
      })
      if( onlyApprovals == true ){
        const mainTab = document.getElementById('main-tab')
        mainTab.innerHTML += '*Threads are waiting to be approved'
      }
    }
  }


  async getThreadsbySub(subId){
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      this.threads = await ThreadAPI.getThreadbySubtopic(Auth.currentUser.usergroup, subId)
      if(document.title !== 'Forum Threads' ){return}
      this.render()
      Utils.initFont() 
      this.approvedOnlyCheck()
    }catch(err){
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="threads"></c-error>'
    }
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>

        <div class="content-panel">      
          <div id="main-tab"> 
          
            ${this.subtopicId == null? html``: html` <div class="subTitle-box"> ${this.subtopicTitle} </div> `}
            <forum-btn btnType="new-thread"></forum-btn>

            ${Data.forumlink == '/forum/topics' ? html` 
              <forum-btn btnType="main-menu"></forum-btn> 
            `:html``}

            ${this.threads == null ? html`
              <br> <br> Getting threads ... <br> <br> 
              <div class="spinner sp-sml"></div> 
            `:html`

              ${this.threads.length == 0 ? html`
                <br><br>
                <p class="welcome-text"> <strong>Welcome ${Auth.currentUser.userName}! </strong> </p>
                <p class="welcome-text"> Be the first to start a conversation here.  </p>
              `:html``}
              
                ${this.threads
                  .map(thread => html ` 

                    ${thread.approved == true ? html`
                      <forum-thread 
                        thread="${JSON.stringify(thread)}">
                      </forum-thread>
                    `:html`

                      ${Auth.currentUser._id == thread.user._id ? html`
                        <forum-thread 
                          thread="${JSON.stringify(thread)}"
                          typeInfo="this-user">
                        </forum-thread>
                      `:html``}  

                    `} 
                  `)} 
            `} 
          </div> 
          <div id="status-tab"></div>

        </div>
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ThreadsView()