import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import Data from '../../Data' // for app variables


customElements.define('forum-subtopics', class ForumComponents extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      subtopicId: { type: String },
      subtopicTitle: { type: String }
    }
  }
  
  firstUpdated(){
    super.firstUpdated()
  }

  openThread(){
    Data.mysubtopic = this.subtopicId
    Data.mysubtitle = this.subtopicTitle
		gotoRoute('/forum/threads?id=' + Data.mysubtopic)
  }

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      .nav-btn {
        background-color: var(--brand-color);
        color: white;
        transition: background-color 0.4s ease-out;
        font-size: 1.2em; 
        margin: 10px;
        padding: 12px 20px;
        cursor: pointer;
        border-radius: 5px;
      }

      .nav-btn:hover { background-color: var(--two-color); }

      /* ------------------- RESPONSIVE ------------------- */

      </style>

      <nav class="nav-btn" @click="${this.openThread}"> 
        ${this.subtopicTitle}
      </nav>
    `
  }
  
})
