import {LitElement, html} from 'lit'
import {navClick} from '../Router'
import Auth from '../Auth' // for user auth
import Utils from '../Utils'

customElements.define('lesson-nav', class LessonNav extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      lessonnumber: { type: Number },
      nextlesson: { type: Number }
    }
  }
  
  firstUpdated(){
    super.firstUpdated()
  }

  quizleadModal(){ Utils.modalPquizlead() }
  unlockQuizModal(){ Utils.modalPrevQuiz() }

  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .lesson-btn {
          display: block;
          margin: 20px auto;
          width: 300px;
          padding: 12px;
          border-radius: 15px;
          color: white;
          font-size: 1.5em;
          position: relative;
          cursor: pointer;
          transition:all .5s;
          display: flex; align-items: center; justify-content: center;
        }
        .lesson-btn:hover { box-shadow: 0px 0px 6px rgba(0,0,0,0.7); }
        .lb-img { width: 30px; margin-right: 3vw; }
        .primary { background-color: var(--brand-color); }
        .action { background-color: var(--action-color) ; }
        .disabled { background-color: var(--disabled-color); }

        @media all and (max-width: 350px){ 
        
          .lesson-btn {
            width: 230px;
            padding: 10px;
            font-size: 1.3em;
          }
          .lb-img { width: 25px; }
        }

      </style>

      ${Auth.currentUser.quizLevel ? html` <!-- A) Has Quiz access -->
          ${this.lessonnumber == Auth.currentUser.quizLevel? html` <!-- B) active quiz  -->
            <nav class="lesson-btn action" nav-link="/quiz/lesson?level=${this.lessonnumber}" @click="${navClick}">
              <img class="lb-img" alt="Quiz Yourself" src="./../images/icons/quiz-qu.svg">
              Quiz Yourself</nav>
          `:html` <!-- B) not current quiz  -->
            ${this.lessonnumber  >= Auth.currentUser.quizLevel? html` <!-- C) level locked  -->
              <nav class="lesson-btn disabled" @click="${this.unlockQuizModal}">
                <img class="lb-img" alt="Quiz Yourself" src="./../images/icons/quiz-qu.svg">
                Quiz Yourself</nav>
            `:html` <!-- C) repeat quiz  -->
              <nav class="lesson-btn action" nav-link="/quiz/lesson?level=${this.lessonnumber}" @click="${navClick}">
                <img class="lb-img" alt="Repeat Quiz" src="./../images/icons/quiz-qu.svg">
                Repeat Quiz</nav>
            `} <!-- C) close  -->
          `} <!-- B) close  -->
        `:html` <!-- A) no quiz Access -->
          <nav class="lesson-btn disabled" @click="${this.quizleadModal}">
            <img class="lb-img" alt="Quiz Yourself" src="./../images/icons/quiz-qu.svg">
            Quiz Yourself</nav>
        `} <!-- A) close -->


      ${this.lessonnumber == 8? html `
        <nav class="lesson-btn primary" nav-link="/lesson/bibliography" @click="${navClick}">
          Bibliography</nav>
      ` : html`
        <nav class="lesson-btn primary" nav-link="/lesson/${this.nextlesson}" @click="${navClick}">
          <img class="lb-img" alt="nextlesson" src="./../images/icons/sw10-next.svg">
          Next Lesson</nav>
      `}

    `
  }
  
})
