import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Validate from '../../../Validate'
import RegisterApi from '../../../auth/RegisterApi'

//Version 2 - add the event listener to active events array via Events
class SignupView {
  init(){    
    document.title = 'Signup'    
    this.render() 
    Utils.initFont()  
    document.addEventListener('keyup', (e) => {
      if(document.title == 'Signup'){
        this.enterBtn(e)
      }
    })
  }

  enterBtn(e){
    const mainTab = document.getElementById('main-tab')
    if (e.key === 'Enter' && !mainTab.classList.contains('hidden')) {
      this.signUpSubmitHandler()
    }
  }

  async signUpSubmitHandler(){
    const emailEntry = document.getElementById('user-email')
    const validStatus = Validate.validEmail(emailEntry.value)
    const formData = new FormData(form)
    const formObject = {};
    formData.forEach((value, key) => formObject[key] = value);
    const formJson = JSON.stringify(formObject);
    
    if(validStatus == false){
      Validate.inputWarn('user-email', 'Enter a valid email')
      return
    } 

    //check agree button
    const agreeButton = document.getElementById('select-item')
    if(!agreeButton.classList.contains('select-active')){
      Validate.inputWarn('select-item', 'Must agree to continue')
      return
    }     

    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.classList.remove('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="signup"></c-waiting>'
    try {
      const emailMessage = await RegisterApi.registerLink(formJson)      
      if(emailMessage == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else {
        statusTab.innerHTML = '<c-success successSource="signup"></c-success>'
      }
    }catch(err){      
      console.log('Error sending email')
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }

  
  agreeHandler(e){
    const selectnew = e.target.closest('.select-item')
    const regButton = document.getElementById('reg-btn')
    selectnew.classList.add('select-active')
    regButton.classList.remove('submit-disabled')
    regButton.classList.add('submit-btn')
    Validate.removeWarn('select-item')
  }

  privacyModal(){ Utils.modalPrivacy() }

    
  render(){
    const template = html`
      
      <div class="sign-content v-center">

          <div class="form-box"> 
            <img class="signinup-logo-mobile" alt="${Data.apptitle}" class="logo-home" src="/images/icons/1-logo-${Data.appcode}.svg" >
            <img class="signinup-logo-mobile" alt="${Data.apptitle}" src="/images/icons/2-apptext-${Data.appcode}.svg" ><br>
            <div id="main-tab" > 
            <p class="form-name">Sign Up</p>      
            <form name="form" class="form-signup" method="post">   
              <p id="user-email-wrn" class="input-require">* Valid email required</p>
              <div class="input-group">
                <input id="user-email" class="para" name="email" type="email" @keyup=${Validate.clearWarning.bind(Validate)} placeholder="Email"/>
              </div>

              <p id="select-item-wrn" class="input-require">* Valid email required</p>
              <div class="agree-wrap">
                <div id="select-item" class="select-item" @click="${this.agreeHandler}">
                  I agree to the <br> <a @click="${this.privacyModal}">privacy policy</a>
                <div class="s-point"></div></div>
              </div>

              <div id="reg-btn" class="submit-disabled" @click=${this.signUpSubmitHandler}>Send Registration Link</div>

            </form>
            <p>Already have an Account? <a href="/signin" class="sign-a-link" @click=${anchorRoute}>Sign In</a></p>
            <a href="/" class="home-button primary" @click=${anchorRoute}>Home</a>
            </div>
            <div id="status-tab"></div>
          </div>
 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new SignupView()