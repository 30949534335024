import {LitElement, html} from 'lit'
import {navClick} from '../Router'


customElements.define('feedback-widget', class SideWidget extends LitElement {
  constructor(){
    super()    
  }


  firstUpdated(){
    super.firstUpdated()
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .widget-box {
          position: fixed;
          bottom: 10vh;
          right: -10px;
          width: 50px;
        }

        .widget-link {
          height: 130px;
          width: 100%;
          border-radius: 10px 0 0 10px;
          position: relative;
          transition: .4s;
          cursor: pointer;
          background-color: #1F62FF;
        }

        .widget-link:hover { background-color: #0847db; }

        .widget-text {
          font-size: 1.3em;
          color: white;
          transform: rotate(-90deg);
          position: absolute;
          right: -15px;
          top: 50px;
        }


        @media all and (max-width: 768px){     
          .widget-box {
            position: absolute;
            right: 0px;
            width: 45px;
            z-index: 110;
          }
          .widget-text {
            right: -22px;
            top: 52px;
          }
        }


        @media all and (min-width: 769px){  
          .widget-link { box-shadow: -4px 4px 5px rgba(0,0,0,0.2); }
        }



      </style>
      <div class="widget-box">
        ${localStorage.accessToken == null ? html` 
        `:html`
          <nav class="widget-link" nav-link="/user/feedback" @click="${navClick}">
            <p class="widget-text">Feedback</p>
          </nav>
        `}
      </div>

    `
  }
  
})
