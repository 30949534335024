import App from '../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../Router'
import Data from '../../Data'
import Utils from '../../Utils'


class InfoView {
   init(){    
    document.title = 'Information'    
    Data.initCountry()
    this.render()  
    Utils.initFont()  
  }

  openSelectHandler(){
    const activeCountry = document.querySelectorAll('.'+ Data.myCountry.countryCode)
    activeCountry.forEach(acountry => {
      acountry.classList.add('select-active')
    })
    const selectBox = document.getElementById('select-position')
    selectBox.classList.remove('hidden')
  }

  closeSelectHandler(){
    const selectBox = document.getElementById('select-position')
    selectBox.classList.add('hidden')
  }

  updateSelectedHandler(e){
    const newCode = e.target.closest('.select-item').getAttribute("data-id")
    // update the country via Data
    Data.updateCountry(newCode)
    const removeAtts = document.querySelectorAll('.select-item')
    //remove active from all elements
    removeAtts.forEach(acountry => {
      acountry.classList.remove('select-active')
    })
    // add "active" class the new selection
    const activeCountry = document.querySelectorAll('.'+ Data.myCountry.countryCode)
    activeCountry.forEach(acountry => {
      acountry.classList.add('select-active')
    })
    this.render()
    // if the user has updated thier country - edit in the leaderboards data
    // this.leaderUsers
  }

  forumRulesModal(){ Utils.modalRules() }
  privacyModal(){ Utils.modalPrivacy() }

  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <!-- Widget won't show unless signed in -->
        <feedback-widget class="feedback-widget"></feedback-widget>

        <div class="content-panel">      
          <p class="app-info para">
          <strong>Police contact: </strong>If you are being attacked or your safety is being threatened, call the emergency number in your country.
          </p>
          <div class="e-box">
            ${Data.myCountry == null ? html` 
              <div class="e-country">
                <div class="spinner sp-sml"></div>

              </div>
            ` : html` 
              <div class="e-country">
                <div class="e-logo">
                ${Data.myCountry.flag == true ? html` 
                  <img class="nav-img" alt="flag" src="./../images/flags/flag-${Data.myCountry.countryCode}.svg">
                ` : html` 
                  <img class="nav-img" alt="flag" src="./../images/flags/flag-aaa.svg">
                `}
                </div>
                <div class="e-name">${Data.myCountry.countryName}</div>
              </div>
              ${Data.myCountry.emergNumber ? html`
                <div class="e-number">${Data.myCountry.emergNumber}</div>
              ` : html` 
                Please contact the local authorities
              `}
            `}
            <div class="e-title">EMERGENCY</div>
          </div>


          <div id="select-position" class="hidden">

              
            <div id="select-panel " class="select-box s-below ">
              <div class="select-close" @click="${this.closeSelectHandler}">
                <img id="close-icon" alt="Close Icon" src="./../images/icons/${Data.appcode}07-close.svg" height="15px">
              </div>
              
                ${Data.priority.map(priority => html `
                <div data-id="${priority.countryCode}" class="select-item ${priority.countryCode}" @click="${this.updateSelectedHandler.bind(this)}">${priority.countryName}<div class="s-point"></div></div>
                `)}
                <div class="priority-wrap"><div class="priority"></div></div>
                

                ${Data.allCountries.map(country => html `
                <div data-id="${country.countryCode}" class="select-item ${country.countryCode}" @click="${this.updateSelectedHandler.bind(this)}">${country.countryName}<div class="s-point"></div></div>
                `)}
              
            </div>
          </div>
          
          <div class="redirect-button action" @click="${this.openSelectHandler}">Change Country</div>

          <p class="app-info para">
          <strong>Human rights: </strong>Everyone has the right to be respected and treated honourably at all times.  No one deserves to be insulted, let alone abused or harmed in any manner.
          </p>

          <p class="app-info para">
            This app provides a safe space for victims to share their experiences and heal together.  
            However, it does NOT provide counselling services.  
            If you need to speak to the police department to report an abuse case, please contact the relevant department in your location.
            And if you need professional medical or psychological help, please search for the relevant services.
          </p>


          <p class="app-info para">
          <strong>Site features: </strong>For information on “Sexual Abuse” read through our free <a href="/lessons" @click="${anchorRoute}">lessons</a>.  
            Support this app by purchasing “Access” to the quizzes and leader-boards ($20).  
            Or subscribe to the forums ($15 / month*) to join in the discussions.  
          </p>

          <p class="app-info para notes">
            *A small fee to access the forums allows us to provide moderators to keep our users safe and make sure everyone is respected. 
            (See <a href="#" @click="${this.forumRulesModal}">Forum Rules</a> for details of what is and isn't allowed in the posts)
          </p>

          

          <p class="app-info para notes">
            **Statistics are available to users who have made an in-app purchase (1 month forum access or quiz access) to ensure unwanted intruders don't “play” with the data.
          </p>

          <p class="app-info para notes">
            Read our <a href="#" @click="${this.privacyModal}">Privacy Policy</a> for information on how we protect your personal information.
          </p>

          <br>
          <p class="para copyright">
            Sponsored by <a href="https://hashtag-webapp.com" target="_blank"> #WEBAPP </a> 
          </p>

        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new InfoView()