import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import Auth from '../../../Auth'
import Utils from '../../../Utils'
import CaseAPI from '../../../api/CaseAPI'
import UserApi from '../../../auth/UserApi'


class MyCasesView {
  init(){    
    document.title = 'My Incidents'   
    this.render()  
    this.getMyCases()
    Utils.initFont()
  }

  async getMyCases(){
    if(!Auth.currentUser.score && !Auth.currentUser.forumSubExpiry){
      gotoRoute('user/profile')
    }
    if(!Auth.myCases == null){
      this.render() 
      return
    }
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    try{
      Auth.myCases = await CaseAPI.getCasesbyUserId(Auth.currentUser._id)
      this.render()  
    } catch(err) {
      console.log(err)
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-error errorSource="tryagain"></c-error>'
    }
  }

  goback(){
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    mainTab.classList.remove('hidden')
    alertTab.classList.add('hidden')
  }

  editHandler(e){
    e.preventDefault()
    const caseId = e.target.closest('nav').getAttribute("id")
    Auth.caseId = caseId
    gotoRoute('/stats/editcase')
  }

  deleteAlertHandler(e){
    e.preventDefault()
    const caseId = e.target.closest('nav').getAttribute("id")
    const mainTab = document.getElementById('main-tab')
    const alertTab = document.getElementById('alert-tab')
    Auth.caseId = caseId
    mainTab.classList.add('hidden')
    alertTab.classList.remove('hidden')
  }

  async deleteCaseHandler(){
    const alertTab = document.getElementById('alert-tab')
    const statusTab = document.getElementById('status-tab')
    alertTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="delete"></c-waiting>' 
    try {
      let addUserCase = {}
      addUserCase.cases = Auth.currentUser.cases - 1
      Auth.currentUser.cases = addUserCase.cases
      const userObject = JSON.stringify(addUserCase);
      await UserApi.updateUser(Auth.currentUser._id, userObject)
      const status = await CaseAPI.deleteCase(Auth.caseId)
      if(status == false){
        statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      } else { 
        statusTab.innerHTML = '<c-success successSource="deleted"></c-success>'
        //Update cases on page: no need to reset:
        for (var i = 0; i < Auth.myCases.length; i++) {
            if (Auth.myCases[i]._id == Auth.caseId) {
              Auth.myCases.splice(i, 1);
            }
        }
        if( document.title !== 'My Incidents' ){return}
        this.closeAccordians()
        this.render() 
      }
    }catch(err){
      statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
    }
  }

  closeAccordians(){
    const allTabs = document.querySelectorAll('.ac-title')
    const panel = document.querySelectorAll('.ac-body')
    allTabs.forEach(tab => {
      const panel = tab.nextElementSibling
      if(panel.style.maxHeight){
        panel.style.maxHeight = null;
        tab.classList.toggle("ac-active") 
      }
    })
  }

  accordianClick(e){
    e.preventDefault()
    const accordionBtn =  e.target
    const panel = e.target.nextElementSibling;
    if (panel.style.maxHeight) {
       panel.style.maxHeight = null;
       accordionBtn.classList.toggle("ac-active")     
    } else {
      panel.style.maxHeight = Math.max(panel.scrollHeight) + "px";
      accordionBtn.classList.toggle("ac-active")
    } 
  }

  disabled(e){ e.preventDefault() }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel"> 
          <div id="main-tab">

            ${Auth.myCases == null ? html`
              <br><br>
              Getting incidents ...
              <br><br>
              <c-spinner></c-spinner>
            `:html`

              ${Auth.myCases.map(thiscase => html `

                <button class="ac-title" @click="${this.accordianClick}">
                    ${thiscase.caseTitle}
                </button>

                <div class="ac-body">

                  <p class="app-info para">
                    <span class="subt">USER:</span>
                    <br><strong>Age: </strong>${thiscase.survivor.survivorAge}
                    <br><strong>Disability: </strong>${thiscase.survivor.survivorDisability}
                    <br><strong>Impacted: </strong>${thiscase.survivor.survivorImpact}
                    
                    ${thiscase.survivor.impactType.length >= 1 ? html`
                      <br><strong>Impacted by: </strong>
                      ${thiscase.survivor.impactType.map(impact => html`
                        <br> - ${impact} 
                      `)}
                    `:html``}

                  </p>

                  <p class="app-info para">
                    <span class="subt">INCIDENT:</span>
                    <br><strong>Incident Year: </strong>${thiscase.incident.caseYear}
                    <br><strong>Reported status: </strong>${thiscase.incident.caseReported}
                    <br><strong>Frequency: </strong>${thiscase.incident.caseFrequency}
                    ${thiscase.incident.ongoingDuration ? html`
                      <br><strong>Duration: </strong> ${thiscase.incident.ongoingDuration}
                    `:``}
                    <br><strong>Incident type: </strong>${thiscase.incident.caseType}
                    <br><strong>Main response: </strong>${thiscase.incident.caseReaction}
                  </p>

                  <p class="app-info para">
                    <span class="subt">PLACE:</span>
                    <br><strong>Country: </strong>${thiscase.place.caseCountry}
                    ${thiscase.place.caseState ? html`
                      <br><strong>State: </strong> ${thiscase.place.caseState}
                    `:``}
                    <br><strong>Location: </strong>${thiscase.place.caseLocation}
                    ${thiscase.place.subdivision ? html`
                      <br><strong>Subdivision: </strong> ${thiscase.place.subdivision}
                    `:``}
                    ${thiscase.place.locationSector ? html`
                      <br><strong>Sector: </strong> ${thiscase.place.locationSector}
                    `:``}
                  </p>

                  <p class="app-info para">
                    <span class="subt">PERPETRATOR:</span>
                    <br><strong>Age: </strong>${thiscase.perpetrator.perpAge}
                    <br><strong>Gender: </strong>${thiscase.perpetrator.perpGender}
                    <br><strong>Position: </strong>${thiscase.perpetrator.perpPosition}
                    <br><strong>Were they known: </strong>${thiscase.perpetrator.perpKnown}
                  </p>

                  <nav id="${thiscase._id}" class="two-buttons">
                    <!-- pass through the case id via closest nav element  -->
                    <a class="mod-button action" @click=${this.editHandler}>Edit</a>
                    <a class="mod-button warning" @click=${this.deleteAlertHandler}>Delete</a>
                  </nav>
                  <br><br>
                </div>
              `)}       
            
            `}

            <br><br>
            ${Auth.currentUser.cases >= 10 ? html`
              *Limit of 10 incidents per user.
              <a href="#" class="forum-button disabled" @click=${this.disabled}>
                Add Incident
              </a>
            `:html`
              <a href="/stats/newcase" class="forum-button forum-btn primary" @click=${anchorRoute}>
                Add Incident
              </a>
            `}

          </div>
          <div id="status-tab"></div>

          <div id="alert-tab" class="hidden">
            <div class="confirm-wrap h-center">
              <p class="para h-center confirm-space">Are you sure you want to remove this incident?</p>
              <div id="delete-click" class="redirect-button warning confirm-space" @click=${this.deleteCaseHandler.bind(this)}>Delete</div>
              <div id="back-click" class="redirect-button primary confirm-space" @click=${this.goback}>Cancel</div>
            </div>
          </div>

        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new MyCasesView()