import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute, anchorRoute } from '../../../Router'
import Validate from '../../../Validate'
import RegisterApi from '../../../auth/RegisterApi'
import ForgotApi from '../../../auth/ForgotApi'
import UserApi from '../../../auth/UserApi'
import SubscribeAPI from '../../../api/SubscribeAPI'


class TokenValidationView {
  init(){    
    document.title = 'Validating Email'    
    this.validatingType = null
    this.render()  
    this.initValidateToken()
  }

  initValidateToken() {
    console.log('validating token')
    const statusTab = document.getElementById('status-tab')
    const urlParams = new URLSearchParams(window.location.search)
    const token =  urlParams.get('token')
    const type =  urlParams.get('type')
    //security check
    const safeToken = Validate.urlCheck(token)
    const safeType = Validate.urlCheck(type)
    if(safeType == false || safeToken == false){
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="urlcorrupt"></c-error>'
      return
    }
    if(type == "register"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateRegTkn(token)
    } else if(type == "forgot"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateForgotTkn(token)
    } else if(type == "update"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateUpdateTkn(token)
    } else if(type == "unsubscribe"){ 
      statusTab.innerHTML = '<c-waiting waitSource="validating"></c-waiting>'
      this.validateUnsubTkn(token)
    } else {
      console.log('Error because no "type" in url')
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="urlcorrupt"></c-error>'
    }
  }

  async validateUnsubTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await SubscribeAPI.unsubscribe(token)    
      if(verifyStatus == true){
        statusTab.innerHTML = '<c-success successSource="unsubscribe"></c-success>'
      } else {
        statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="unsubscribe"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="backhome" errorInfo="unsubscribe"></c-error>'
    }
  }

  async validateRegTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await RegisterApi.verifyRegisterToken(token)    
      if(verifyStatus == true){
        gotoRoute('/register')
      } else {
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="register"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="register"></c-error>'
    }
  }

  async validateForgotTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await ForgotApi.verifyForgotToken(token)    
      if(verifyStatus == true){
        gotoRoute('/reset')
      } else {
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="forgot"></c-error>'
    }
  }
  
  async validateUpdateTkn(token){
    const statusTab = document.getElementById('status-tab')
    try {
      const verifyStatus = await UserApi.validateUpdateEmail(token)    
      if(verifyStatus == true){
        statusTab.innerHTML = '<c-success successSource="update" successInfo="email"></c-success>'
      } else {
        statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="update"></c-error>'
      }
    }catch(err){      
      statusTab.innerHTML = '<c-error errorSource="validating" errorInfo="update"></c-error>'
    }
  }

  
  render(){
    const template = html`
      <div class="sign-content v-center">
        <div class="form-box">           
          <br>
          <div id="status-tab">
            <div class="spinner sp-sml"></div>
          </div>
        </div>
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new TokenValidationView()