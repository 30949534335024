import {LitElement, html} from 'lit'
import Auth from '../Auth' // for user auth


customElements.define('board-users', class BoardUsers extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      userName: { type: String },
      countryCode: { type: String },
      userId: { type: String },
      flagStatus: { type: Boolean }
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .score-card { 
          display: flex; align-items: center; justify-content: left;
          font-size: 1.2em; position: relative;
        }
        .user-img { height: 60px; padding: 5px 3vw; }
        .me-img { position: absolute; right: 0; height: 60px; padding: 5px 15px; }
        p { margin-right: 105px; }

        @media all and (max-width: 450px){ 
          .score-card {  font-size: 1em;  }
          .user-img { height: 50px; padding: 3px 10px; }
          .me-img { position: absolute; right: 0; height: 40px; padding: 5px 5px; }
          p { margin-right: 90px; }
        }

      </style>


      <div class="score-card"> 

        ${this.flagStatus == true? html`
          <img class="user-img" src="./../images/flags/flag-${this.countryCode}.svg">
        `: html`
          <img class="user-img" src="./../images/flags/flag-aaa.svg">
        `}

        ${this.userId == Auth.currentUser._id? html`
          <p><strong>${this.userName}</strong></p>
          <img class="me-img" src="./../images/quiz-badges/me-hold-second.svg">
        `:html`
          <p>${this.userName}</p>
        `}

      </div>

    `
  }
  
})
