import {LitElement, html} from 'lit'
import {render} from 'lit/html.js'
import Data from '../../Data' // for app variables
import Utils from '../../Utils'

//use classes to and info to add conditions for modal content to avoid repetition

customElements.define('main-modal', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalClass: { type: String },
      modalInfo: { type: String }
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
    this.insertmyHTML()
  }


  closeDialogHandler(e){
    const modal = this.shadowRoot.getElementById('ta-modal')
    const modalDiv = document.getElementById('modal-div')
    if (e.target == modal) {
      modalDiv.innerHTML = ``
    } else {return}
  }


  insertmyHTML(){
    const modalInsert = this.shadowRoot.getElementById('modal-content')
    modalInsert.innerHTML = `<mi-${this.modalClass} modalType="${this.modalInfo}"></mi-${this.modalClass}>` 
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

          .modal-overlay {
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.4);  
            position: absolute;
            left: 0;
            top: 0;
            z-index: 9999;
            display: flex; align-items: center; justify-content: center;
          }
          
          .modal-box {
            position: relative;
            background-color: white;
            padding-top:  20px;
            border-radius: 10px;
            min-width: 350px;
            max-width: 95%;
            min-height: 300px;
            max-height: 90vh; 
            display: flex; align-items: center; justify-content: center;
          }

          #modal-content { text-align: center; padding: 20px 10px; }
          .hidden { display: none; }

          .modal-close {
            position: absolute;
            right: 0;
            cursor: pointer;
            padding: 5%;
            top: 0;
          }

          
          /* Mobile styles ---------------------------------------------------*/
          @media all and (max-width: 768px){
            .modal-box { min-width: 300px; } 
          } 

          @media all and (max-width: 350px){
            .modal-box { min-width: 200px; } 
          }

      </style>
      <div id="ta-modal" class="modal-overlay" @click="${this.closeDialogHandler}">
        <div id="ta-box" class="modal-box h-center">
          <div class="modal-close" @click="${Utils.closeModal.bind(Utils)}">
            <img class="close-icon" alt="Close Icon" src="/images/icons/${Data.appcode}07-close.svg" height="15px">
          </div>
          <div id="modal-content"></div>
        </div> 
      </div>  

    

    `
  }
  
})
