import App from '../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute } from '../../Router'
import Data from '../../Data'
import CheckoutAPI from '../../api/CheckoutAPI'


class CheckoutView {
  init(){    
    document.title = 'Checkout'   
    this.render()  
    document
      .querySelector("#payment-form")
      .addEventListener("submit", (e) => {
        e.preventDefault()
        //console.log('call submit')
        CheckoutAPI.handleSubmit()
      });
    this.initCheckOut()
  }

  async initCheckOut(){   
    const statusTab = document.getElementById('stripe')
    if(Data.coCode == null){
      gotoRoute('/purchases')
      return
    }
    try {
      const initData = await CheckoutAPI.initPayment()
      if(initData == false){
        console.log('An error occured setting up the payment')
        statusTab.innerHTML = '<c-error errorSource="purchase"></c-error>'
      } else { 
        CheckoutAPI.checkStatus()
      }
    } catch(err){
      //console.log('A catch error occured setting up the payment')
      console.log(err)
      statusTab.innerHTML = '<c-error errorSource="purchase"></c-error>'
    }
  }


  //See CheckoutAPI for STRIPE checkout codes 


  render(){
    const template = html`
      <style>
        * { box-sizing: border-box; }

        #stripe {
          font-family: -apple-system, BlinkMacSystemFont, sans-serif;
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          display: flex;
          justify-content: center;
          align-content: center;
          height: 100vh;
          width: 100vw;
        }

        form {
          width: 30vw;
          min-width: 500px;
          align-self: center;
          box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
            0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
          border-radius: 7px;
          padding: 40px;
        }

        .hidden { display: none; }

        #payment-message {
          color: rgb(105, 115, 134);
          font-size: 16px;
          line-height: 20px;
          padding-top: 12px;
          text-align: center;
        }

        #payment-element { margin-bottom: 24px; }

        /* Buttons and links */
        button {
          background: #5469d4;
          font-family: Arial, sans-serif;
          color: #ffffff;
          border-radius: 4px;
          border: 0;
          padding: 12px 16px;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;
          display: block;
          transition: all 0.2s ease;
          box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
          width: 100%;
        }
        button:hover { filter: contrast(115%); }
        button:disabled { opacity: 0.5; cursor: default; }

        /* spinner/processing state, errors */
        .spinner, .spinner:before, .spinner:after { border-radius: 50%; }
        .spinner {
          color: #ffffff;
          font-size: 22px;
          text-indent: -99999px;
          margin: 0px auto;
          position: relative;
          width: 20px;
          height: 20px;
          box-shadow: inset 0 0 0 2px;
          -webkit-transform: translateZ(0);
          -ms-transform: translateZ(0);
          transform: translateZ(0);
        }
        .spinner:before, .spinner:after { position: absolute; content: ""; }
        .spinner:before {
          width: 10.4px;
          height: 20.4px;
          background: #5469d4;
          border-radius: 20.4px 0 0 20.4px;
          top: -0.2px;
          left: -0.2px;
          -webkit-transform-origin: 10.4px 10.2px;
          transform-origin: 10.4px 10.2px;
          -webkit-animation: loading 2s infinite ease 1.5s;
          animation: loading 2s infinite ease 1.5s;
        }
        .spinner:after {
          width: 10.4px;
          height: 10.2px;
          background: #5469d4;
          border-radius: 0 10.2px 10.2px 0;
          top: -0.1px;
          left: 10.2px;
          -webkit-transform-origin: 0px 10.2px;
          transform-origin: 0px 10.2px;
          -webkit-animation: loading 2s infinite ease;
          animation: loading 2s infinite ease;
        }

        @-webkit-keyframes loading {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }
        @keyframes loading {
          0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
          }
          100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
          }
        }

        @media only screen and (max-width: 600px) {
          form {
            width: 80vw;
            min-width: initial;
          }
        }
      </style>

      <div id="stripe">
      
        <!-- <script src="https://js.stripe.com/v3/"></script> -->
        <form id="payment-form">
          <div id="payment-element"> <!-- Payment Element--> </div>
          
          <button id="submit">
            <div class="spinner hidden" id="spinner"></div>
            <span id="button-text">Pay now</span>
          </button>
          <div id="payment-message" class="hidden"></div>
        </form>
        
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new CheckoutView()