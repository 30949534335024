import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import moment from 'moment'

// Buttons for /payments /posts /progress (version 2)

class ProfileView {
  init(){    
    document.title = 'My Profile'  
    this.myCountry = null  
    this.render()  
    Utils.initFont()
    Data.initCountry()
    this.myCountry = Data.myCountry
    this.render()  
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">      

          ${this.myCountry == null ? html` 
              <div class="e-country">
                <div class="spinner sp-sml"></div>
              </div>
            ` : html` 
              <div class="e-country">
                <div class="e-logo">
                  ${this.myCountry.flag == true ? html` 
                    <img class="nav-img" alt="flag" src="./../images/flags/flag-${Auth.currentUser.countryCode}.svg">
                  ` : html` 
                    <img class="nav-img" alt="flag" src="./../images/flags/flag-aaa.svg">
                  `}
                </div>
              </div>
            `}


            <div class="h-center">
              <p class="profile-text para">
                <strong>${Auth.currentUser.userName}</strong>
              </p>
              <p class="profile-text para">
                Member since: ${moment(Auth.currentUser.createdAt).format('MMMM Do YYYY')}
              </p>
            </div>

            <a href="/user/editprofile" class="forum-button forum-action-btn action" @click=${anchorRoute}>
              Edit Profile
              <img class="back-img img-padding" alt="back" src="./../images/icons/sw04-pencil.svg">
            </a>

            <div class="divide-line"></div>

<!-- version 2 ------------------------conditions----------------------------
            <a class="forum-button forum-btn disabled">
              Quiz Progress
            </a>

            <div class="two-buttons">
              <a href="/user/progress" class="forum-button forum-btn primary" @click=${anchorRoute}>
                Quiz Progress
              </a>
              <a href="/user/posts" class="forum-button forum-btn primary" @click=${anchorRoute}>
                My Posts
              </a>
            </div>
-->

      
              ${!Auth.currentUser.score && !Auth.currentUser.forumSubExpiry ? html`
                *Make any purchase to access statistics features.
                <a class="forum-button disabled" > My Incidents </a>
              `:html`
                <a href="/user/cases" class="forum-button forum-btn primary" @click=${anchorRoute}>
                  My Incidents
                </a>
              `}
        
<!-- Button for my payments (version 2)
            <br> payment conditions: if no score && no subscription = no payments

            <a href="/user/payments" class="forum-button forum-btn primary" @click=${anchorRoute}>
              Payments
            </a>
-->

        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new ProfileView()