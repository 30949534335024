import App from '../../../App'
import {render, html} from 'lit/html.js'
import { anchorRoute } from '../../../Router'
import Data from '../../../Data'


class StatsCaseMenuView {
  init(){    
    document.title = 'Incident Statistics'    
    this.render()  
  }

  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">  
           
          <br> <br>
          ${Data.statisticsFields == null ? html`
            <div class="spinner sp-sml"></div>
          `:html`
            ${Data.statisticsFields.map(statField => html `
              <my-accordian accordianType="stats" accordianName="${statField.category}" dataId="${statField.category}"></my-accordian>
            `)}
          `}

          <br>
          <a href="/stats/main" class="forum-button forum-btn primary" @click=${anchorRoute}>
            Back to Main
            <img class="back-img" alt="back" src="./../images/icons/sw00-back.svg">
          </a>

        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new StatsCaseMenuView()