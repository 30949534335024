import App from '../../../App'
import {render, html} from 'lit/html.js'
import Auth from '../../../Auth'
import Data from '../../../Data'
import UserApi from '../../../auth/UserApi'


class LeaderboardLevelView {
  init(){    
    document.title = 'Leader Levels'   
    this.myLevel = null 
    this.openLevels = null
    this.moreLevels = false
    this.levelStatus = 0
    this.render()  
    this.getLevels()
  }


  getMyLevel(){
    const dividedscore = Auth.currentUser.score / 10
    this.myLevel = Math.trunc(dividedscore * 1) / 1 
    this.levelStatus++
  }


  async getLevels(){
    this.getMyLevel()
    if(Data.leaderUsers == null){
      Data.leaderUsers = await UserApi.getLeaderUsers()
    }
    const usersArray = Data.leaderUsers
    const highestScore = Math.max(...usersArray.map(o => o.score), 0);
    const dividedscore = highestScore / 10
    const highestLevel = Math.trunc(dividedscore * 1) / 1 
    const newleaders = Data.leaders.filter(leader => leader.level <= highestLevel)
    this.openLevels = newleaders
    if(highestLevel == 10){ this.moreLevels = false } 
    else { this.moreLevels = true }
    this.levelStatus++
    if( document.title !== 'Leader Levels' ){return}
    this.render()
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">      
          ${this.levelStatus !== 2? html`
            <br>
            <br>
            <div class="spinner sp-sml"></div>
          `: html`
           
            ${this.moreLevels == false ? html``:html`
              <p class="para app-info h-center">"Next Level Coming Soon"</p>
            `}
            ${this.openLevels.map(leader => html `

              ${leader.level == this.myLevel? html`
                <leader-level
                  title="${leader.levelTitle}"
                  level="${leader.level}"
                  currentUser=true
                  countryCode="${Auth.currentUser.countryCode}"
                ></leader-level>
              `:html`
                <leader-level
                title="${leader.levelTitle}"
                level="${leader.level}"
              ></leader-level>
              `}
            `)} 

          `} 
          
        </div> 

      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new LeaderboardLevelView()