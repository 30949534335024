import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import Data from '../../Data' // for app variables  CheckoutAPI
import Utils from '../../Utils'
import Auth from '../../Auth'
import CheckoutAPI from '../../api/CheckoutAPI'

customElements.define('cmi-purchase', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: {
        type: String
      }
    }
  }

  firstUpdated(){   
    super.firstUpdated()
    this.fontUpdate()
  }

  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = Utils.myfont +'em';
    })
  }


  async toCheckout(){
    // adjust container sizes for consistency
    const mmView = this.shadowRoot.getElementById('cm-view-main')
    const wtView = this.shadowRoot.getElementById('cm-view-wait')
    const errView = this.shadowRoot.getElementById('cm-view-err')
    const clientWidth = mmView.clientWidth;
    const clientHeight = mmView.clientHeight;
    wtView.style.width = clientWidth
    wtView.style.height = clientHeight
    errView.style.width = clientWidth
    errView.style.height = clientHeight

    //hide main and show wait
    mmView.classList.add('hidden')
    wtView.classList.remove('hidden')

    //run the setup payment code
    let checkoutData = {}
    checkoutData.purchaseCodes = Data.purchaseCodes
    checkoutData.userId = Auth.currentUser._id
    if(Auth.currentUser.customerId){ checkoutData.customerId = Auth.currentUser.customerId }
  
    try {
      const checkoutObject = JSON.stringify(checkoutData);
      const initStatus = await CheckoutAPI.setUpPayment(checkoutObject)
      //if successful the user is redirected
      if(initStatus ==  true) {
        gotoRoute('/checkout')
      } else {
        //console.log('An error occured setting up the payment')
        wtView.classList.add('hidden')
        errView.classList.remove('hidden')
      }
    } catch(err){
      //console.log('Catch error')
      console.log(err)
      wtView.classList.add('hidden')
      errView.classList.remove('hidden')
    }
    Utils.closeModal()
    gotoRoute('/checkout')
  }

  cancelPurch(){
    Utils.closeModal()
    //reset purchase data
    Data.purchase = []
    Data.purchaseCodes = []
    Data.purchaseTotal = 0
  }


  

  render(){    
    return html`
      <style>  
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .check-out-form {
          margin-bottom: 20px;
          padding: 1em 1em 0 1em;
          border: solid 2px var(--two-color);
          border-radius: 10px;
          text-align: center;
        }
        h2 { margin: 0 0 20px 0; font-family: 'bree', Tahoma, sans-serif; }

        .purchase-item {
          text-align: left;
          margin: 20px 0;
          padding-bottom: .5em;
          border-bottom: solid .5px var(--two-color);
        }
        .item { margin: auto; }
        .price { font-weight: bold; text-align: center; margin: 0; padding: 0; }

        .check-price { font-weight: bold; text-align: right; margin: 0; padding: 0; }
        .total {
          font-weight: bold;
          font-size: 1.2em;
          text-align: right;
          margin-top: 20px;
          color: var(--three-color);
        }
        strong { margin-right: 10px; }
        .payment-form { margin-top: 20px; text-align: center; }
        .mi-box { text-align: center; font-size: 1.1em; }
        .mi-text { margin-bottom: 30px;  width: 250px;  }
        .para { font-size: 1.2em;  }

        .redirect-button {
          color: white;
          border: none;
          border-radius: 5px;
          padding: 7px;
          margin: 20px auto;
          text-decoration: none;
          width: 200px; 
          cursor: pointer;
          font-size: 1.1em;
        }

        .primary { background-color: var(--brand-color); cursor: pointer; transition: background-color 0.4s ease-out; }
        .primary:hover { background-color: var(--two-color); }
        .action { margin: 30px 0; background-color: var(--action-color) ; transition: .4s;  }
        .action:hover { background-color: rgb(243, 183, 18); }
        .warning { background-color: red;  }
        .disabled { background-color: var(--disabled-color); cursor: none; }
        .hidden { display: none; }

        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }

      </style>

      <div id="cm-view-main" class="cm-view">

        <div class="check-out-form para">
          <h2>Purchase Order</h2>
            ${Data.purchase.map(purchase => html `
              <div class="purchase-item"> 
                <div class="item">  ${purchase.title} </div>
                <div class="check-price"> $${purchase.price} </div>
              </div>
            `)}
          <div class="total">
            <strong>Total</strong>USD<strong>:   </strong> $${Data.purchaseTotal}
          </div>
          <button class="redirect-button action" @click=${this.toCheckout}>Confirm</button>
        </div>

        <div class="payment-form para">
          <div class="redirect-button primary" @click=${this.cancelPurch}>Cancel</div>
        </div>
      </div>

      <div id="cm-view-wait" class="cm-view hidden">
        <br><br>
        <p class="mi-text"> Setting up payment ... </p>
        <br><br>
        <c-spinner></c-spinner>
      </div>

      <div id="cm-view-err" class="cm-view hidden">
        <br><br>
        <p class="mi-text">Something went wrong!</p>
        <br>
        <img id="warn" alt="Error Exclamation" src="/images/icons/warning.svg">
        <br><br>
        <div class="redirect-button primary" @click=${this.cancelPurch}>Back</div>
      </div>

    `
  }
  
  
})
