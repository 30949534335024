import {LitElement, html} from 'lit'
import Data from '../../Data' // for app variables
import Utils from '../../Utils'


customElements.define('forum-post', class ForumComponents extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      post: { type: Object },
      typeInfo: { type: String },
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
    this.fontUpdate()
    this.postUpdate()
  }

  postUpdate(){
    const pTextDiv = this.shadowRoot.getElementById('post-text-formatted')
    const formattedText = Utils.formatParagraphText(this.post.postText)
    pTextDiv.innerHTML = formattedText
  }

  replyHandler(){
    Data.myreplypost = this.post
    const btnDiv = this.shadowRoot.getElementById('reply-btn-div')
    const postDiv = this.shadowRoot.getElementById('reply-post-div')
    btnDiv.classList.add('hidden')
    postDiv.innerHTML = `<new-post typeInfo="reply-post"></new-post>`
  }

  animtePostDiv(){
    const postDiv = this.shadowRoot.getElementById('reply-post-div')
    postDiv.style.maxHeight = Math.max(postDiv.scrollHeight) + "px";
  }

  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.c-para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = Utils.updatedfont
    })
  }

  scrollToPost(){
    const replyPost = document.getElementById(this.post.replyId)
    replyPost.scrollIntoView({
      top: -20,
      behavior: 'smooth'
    })
  }

  // <div class="post-wrap"> 
    // <div class="post-name"> ${this.post.user.userName} </div>
    // <div class="post-date"> ${new Date(this.post.createdAt).toLocaleDateString()}</div>
    // ${this.post.replyShort ? html`

  render(){    
    return html`
    <style>      
    
    * { box-sizing: border-box; margin: 0px; padding: 0px; }

    .post-wrap {
      border-bottom: solid 2px var(--two-color);
      margin: 10px; 
      padding: 5px;
    }
    .post-name{
      color: var(--three-color); 
      font-size: 1em; 
      font-weight: bold;
      margin-right: 10px;
      display: inline;
      overflow-wrap: break-word;
    }
    /* .post-date{
      color: rgb(132, 130, 130); 
      font-size: 0.9em;
      display: inline;
    } */
    .post-quote { 
      display: block; 
      width: 100%;
      position: relative;
      border-bottom: solid 0.5px #d7d7d7;
      margin-top: 5px;
      padding-left: 10px;
    }
    .quote-text { 
      width: 90%; 
      color: #818080; 
      margin-bottom: 5px; 
      word-break: break-word;
    }

    .qbtn-wrap { 
      position: absolute; 
      right: 3px;
      top: -2px;
      width: 9%; 
    }
    .quote-text:before { content: open-quote; }
    .quote-text:after { content: close-quote; }

    .quote-text:before,
    .quote-text:after {
      display: inline-block;
      vertical-align: bottom;
      position: relative;
    }

    .quote-link-wrap {
      height: 25px;
      width: 25px;
      position: relative;
      transition: .4s;
    }

    .quote-link-wrap:hover { transform: rotate(-90deg) !important; }
    .quote-link-wrap:after {
      content: '';
      width: 50%;
      height: 50%;
      right: 6px;
      top: 3px;
      border-top: 6px solid var(--brand-color);
      border-right: 6px solid var(--brand-color);
      border-radius: 4px;
      transform: rotate(45deg);
      position: absolute;
    }

    .post-text { margin: 5px 0; word-break: break-word; }
    .c-para { font-size: 1.2em; }

    .reply-link {
      border: solid 2px var(--action-color);
      border-radius: 7px;
      color: var(--action-color);
      width: 50%;
      margin: auto;
      padding: 5px;
      text-align: center;
      transition: .4s;
      font-weight: bold;
      cursor: pointer;
      user-select: none;
    }
    .reply-link:hover { color: rgb(230, 139, 28); }
    .waiting { color: red; margin-bottom: 8px; }


    .hidden { display: none; }


    
      
    /* ------------------- RESPONSIVE ------------------- */




  </style>
   
      <div class="post-wrap"> 
        <div class="post-name"> ${this.post.user.userName} </div>
        ${this.post.replyShort ? html`
          <div class="post-quote"> 
            <div class="quote-text c-para">${this.post.replyShort}</div> 
            <div class="qbtn-wrap" @click=${this.scrollToPost}>
              <div class="quote-link-wrap"></div>
            </div>
          </div>
        `: html``}
          <p id="post-text-formatted" class="post-text c-para"></p>
          ${this.typeInfo == "mod" ? html``:html`
            ${this.typeInfo == "this-user" ? html`
              <p class="waiting">*Waiting for approval</p>
            `:html`
              <div id="reply-btn-div">
                <div class="reply-link" @click=${this.replyHandler.bind(this)}>Reply</div>
              </div>
              <div id="reply-post-div"></div>
            `}
          `}
      </div > 

    `
  }
  
})
