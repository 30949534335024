[{
  "item": "year",
  "title": "One year forum access",
  "price": 150
},{
  "item": "month",
  "title": "One month forum access",
  "price": 15
},{
  "item": "quiz",
  "title": "Open access to quizzes and leader boards",
  "price": 20
}]
