import {LitElement, html} from 'lit'
import {navClick} from '../Router'


customElements.define('leader-level', class LeaderLevel extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      level: { type: String },
      title: { type: String },
      currentUser: { type: Boolean },
      countryCode: { type: String }
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
    this.classes()
    this.render()
  }


  classes(){
    const buttons = this.shadowRoot.getElementById('this10')
    if(buttons == null){
      return 
    } else {
    buttons.classList.add('top-level')
    }
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .level-frame { position: relative; 
          display: flex; align-items: center; justify-content: left;
          height: 100px; }
        .level-btn { position: absolute; z-index: 3; }
        .text-wrap {  z-index: 5; 
          font-size: 1.8em;
          font-weight: bold;
          display: flex; align-items: center; justify-content: space-between;
          width: 80%;
        }
        .title {margin: 0 50px; }
        .me-img { position: absolute; z-index: 10; height: 60px;
          margin: 5px 15px; right: 0; }

        .mycountry { position: absolute; z-index: 11; right: 20px; height: 50px; margin: 5px 0;} 
        .top-level { color: white; }
        

        @media all and (max-width: 1050px){     
          .title {margin: 0 30px; }
          .text-wrap { font-size: 1.5em; }
          .level-frame { height: 80px; }
        }

        @media all and (max-width: 850px){     
          .text-wrap { font-size: 1.4em; width: 85%; }
        }

        @media all and (max-width: 769px){  
          .level-frame { width: 75vw; margin: auto; }
        }

        @media all and (max-width: 769px){  
          .level-frame { width: 80vw;}
        }

        @media all and (max-width: 450px){ 
          .text-wrap { font-size: 1em; width: 90%; }
          .level-frame { height: 60px; }
        }

        @media all and (max-width: 350px){  
          .level-frame { width: 90vw;}
          .text-wrap { font-size: .9em;  }
          .title { margin: 0 20px; }
        }

      </style>

      <nav class="level-frame" nav-link="/quiz/leaderboards?level=${this.level}" @click="${navClick}">
        <img class="level-btn" src="./../images/quiz-badges/leader-btn-${this.level}.svg">
        <div id="this${this.level}" class="text-wrap">
          <div class="title">${this.title}</div>
          <div class="level">Level ${this.level}</div>
        </div>

        ${this.currentUser == true? html`
          <img class="me-img" src="./../images/quiz-badges/me-hold-main.svg">
          <img class="mycountry" src="./../images/flags/flag-${this.countryCode}.svg">
        `:html``}
      </nav>

    `
  }
  
})
