import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'
import Data from '../../../Data'
import Images from '../../../Images'
import Utils from '../../../Utils'
import Validate from '../../../Validate'
import Sign from '../../../Sign'

//Version 2 - add the event listener to active events array via Events
class SigninView {
  init(){    
    document.title = 'Signin'  
    this.render()  
    Utils.initFont() 
    document.addEventListener('keyup', Sign.signEnter) 
    //this.insertImages()
  }

  //this works but the sizing CSS needs revising
  //<div id="logo" class="signinup-logo-mobile logo-home" alt="${Data.apptitle}"></div>
  insertImages(){
    const logoDiv = document.getElementById('logo')
    logoDiv.innerHTML = Images.logo
  }

  viewForgot(){
    const formTitle = document.getElementById('frm-title')
    const toggleView = document.querySelectorAll('#sign-div, #forgot-div')
    formTitle.innerHTML = "Forgot Password"
    toggleView.forEach(view => { 
      view.classList.toggle('hidden')
    })
  }
  

  viewSign(){
    const formTitle = document.getElementById('frm-title')
    formTitle.innerHTML = "Sign In"
    const toggleView = document.querySelectorAll('#sign-div, #forgot-div')
    toggleView.forEach(view => { 
      view.classList.toggle('hidden')
    })
  }

  render(){
    const template = html`
      
      <div class="sign-content v-center">
        <div class="form-box"> 
          <div id="main-tab">
            
            <img class="signinup-logo-mobile logo-home" alt="${Data.apptitle}" src="/images/icons/1-logo-${Data.appcode}.svg" >
            <img class="signinup-logo-mobile" alt="${Data.apptitle}" src="/images/icons/2-apptext-${Data.appcode}.svg" ><br>
            <p id="frm-title" class="form-name">Sign In</p>      
        
            <p id="user-email-wrn" class="input-require">*Need a height</p>
            <div class="input-group">
              <input id="user-email" class="para" type="email" placeholder="Email"  @keyup=${Validate.clearWarning.bind(Validate)}  required/>
            </div>

            <div id="sign-div">
              <p id="password-wrn" class="input-require">*Need a height</p>
              <div class="input-group">
                <input id="password" class="para" type="password" placeholder="Password"  @keyup=${Validate.clearWarning.bind(Validate)}  required>
              </div>
              <button class="submit-btn" submit @click=${Sign.signInSubmitHandler.bind(Sign)}>Sign in</button>
              <p>No Account? <a href="/signup" class="sign-a-link" @click=${anchorRoute}>Sign Up</a></p>
              <p> <a href="#" class="sign-a-link" @click=${this.viewForgot}>Forgot password?</a></p>
            </div>

            <div id="forgot-div" class="hidden">
              <button class="submit-btn" submit @click=${Sign.sendLinkHandler.bind(Sign)}>Send Reset Link</button>
              <br><br>
              <p> <a href="#" class="sign-a-link" @click=${this.viewSign}>Back to Sign In</a></p>
            </div>
            <a href="/" class="home-button primary" @click=${anchorRoute}>Home</a>
          </div>
          <div id="status-tab"></div>
        </div>
      </div>  
    `

    render(template, App.rootEl)
  }
}

export default new SigninView()