import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute} from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Validate from '../../../Validate'
import Utils from '../../../Utils'
import UserApi from '../../../auth/UserApi'

//add conditions for users with low scores so they must try again
class QuizView {
  init(){    
    document.title = 'Quiz'    
    this.lessonNo = null
    this.nextlesson = null
    this.quizquestions = null
    this.nextQuestion = null
    this.questionIndex = 0
    this.correctAnswers = 0
    this.render()  
    Utils.initFont()  
    this.initQuiz()
  }
  

  async initQuiz(){
    const urlParams = new URLSearchParams(location.search)
    const testtext = urlParams.get('level')
    const cleantext = await Validate.sanitize(testtext)
    if(cleantext == null){ gotoRoute('/quiz/menu'); return }
    this.lessonNo =  parseInt(cleantext)
    this.nextlesson = this.lessonNo
    this.nextlesson++
    const myquestions = Data.quizzes.find(quiz => quiz.lesson == this.lessonNo)
    const orderedQuestions = myquestions.questions //get the questions
    const randomQuestions = orderedQuestions //shuffle the questions
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value)
    this.quizquestions = randomQuestions // set the suffled questions
    //set the first question in the array
    this.nextQuestion = this.quizquestions[0]
    this.render()
    Utils.initFont()     
  }

  selectQuestionHandler(e){
    const selectnew = e.target.closest('.select-item')
    this.deselect()
    selectnew.classList.add('select-active')
  }

  deselect(){
    const unselect = document.querySelectorAll('.select-item')
    unselect.forEach(item => {
      item.classList.remove('select-active')
    })
  }

  async nextQuestionHandler(){
    const selected = document.querySelectorAll('.select-active')
    const infoalert = document.getElementById('quiz-info')
    if(selected.length == 0){
      infoalert.innerText = 'Please select an answer'
      return
    } else {
      infoalert.innerText = ''
    }
    const searchanswer = selected[0].innerText 
    const selectedanswer = this.nextQuestion.answers.find(nanswer => nanswer.answer == searchanswer)
    if(selectedanswer.answerStatus == "correct"){
      console.log('well done')
      Data.addScore++
      console.log("New score" + Data.addScore)
      this.correctAnswers++
    } else {
      console.log('nice try')
    }
    this.deselect()

    this.questionIndex++
    this.nextQuestion = this.quizquestions[this.questionIndex]
    this.render()
    Utils.initFont()   

    if(this.questionIndex == 5){
      let userResults = {}
      userResults.score = Data.addScore + Auth.currentUser.score
      if(this.correctAnswers > 2){
        userResults.quizLevel = Auth.currentUser.quizLevel + 1
      }
      try {
        const userObject = JSON.stringify(userResults);
        const updated = await UserApi.updateUser(Auth.currentUser._id, userObject)
        if(updated == false){
          console.log('score not update')
          return
        } else { 
          //console.log('score successfully update')
          //users score is automatically upated when loggin in
          if (Data.leaderUsers !== null){
            //find the user and update thier score from the array
            const index = Data.leaderUsers.findIndex(object => {
              return object._id === Auth.currentUser._id;
            });
            if (index !== -1) {
              Data.leaderUsers[index].score = Auth.currentUser.score;
            }
          }          
          //reset addscore
          Data.addScore = 0
        }
      }catch(err){
        console.log('Problem updating score')
        console.log(err)
      }
    }
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.lessonNo} Quiz"></main-app-header>

        <div class="content-panel">   
          
          ${this.questionIndex == 5 ? html`
            <br>

            ${this.correctAnswers == 5 ? html`
              <p class="app-info para h-center"><strong>Excellent!</strong></p>
            `:html`
              ${this.correctAnswers > 2 ? html`
                <p class="app-info para h-center"><strong>Well done!</strong></p>
              `:html`
                <p class="app-info para h-center"><strong>Nice try!</strong></p>
                <p class="app-info para h-center">Quiz yourself again to access the next level</p>
              `}
            `}
          
            <div class="score">${this.correctAnswers} / 5</div>
            <lesson-nav lessonnumber=${this.lessonNo} nextlesson=${this.nextlesson}></lesson-nav>

          `: html`

            ${this.nextQuestion == null ? html`
              <br>
              <br>
              <div class="spinner sp-sml"></div>
            `: html`

              <div class="lesson-title-box">Question ${this.questionIndex+1} / 5</div> 
              <br>
              <p class="app-info para">
                <strong>${this.nextQuestion.question}</strong>
              </p>
              
              <div class="qa-box">
                ${this.nextQuestion.answers.map(value => ({ value, sort: Math.random() }))
                  .sort((a, b) => a.sort - b.sort)
                  .map(({ value }) => value)
                  .map(qanswer => html `
                    ${qanswer.answerStatus == "correct" ? html`
                    <div class="select-item para" @click="${this.selectQuestionHandler.bind(this)}">${qanswer.answer}<div class="s-point"></div></div>
                  `:html`
                    <div class="select-item para" @click="${this.selectQuestionHandler.bind(this)}">${qanswer.answer}<div class="s-point"></div></div>
                  `}
                `)}
              </div>
            `}

            <nav class="quiz-btn action"  @click="${this.nextQuestionHandler.bind(this)}">
              Next Question
              <img class="lb-img" alt="next question" src="./../images/icons/sw10-next.svg">
            </nav>
            <p id="quiz-info" class="app-info para warning-text"></p>
          `}

        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new QuizView()