import App from '../../../App'
import {render, html} from 'lit/html.js'
import {gotoRoute } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Nstat from '../../../Nstat'

// NOTE: Don't use "enter" eventListener if there are 2 action btns on a page

class EditCaseView {
  init(){    
    document.title = 'Edit Incident'    
    this.mycase = {
      "caseTitle": "",
      "survivor": {},
      "incident": {},
      "place": {},
      "perpetrator": {}
    }
    this.newtitle = null
    this.newSurvivor = null
    this.newIncident = null
    this.newPlace = null
    this.newPerpetrator = null
    this.render()  
    Utils.initFont()   
    Nstat.resetCase()
    this.initEditCase()
  }

  initEditCase(){
    if(!Auth.caseId){
      gotoRoute('/user/cases')
      return
    }
    this.getMyCase()
    this.setFields()
    this.render()  
  }

  // get mycase mycase
  getMyCase(){
    this.mycase = Auth.myCases.find(mycase => mycase._id == Auth.caseId )
  }

  setFields(){
    // Set radio buttons
    const radiosDis = document.getElementsByName('survivorDisability')
    const radiosImp = document.getElementsByName('survivorImpact')
    radiosDis.forEach(radio => {
      if (radio.value == this.mycase.survivor.survivorDisability){
        radio.checked = true
      }
    })
    radiosImp.forEach(radio => {
      if (radio.value == this.mycase.survivor.survivorImpact){
        radio.checked = true
      }
    })
    //Set impact array
    if(this.mycase.survivor.impactType){
      if(this.mycase.survivor.impactType.length !== 0){
        const multiBoxes = document.getElementById('multione').getElementsByClassName('agree-box')
        for(var i = 0; i < multiBoxes.length; i++){
          if(this.mycase.survivor.impactType.includes(multiBoxes[i].nextElementSibling.innerText)){
            multiBoxes[i].firstChild.nextElementSibling.classList.add('multi-check')
          }
        }
      }
    }
    //set conditional fields
    if(this.mycase.incident.ongoingDuration){
      this.setSelect("ongoingDuration", this.mycase.incident.ongoingDuration)
      this.showSelect("ongoing")
    }
    if(this.mycase.place.caseState){
      this.setSelect("caseState", this.mycase.place.caseState)
      this.showSelect("states")
    }
    if(this.mycase.place.subdivision){
      this.setSelect("subdivision", this.mycase.place.subdivision)
      this.showSelect("subdiv")
    }
    if(this.mycase.place.sector){
      this.setSelect("locationSector", this.mycase.place.sector)
      this.showSelect("sector")
    }
    this.setDynamicOptions()
  }

  setDynamicOptions(){
   const cfValue = this.mycase.incident.caseFrequency
    const cfOptions = Data.statisticsOptions
    .find(field => field.fieldName  == "caseFrequency" )
    .options.find(field => field.optionText == cfValue )
    .subOptions.find(field => field.soField  == "caseType" )
    .soText
    Nstat.populateOptions('caseType-l', cfOptions)
    //STATES = from country code
    if(this.mycase.place.caseState){
      const ccValue = this.mycase.place.caseCountry
      const myCountry = Data.allCountries.find(field => field.countryName == ccValue )
      Nstat.populateOptions('caseState-l', myCountry.state)
    }
    //perpposition = from location
    const clValue = this.mycase.place.caseLocation
    const locationArray = Data.statisticsOptions
      .find(field => field.fieldName  == "caseLocation" )
      .options.find(field => field.optionText == clValue )
    const clOptions = locationArray
      .subOptions.find(field => field.soField  == "perpPosition" )
      .soText
    Nstat.populateOptions('perpPosition-l', clOptions)
    // subdivision = from location
    if(this.mycase.place.subdivision){
      const myLocation = locationArray.subOptions.find(field => field.soField == 'subdivision' )
      Nstat.populateOptions('subdivision-l', myLocation.soText)
    }
    // sector = from location
    if(this.mycase.place.sector){
      const mySector = locationArray.subOptions.find(field => field.soField == 'locationSector' )
      Nstat.populateOptions('locationSector-l', mySector.soText)
    }
  }

  showSelect(div){
    const thisDiv = document.getElementById(div)
    thisDiv.classList.remove('hidden')
  }

  setSelect(select, value){
    const thisSelect = document.getElementById(select)
    thisSelect.options[0].text = value
    thisSelect.options[0].value = value
  }

  activateUpdateBtn(){
    const updateBtn = document.getElementById('update-case')
    if(updateBtn.classList.contains('disabled')){
      updateBtn.classList.remove('disabled')
      updateBtn.classList.add('forum-action-btn', 'action')
    }
  }


  editUserHandler(){
    console.log('begin user handler: new version')
    Nstat.userValidation()
    if(Nstat.userValidated == false){
      Utils.scrollTopWarning()  
      console.log('user not validated')
      return
    }
    //check if the fields are updated
    const deepequalOk = this.deepEqual(this.mycase.survivor, Nstat.caseData.survivor)
    if(this.mycase.caseTitle == Nstat.caseData.caseTitle && deepequalOk == true){
      console.log('all data is matching - return out')
      return
    } else {
      if(deepequalOk == false){
        this.newSurvivor = Nstat.caseData.survivor
        console.log('newSurvivor')
        console.log(this.newSurvivor)
      }
      if(this.mycase.caseTitle !== Nstat.caseData.caseTitle){
        this.newtitle = Nstat.caseData.caseTitle
        console.log('newtitle')
        console.log(this.newtitle)
      }
      this.activateUpdateBtn()
      Utils.scrollTopSmooth() 
    }
  }

  //This code checks if objects are exactly the same including all internal objects.
  deepEqual(x, y){
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
        ok(x).every(key => this.deepEqual(x[key], y[key]))
    ) : (x === y);
  }

  editIncidentHandler(){
    Nstat.caseValidation()
    if(Nstat.caseValidated == false){
      Utils.scrollTopWarning()
      return
    }
    const deepequalOk = this.deepEqual(Nstat.caseData.incident, this.mycase.incident)
    if(deepequalOk == true){
      console.log('all case data is matching - return out')
      return
    } else {
      //there are changes
      this.newIncident = Nstat.caseData.incident
      this.activateUpdateBtn()
      Utils.scrollTopSmooth()
    }
  }

  editPlacetHandler(){
    Nstat.placeValidation()
    if(Nstat.placeValidated == false){
      Utils.scrollTopWarning()
      return
    }
    const deepequalOk = this.deepEqual(Nstat.caseData.place, this.mycase.place)
    if(deepequalOk == true){
      //console.log('all place data is matching - return out')
      return
    } else {
      //there are changes
      if(Nstat.caseData.place.caseLocation !== this.mycase.place.caseLocation){
        Nstat.pprequired = true
      }
      this.newPlace = Nstat.caseData.place
      this.activateUpdateBtn()
      Utils.scrollTopSmooth()
    }
  }

  editPerpHandler(){
    Nstat.perpValidation()
    if(Nstat.perpValidated == false){
      Utils.scrollTopWarning()
      return
    } else {
      Nstat.pprequired = false
    }
    const deepequalOk = this.deepEqual(Nstat.caseData.perpetrator, this.mycase.perpetrator)
    console.log(deepequalOk)
    if(deepequalOk == true){
      //console.log('all perp data is matching - return out')
      return
    } else {
      this.newPerpetrator = Nstat.caseData.perpetrator
      this.activateUpdateBtn()
      Utils.scrollTopSmooth()
    }
  }

  tabButton(e){
    const thisTabBtn = e.target.getAttribute('id')
    const lastChar = thisTabBtn.slice(-1)
    Nstat.showEditTab(lastChar)
  }

  updateCaseHandler(){
    let detailsUpdated = false
    //title
    if(this.newtitle !== null){ 
      Nstat.caseUpdates.caseTitle = this.newtitle
      detailsUpdated = true 
    } else {
      Nstat.caseData.caseTitle = this.mycase.caseTitle
    }
    //survivor
    if(this.newSurvivor !== null){ 
      Nstat.caseUpdates.survivor = {}
      Nstat.caseUpdates.survivor = this.newSurvivor
      detailsUpdated = true 
    } else {
      Nstat.caseData.survivor = this.mycase.survivor
    }
    //incident
    if(this.newIncident !== null){ 
      Nstat.caseUpdates.incident = {}
      Nstat.caseUpdates.incident = this.newIncident
      detailsUpdated = true 
    } else {
      Nstat.caseData.incident = this.mycase.incident
    }
    //place
    if(this.newPlace !== null){ 
      Nstat.caseUpdates.place = {}
      Nstat.caseUpdates.place = this.newPlace
      detailsUpdated = true 
    } else {
      Nstat.caseData.place = this.mycase.place
    }
    //perp
    if(this.newPerpetrator !== null){ 
      Nstat.caseUpdates.perpetrator = {}
      Nstat.caseUpdates.perpetrator = this.newPerpetrator
      detailsUpdated = true 
    } else {
      Nstat.caseData.perpetrator = this.mycase.perpetrator
    }

    if(detailsUpdated==false){
      Utils.cmodalNoChange()
    } else {
      if(Nstat.pprequired == true){
        Utils.cmodalPpRequired()
      } else {
        Utils.cmodalEditCaseReady()
      }
    }
  }
  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-full" id="contentpanelid">  

          ${this.mycase == null? html`
            <br>
            Retreiving case details ...
            <br>
            <div class="spinner sp-sml"></div>
          `:html`
            <h2 class="case-title">${this.mycase.caseTitle}</h2>
            *Add the new details for the required sections and then update the incident ...
            <div id="update-case" class="redirect-button disabled" @click=${this.updateCaseHandler.bind(this)}><strong>Update</strong></div>
            <br>
         
            <form class="form-full" name="case-form" method="post">

              <input id="survivorGender" type="hidden" name="survivorGender" value="${Auth.currentUser.usergroup}"/>

              <div id="tab-buttons">
                <nav id="tb-1" class="tb-btns tb-active" @click="${this.tabButton}">User</nav>
                <nav id="tb-2" class="tb-btns" @click="${this.tabButton}">Incident</nav>
                <nav id="tb-3" class="tb-btns" @click="${this.tabButton}">Place</nav>
                <nav id="tb-4" class="tb-btns" @click="${this.tabButton}">Perpetrator</nav>
                <div class="bottom-line"></div>
              </div>

<!--  USER TAB 1 -->
              <div id="tab-1" class="tab-section">
                <h3 class="case-h2">User</h3>

    <!-- caseTitle -->
                <label class="para label">Incident title:</label> (For your reference only)
                <div class="input-group">
                  <input class="para" name="caseTitle" value="${this.mycase.caseTitle}">
                </div>

    <!-- survivorAge -->
                <div class="input-group">
                  <label class="para label">Age at time of Incident:</label>
                  <p id="survivorAge-wrn" class="input-require">* Valid age required</p>
                  <input title="Survivor's age" class="para input-number-year" type="number" id="survivorAge" name="survivorAge" max="110" min="0" value="${this.mycase.survivor.survivorAge}">
                </div>


    <!-- survivorDisabilityy /n-->
                <label class="para label">Did you have a visibility Disability?</label>
                <p id="survivorDisability-wrn" class="input-require">* Selection required</p>
                <div class="select-two h-center"> 
                  <div class="radio">
                    <input title="Yes Disability" id="survivorDisabilityy" name="survivorDisability" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="With visible disability">
                    <label for="toggle-on">Yes</label>
                  </div>
                  <div class="radio">
                    <input title="No Disability" id="survivorDisabilityn" name="survivorDisability" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="No visible disability">
                    <label for="toggle-off">No</label>
                  </div>
                </div>

    <!-- survivorImpacty -->
                <label class="para label">Were you impacted by the incident?</label>
                <p id="survivorImpact-wrn" class="input-require">* Selection required</p>
                (or no choices selected below)
                <div class="select-two h-center"> 
                  <div class="radio">
                    <input title="Yes Impact" id="survivorImpacty" name="survivorImpact" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="Yes, I was impacted by it">
                    <label for="toggle-on">Yes</label>
                  </div>
                  <div class="radio">
                    <input title="No Impact" id="survivorImpactn" name="survivorImpact" type="radio" @click="${Nstat.radioHandler.bind(Nstat)}" value="No, I wasn't">
                    <label for="toggle-off">No</label>
                  </div>
                </div>


    <!-- impactType -->
                <div id="multione" class="input-group">
                  <label class="para label">If yes, how were you impacted?</label>
                  (Select one or more)
                  <p id="impactType-wrn" class="input-require">* Selection required if impacted</p>
                    ${Data.statisticsOptions.find(field => field.fieldName == "impactType" )
                      .options
                      .map(option => html `
                        <div class="multi-select">
                          <div class="agree-box" @click="${Nstat.multiCheck.bind(Nstat)}">
                            <div class="multi-tick"></div> 
                          </div> 
                          <p class="multi-text">${option}</p>
                        </div>
                    `)}
                </div><!-- end multione-->

                <div id="next1" class="redirect-button forum-action-btn action" @click=${this.editUserHandler.bind(this)}><strong>Add User Details</strong></div>
              </div>

<!-- INCIDENT TAB 2   -->
              <div id="tab-2" class="tab-section hidden">
                <h3 class="case-h2">Incident</h3>

    <!-- caseYear -->
                <div class="input-group">
                  <label class="para label">Year incident took place:</label>
                  <p id="caseYear-wrn" class="input-require">* Valid year required</p>
                  <input title="Year" class="para input-number-year" type="number" id="caseYear" name="caseYear" min="1930" max="${new Date().getFullYear()}" value="${this.mycase.incident.caseYear}">
                </div>

    <!-- caseReported -->
                <div class="input-group">
                  <label class="para label">Was it reported?</label>
                  <p id="caseReported-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Reported" id="caseReported" class="para msc-input" type="text" name="caseReported" @mousedown="${Nstat.preventSelect}">
                      <option selected value="${this.mycase.incident.caseReported}">${this.mycase.incident.caseReported}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseReported-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "caseReported" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>
                
  <!-- Condition 2A1) for duration and type -->
    <!-- caseFrequency -->
                <div class="input-group">
                  <label id="freq-label" class="para label">How often?</label>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <p id="caseFrequency-wrn" class="input-require">* Selection required</p> 
                    <select title="Frequency" id="caseFrequency" class="para msc-input" type="text" name="caseFrequency" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.incident.caseFrequency}">${this.mycase.incident.caseFrequency}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseFrequency-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "caseFrequency" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option.optionText}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

  <!-- Condition 2A2) depending on frequency - ongoing -->
    <!-- ongoingDuration -->
                <div id="ongoing" class="input-group hidden">
                  <label class="para label">How long did it go on for?</label>
                  <p id="ongoingDuration-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                  <!-- Here add the condition for the select... -->
                    <select title="Duration" id="ongoingDuration" class="para msc-input" type="text" name="ongoingDuration" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="Select">Select duration ...</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="ongoingDuration-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName  == "caseFrequency" )
                          .options.find(field => field.optionText == "Ongoing" )
                          .subOptions.find(field => field.soField  == "ongoingDuration" )
                          .soText
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>
  


  <!-- Condition 2A3) depending on frequency -->
    <!-- caseType -->
                <div class="input-group">
                  <label class="para label">What type of incident?</label>
                  <p id="caseType-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Case Type" id="caseType" class="para msc-input" type="text" name="caseType" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.incident.caseType}">${this.mycase.incident.caseType}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseType-l" class="msc-ul para"></ul> 
                    </div>
                  </div>
                </div>

        <!-- caseReaction -->
                <div class="input-group">
                  <label class="para label">Main response:</label>
                  <p id="caseReaction-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Main Response" id="caseReaction" class="para msc-input" type="text" name="caseReaction" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.incident.caseReaction}">${this.mycase.incident.caseReaction}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseReaction-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "caseReaction" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

                <div id="next2" class="redirect-button forum-action-btn action" @click=${this.editIncidentHandler.bind(this)}><strong>Add Incident Details</strong></div>
              </div>

<!-- PLACE TAB 3 -->
              <div id="tab-3" class="tab-section hidden">
                <h3 class="case-h2">Place</h3>

  <!-- CONDITION 3A1) for states .... -->
    <!-- caseCountry -->
                <div class="input-group">
                  <label class="para label">Which country?</label>
                  <p id="caseCountry-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Country" id="caseCountry" class="para msc-input" type="text" name="caseCountry" @mousedown="${Nstat.preventSelect}" value="${this.mycase.place.caseCountry}">
                      <option disabled selected value="${this.mycase.place.caseCountry}">${this.mycase.place.caseCountry}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseCountry-l" class="msc-ul para">
                      ${Data.priority.map(country => html `
                        <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                        `)}
                        <div class="priority"></div>
                      ${Data.allCountries.map(country => html `
                          <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${country.countryName}</p></li>
                        `)}
                      </ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 3A2) if country has states .... -->
    <!-- caseState -->
                <div id="states" class="input-group hidden">
                  <label class="para label">Which state?</label>
                  <p id="caseState-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="State" id="caseState" class="para msc-input" type="text" name="caseState" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="Select">Select state ...</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseState-l" class="msc-ul para"></ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 3B1) for subdivision & locationSector -->
  <!-- CONDITION 4A1) for perpPosition .... -->
    <!-- caseLocation -->
                <div class="input-group">
                  <label id="loc-label" class="para label">Which place?</label>
                  <p id="caseLocation-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Location" id="caseLocation" class="para msc-input" type="text" name="caseLocation" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.place.caseLocation}">${this.mycase.place.caseLocation}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="caseLocation-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "caseLocation" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option.optionText}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 3B2) depending on place -->
    <!-- subdivision -->
                <div id="subdiv" class="input-group hidden">
                  <label class="para label">Which subdivision?</label>
                  <p id="subdivision-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Place subdivision" id="subdivision" class="para msc-input" type="text" name="subdivision" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="Select">Select section ...</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="subdivision-l" class="msc-ul para"></ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 3B3) depending on place -->
    <!-- locationSector -->
                <div id="sector" class="input-group hidden">
                  <label class="para label">Which sector?</label>
                  <p id="locationSector-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Sector" id="locationSector" class="para msc-input" type="text" name="locationSector" @mousedown="${Nstat.preventSelect}" >
                      <option disabled selected value="Select">Select ...</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="locationSector-l" class="msc-ul para"></ul> 
                    </div>
                  </div>
                </div>   


                <div id="next3" class="redirect-button forum-action-btn action" @click=${this.editPlacetHandler.bind(this)}><strong>Add Place Details</strong></div>
              </div>

<!-- PERP TAB 4 -->
              <div id="tab-4" class="tab-section hidden">
                <h3 class="case-h2">Perpetrator</h3>

    <!-- perpAge -->
                <div class="input-group">
                  <label class="para label">What was the age group of the perpetrator?</label>
                  <p id="perpAge-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Age" id="perpAge" class="para msc-input" type="text" name="perpAge" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.perpetrator.perpAge}">${this.mycase.perpetrator.perpAge}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="perpAge-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "perpAge" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

    <!-- perpGender -->
                <div class="input-group">
                  <label class="para label">What was their gender?</label>
                  <p id="perpGender-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Gender" id="perpGender" class="para msc-input" type="text" name="perpGender" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.perpetrator.perpGender}">${this.mycase.perpetrator.perpGender}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="perpGender-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "perpGender" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

  <!-- CONDITION 4A2) ... depending on place -->
    <!-- perpPosition -->
                <div class="input-group">
                  <label class="para label">What was their position?</label>
                  <p id="perpPosition-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Position" id="perpPosition" class="para msc-input" type="text" name="perpPosition" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.perpetrator.perpPosition}">${this.mycase.perpetrator.perpPosition}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="perpPosition-l" class="msc-ul para"></ul> 
                    </div>
                  </div>
                </div>

    <!-- perpKnown -->
                <div class="input-group"> <!-- perpKnown -->
                  <label class="para label">Were they known to you?</label>
                  <p id="perpKnown-wrn" class="input-require">* Selection required</p>
                  <div class="msc-wrap" @click="${Nstat.mcsOpenMenu.bind(Nstat)}">
                    <select title="Familiarity" id="perpKnown" class="para msc-input" type="text" name="perpKnown" @mousedown="${Nstat.preventSelect}">
                      <option disabled selected value="${this.mycase.perpetrator.perpKnown}">${this.mycase.perpetrator.perpKnown}</option>
                    </select>
                  </div>
                  <div class="msc-wrap hidden">
                    <div class="msc-list-box">
                      <ul id="perpKnown-l" class="msc-ul para">
                        ${Data.statisticsOptions.find(field => field.fieldName == "perpKnown" )
                          .options
                          .map(option => html `
                            <li><p @click="${Nstat.mcsSelectOption.bind(Nstat)}">${option}</p></li>
                          `)}
                      </ul> 
                    </div>
                  </div>
                </div>

                <div id="next4" class="redirect-button forum-action-btn action" @click=${this.editPerpHandler.bind(this)}><strong>Add Perpetrator Details</strong></div>
              </div> <!-- end tabs -->
            </form>      
          `} <!-- END MYCASE CONDITION -->         
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }

}

export default new EditCaseView()