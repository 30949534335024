import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'

//remove spaces from links and add css code to allow linebreaks (see posts - username styles)
// .word-wrap-all li { word-break:break-all; }
class BibliographyView { 
  init(){    
    document.title = 'Bibliography'  
    this.render()  
    Utils.initFont()  
  }


  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Bibliography"></main-app-header>
        <div class="content-panel">     
          <section>

            <ul class="para word-wrap-all">
              <li>
              Alexa. 2015. “A Girl’s Guide to Staying Safe in College” https://theyounghopeful.wordpress.com/2015/12/27/a-girls-guide-to-staying-safe-in-college/
              </li>

              <li>
              Amaze Org. 2020. “Sexual Assault, Consent and Sexual Harassment: What's The Difference?” https://www.youtube.com/watch?v=4qWt2qZ59w8
              </li>

              <li>
              Avert. 2021. “Sexual Consent” https://www.avert.org/sex-stis/consent
              </li>

              <li>
              Bessel van der Kolk. 2021. “6 ways to heal trauma without medication”  https://www.youtube.com/watch?v=ZoZT8-HqI64
              </li>

              <li>
              Crappy Childhood Fairy. 2018. “Most CPTSD Treatments Don't Work. Here's What Does.”  https://www.youtube.com/watch?v=GXSlAfoJiAg
              </li>

              <li>
              DDM Global. 2015 “10 Examples of Sexual Harassment in the Workplace.” http://pauluslawfirm.com/10-examples-of-sexual-harassment-in-the-workplace/
              </li>

              <li>
              Government of Canada. 2022. “Sexual Assault.” https://www.laws-lois.justice.gc.ca/eng/acts/C-46/section-271.html
              </li>

              <li>
              Department of Defense Education Activity. 2018. “Examples of Sexual Harassment.” https://www.dodea.edu/sexualHarassment/Examples-of-Sexual-Harassment.cfm
              </li>

              <li>
              Greek Reporter. 2021. “Revenge Porn Makes Ugly Appearance in Greece.” https://greekreporter.com/2021/12/15/revenge-porn-greece/ 
              </li>

              <li>
              Hawaii Community College. n.d. “Ways to reduce your Risk of Sexual Assault” https://hawaii.hawaii.edu/sites/default/files/assets/security/docs/sexualassault_prevention.pdf
              </li>

              <li>
              Health Line. n.d. “8 Self-Defense Moves Every Woman Needs to Know.” https://www.healthline.com/health/womens-health/self-defense-tips-escape
              </li>

              <li>
              Health Navigator. 2020. “Sexual Assault” https://www.healthnavigator.org.nz/health-a-z/s/sexual-assault/?tab=13923
              </li>

              <li>
              Hormone Health Network. n.d. “What is Adrenaline?.” https://www.hormone.org/your-health-and-hormones/glands-and-hormones-a-to-z/hormones/adrenaline
              </li>

              <li>
              Hopper, Jim. 2018. “Freezing during Sexual Assault and Harassment.” https://www.psychologytoday.com/us/blog/sexual-assault-and-the-brain/201804/freezing-during-sexual-assault-and-harassment
              </li>

              <li>
              Hunt, Dr Phoebe. 2021.  “Consent.” https://www.healthnavigator.org.nz/health-a-z/c/consent/
              </li>

              <li>
              Kuadli, Jenifer. 2021. “32 Shocking Sexual Assault Statistics for 2021.”https://legaljobs.io/blog/sexual-assault-statistics/
              </li>

              <li>
              Mayo Clinic. 2017 “Dissociative disorders.” https://www.mayoclinic.org/diseases-conditions/dissociative-disorders/symptoms-causes/syc-20355215
              </li>

              <li>
              Med Circle. 2021 “6 Hidden Signs of Complex PTSD (cPTSD) | MedCircle” https://www.youtube.com/watch?v=44hqDT7NNHU
              </li>

              <li>
              Morton, Kati. 2017. What is Consent, Assault & Harassment? https://www.youtube.com/watch?v=B-ltH7RIpWU
              </li>

              <li>
              National Sexual Violence Resource Center (NSVRC). n.d. “About Sexual Assault.” https://www.nsvrc.org/about-sexual-assault
              </li>

              <li>
              Pennsylvania Coalition against Rape. 2013.  “A Guide for Friends & Family of Sexual Violence Survivors” 
              </li>

              <li>
              Psychology Today. n.d. “Dissociation” https://www.psychologytoday.com/us/basics/dissociation
              </li>

              <li>
              RAINN. n.d. “Warning Signs for Young Children” https://www.rainn.org/articles/warning-signs-young-children
              </li>

              <li>
              Rape Crisis. Nd. “What is sexual consent?” https://rapecrisis.org.uk/get-informed/about-sexual-violence/sexual-consent/
              </li>

              <li>
              Sheppard, Sarah. 2030. “Understanding Rape and Sexual Assault” https://www.verywellmind.com/what-is-sexual-assault-4844451
              </li>

              <li>
              Tavss Fletcher. n.d. “What Does The Term “Sexual Assault “ Mean, And How Broad Is The Definition?.” https://www.tavss.com/faqs/defining-and-classifying-levels-of-sexual-assault.cfm  
              </li>

              <li>
              Therapy in a Nutshell. 2019. “The Freeze Response and Sexual Assault: PTSD and Trauma Recovery #2” https://www.youtube.com/watch?v=pes7H4ECTdw  
              </li>

              <li>
              The School of Life. 2020. “12 signs you might be suffering from PTSD” https://www.youtube.com/watch?v=qOibW5LXt3w
              </li>

              <li>
              Veterans Health Administration. 2014. “Veterans Health Administration” https://www.youtube.com/watch?v=FeLLt39DI8A
              </li>

              <li>
              Walker, Justin. 2018. “10 Examples of Sexual Harassment That You Didn’t Realize Were Sexual Harassment.” https://walkerlawsd.com/10-examples-of-sexual-harassment-that-you-didnt-realize-were-sexual-harassment/
              </li>

              <li>
              World Health Organisation. 2021. “Devastatingly pervasive: 1 in 3 women globally experience violence.” https://www.who.int/news/item/09-03-2021-devastatingly-pervasive-1-in-3-women-globally-experience-violence
              </li>
            </ul> 

          </section>
        </div>  
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new BibliographyView() 