import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class AssaultView { 
  init(){    
    document.title = 'Sexual Assault'  
    this.currentlesson = 5 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }


  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/5-punch.svg"> 
          </div>
          <section class="lesson-section">
            <p class="app-info para">
              As mentioned in the introduction, sexual assault can take many forms.  
              A few broad categories include:
              <ol class="para">
                <li>Averted Sexual Assault</li>
                <li>Obvious or Hidden Assault</li>
                <li>Major Sexual Assault</li>
                <li>One Time Abuse vs. Ongoing Abuse</li>
              </ol> 
            </p>

            <h2 class="para">Averted Sexual Assault</h2>

            <p class="app-info para">
              Averted Sexual Assault is where the intended victim of sexual assault escapes, repels or distracts the “would be” perpetrator from major sexual assault. 
              Sometimes, the actual assault did not take place, in which case it was likely that the incident was preceded by sexual harassment. 
              Or the sexual assault was limited to a brief grope or grab.
            </p>

            <p class="app-info para">
              A few examples of “Averted Sexual Assault” are where intended victims… 
              <ol class="para">
                <li>Felt fear and ran to safety</li>
                <li>Called for nearby help</li>
                <li>Distracted the attacker</li>
                <li>Used their phone’s emergency call to alert the Police. 
                  A woman used her phones emergency feature to patch her through to emergency services, who got her GPS location and sent police to the scene.
                  </li>
                <li>Hitting the attacker in a vulnerable area allowing time to escape (such as the groin, throat, nose, eyes and even scraping their shin with sharp heels)</li>
              </ol> 
            </p>

            <p class="app-info para">
              Taking self-defence courses can equip people with the skills they need to protect themselves from an attack. 
            </p>

            <p class="app-info para notes">
              Our statistics consider “Averted Sexual Assault” as a category where the assault was prevented as opposed to “Sexual Assault” where an offence takes place. 
            </p>


            <h2 class="para">Obvious or Hidden Assault</h2>

            <p class="app-info para">
              Sexual assault can either be obvious and clear or hidden.  
              In the event the sexual assault is obvious, it is generally very clear to the survivor exactly what is taking place.  
              Obvious unwanted sex acts like grabbing, groping, touching or threatening to show private parts are immediately recognised for what they are.  
              However, when it is hidden and made to appear like an accident or it is disguised by another action, it can take the survivor a little more time to recognise the assault.  
              In some cases, hidden assault can even go unnoticed.
            </p>

            <p class="app-info para">
              Unnoticed hidden assault might be where a doctor or nurse disguises the assault as a normal medical procedure.  
              A midwife might tell a new mother that a full breast check-up or an internal examination is required. 
              Another common example is where a doctor tells his patient to remove their top / bra to use the stethoscope to check their breathing. 
              Although in some case the survivor doesn’t realize what is taking place, it is still sexual assault.
            </p>

            <p class="app-info para">
              Less obvious sexual assault includes acts like looking at ones private parts.  
              This may occur by someone looking up a skirt, down a shirt or catching glances in a change room.  
              It is also sexual assault when someone is forced or coerced to show their private parts, whether in person or images.  
              In the event that images are later shared, this is referred to as revenge porn and is a cause of suicide.
            </p>

            <p class="app-info para">
              Hidden assault may begin as a normal procedure such as an internal examination or a massage.  
              The perpetrator pretends not to notice they are stimulating the patient or customer and in many incidents the survivor freezes and is unable to stop the assault.
            </p>


            <p class="app-info para">
              As mentioned, if any unwanted sex act extends “one second” (It takes one second to say “one second” so sound it out in your head), then it is time to stop or avert the assault if you are able.  
              If anyone touches you doing an unwanted sexual act, then do you what you can to exit from the situation if you can.  
              Call out for help, say you feel sick and want to vomit, say it is hurting you or try to get away.
            </p>


            <h2 class="para">Major Sexual Assault</h2>

            <p class="app-info para">
              Major Sexual Assault is an incident of sexual assault which causes trauma and severe distress. 
              Rape (involving penetration of any type), prolonged stimulation (sometimes resulting in an orgasm), and revenge porn are examples of major sexual assault.  
              Rape is an example of obvious sexual assault. 
              And an internal medical examination with external stimulation is an example of hidden sexual assault. 
            </p>

            <p class="app-info para">
              Major sexual can result in an orgasm as the body reacts to physical stimulation. 
              It is important for survivors to understand that it is a natural reaction and not that their body has betrayed them. 
              This reaction would be likened to the “knee jerk” reaction like when a doctor tests someone’s reflexes.  
              It is not intentional and cannot be controlled.
            </p>

            <p class="app-info para">
              If you are physically able, it is recommended to try to deter, fight back and in particular leave scratches or bite marks as identifiers for when trying to take the perpetrator to court. 
              However, for many women the shame is so great that the thought of recounting the event to others is almost more unbearable than the attack itself.
            </p>


            <h2 class="para">One Time Abuse vs Ongoing Abuse</h2>

            <p class="app-info para">
              The previous examples in “major sexual assault” are generally examples of one time abuse. 
              Rape takes on various forms including date rape, gang rape and incidents of prickings or spiked drinks reported from club outings.  
              Revenge porn (exposing images or videos) is also another “one time” incident, however, the impact can often be much more devastating and prolonged.  
              In fact it is reported that revenge porn impacts survivors the hardest. 
              You are not alone! 
              Unfortunately too many people have been affected and we can get through it. 
              We are survivors. 
            </p>

            <p class="app-info para">
              Ongoing abuse on the other hand, is where the survivor is trapped in a cycle of abuse on a repetitive basis.  
              This may be in the form of spouse assault or rape. 
              Sex slavery is also ongoing abuse, where a person is trapped in a location or restricted by the confiscation of passports etc. 
              Coercion of sexual acts is also ongoing abuse if it is repeated and the survivor feels compelled or forced.  
              Coercion could occur by a perpetrator threatening to expose images, personal information or threaten to harm someone or their position or reputation etc.  
              Sometimes breaking out of a cycle of ongoing abuse can seem impossible.  
              But you are a survivor and you have the courage.  
              You can do it!
            </p>

            <p class="app-info para">
              In the event of revenge porn and ongoing coercive abuse, deleting social media accounts for a period of time can block a great deal of the harm.  
              If necessary, changing schools, the place of work or even relocating can help to break out of the cycle.  
              Sometimes it is just the thought of the humiliation that we are unable to deal with. 
              But we are survivors. 
              We can do it!
            </p>

           </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new AssaultView() 