import App from '../../../App'
import {render, html} from 'lit/html.js'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'
import Validate from '../../../Validate'
import ThreadAPI from '../../../api/ThreadAPI'


class NewthreadView {
  init(){    
    document.title = 'Add New Thread'    
    this.subTopic = null
    this.topic = null
    this.other = false 
    this.formready = false
    this.render() 
    this.subtopicInit()
    Utils.initFont()  
    this.topicinput = document.getElementById('topic')
    if(this.topicinput !== null) {
      this.topicinput.onchange = this.handleTopicSelect.bind(this)
    }
    this.subtopicinput = document.getElementById('subtopic')
    if(this.subtopicinput !== null) {
      this.subtopicinput.onchange = this.handleSubtopicSelect.bind(this)
    }
  }


async subtopicInit(){
  if(Data.mysubtopic == null) {
    this.formready = true
  } else {
    this.subTopic = Data.mysubtopic
    this.formready = true
    this.hasWord(Data.mysubtitle)
  }
  this.render() 
}


async newThreadHandler(){
  let validated = true // check all inputs are valid
  let newThread = {}

  const topicinput = document.getElementById("topic")
  const subtopicinput = document.getElementById("subtopic")

  const otherinput = document.getElementById("other")
  const subjectinput = document.getElementById("subject")
  const postinput = document.getElementById("post")
  const subCheck = document.getElementById("sub-check")

  if(Data.mysubtopic == null) {
    if(this.topic == null){
      Validate.inputWarn(topicinput.id, "*Selection required")
      validated = false
    }
    if(subtopicinput.value.includes('placeholder')){
      Validate.inputWarn(subtopicinput.id, "*Selection required")
      validated = false
    }
    if (otherinput !== null && otherinput.value !== "") {
      const otherStatus = Validate.safeCheck(otherinput.value)
      if (otherStatus == false){
        Validate.inputWarn(otherinput.id, "*No special characters")
        validated = false
      } else if (otherinput.value.length < 4 || otherinput.value.length > 50 ){
        Validate.inputWarn(otherinput.id, "*Enter 4 to 50 characters")
        validated = false
      } 
    }
  }

  if (subjectinput.value == "") {
    Validate.inputWarn(subjectinput.id, "*Required")
    validated = false
  } else {
    const sbStatus = Validate.safeCheck(subjectinput.value)
    if (sbStatus == false){
      Validate.inputWarn(subjectinput.id, "*No special characters")
      validated = false
    } else if (subjectinput.value.length < 4 || subjectinput.value.length > 50 ){
      Validate.inputWarn(subjectinput.id, "*Enter 4 to 50 characters")
      validated = false
    } 
  }

  if (postinput.value == "") {
    Validate.inputWarn(postinput.id, "*Required")
    validated = false
  } else {
    const pStatus = Validate.safeCheck(postinput.value)
    if (pStatus == false){
      Validate.inputWarn(postinput.id, "*No special characters")
      validated = false
    } else if (postinput.value.length < 8 || postinput.value.length > 3000 ){
      Validate.inputWarn(postinput.id, "*Enter 8 to 3000 characters")
      validated = false
    } 
  }

  if(validated == false){ return }
  newThread.user = Auth.currentUser._id

  if(Data.mysubtopic == null) {
    newThread.subtopicId = subtopicinput.value
  } else {
    newThread.subtopicId = Data.mysubtopic
  }
  
  if(this.other == true && otherinput.value !== ""){
    newThread.otherDesc = otherinput.value
  }
  newThread.threadTitle = subjectinput.value
  newThread.threadText = JSON.stringify(postinput.value)
  if(subCheck.classList.contains('select-active')){
    newThread.subscribers = []
    newThread.subscribers.push(Auth.currentUser.email) 
  }

  if(validated == true) { 
    /*
    const mainTab = document.getElementById('main-tab')
    const statusTab = document.getElementById('status-tab')
    mainTab.classList.add('hidden')
    statusTab.innerHTML = '<c-waiting waitSource="forum-thread"></c-waiting>'
    */
    Utils.confirmModal('submit','newthread', 'wait')
  
    try {
      const threadObject = JSON.stringify(newThread);
      const registered = await ThreadAPI.newThread(Auth.currentUser.usergroup, threadObject)
      if(registered == false){
        //statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
        Utils.confirmModal('submit','newthread', 'error')
      } else { 
        //statusTab.innerHTML = '<c-success successSource="thread"></c-success>'
        Utils.confirmModal('submit','newthread', 'success')
      }
    }catch(err){
      //statusTab.innerHTML = '<c-error errorSource="goback"></c-error>'
      Utils.confirmModal('submit','newthread', 'error')
    }
  }
}


handleTopicSelect(e){
  const newTopic = e.target.value
  if(this.topic == newTopic){
    return 
  } else {
    this.topic = newTopic
    const subtopicSelect = document.querySelector("#subtopic")
    subtopicSelect.disabled = false
    subtopicSelect.value = 'placeholder'
    this.other = false
  }
  this.render() 
}

hasWord(str){
  const otherInput = document.getElementById("other-input")
  const lowerCaseString = str.toLowerCase()
  const hasWord = lowerCaseString.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "").split(/\s+/).includes("other")
  if(hasWord == true){
    this.other = true
    otherInput.classList.remove('hidden');
    Utils.initFont()
  } else {
    this.other = false
    otherInput.classList.add('hidden');
  }
}

//here add condition to hide the "other field" 
handleSubtopicSelect(e){
  const selectedSubtopic = e.target
  const subtopicText = selectedSubtopic.options[selectedSubtopic.selectedIndex].text
  this.hasWord(subtopicText)
}

subSelect(e){
  const selectnew = e.target.closest('.select-item')
  selectnew.classList.toggle('select-active')
}

  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">  
          <div id="main-tab"> 
          
            ${this.formready == false? html`
              <br><br>
              <div class="spinner sp-sml"></div> 
            `: html`

              ${this.subTopic == null? html`
                <div class="input-group">
                  <p id="topic-wrn" class="input-require">*Selection required</p>
                  <select class="para" name="topicId" id="topic" @click="${Validate.clearWarning.bind(Validate)}">
                    <option value="" disabled selected hidden>Select Topic...</option>
                    ${Data.topics.map(topic => html `<option value="${topic.topicId}" @click="${this.handleTopicSelect.bind(this)}">${topic.topicTitle}</option> `)}
                  </select>
                </div>
              `:html``}

              <form id="new-form" method="post">
                <input  id="user-i" type="hidden" name="user" value="${Auth.currentUser._id}" />
                ${this.subTopic == null? html`


                  <div class="input-group">
                    <p id="subtopic-wrn" class="input-require">*Selection required</p>
                    <select class="para" name="subtopicId" id="subtopic" disabled  @click="${Validate.clearWarning.bind(Validate)}">
                      <option value="placeholder" disabled selected hidden>Select Subtopic...</option>
                      ${Data.subTopics
                        .filter(subtopic => subtopic.topicId == this.topic )
                        .map(subtopic => html ` 
                        <option value="${subtopic.subtopicId}" @click="${this.handleSubtopicSelect.bind(this)}">${subtopic.subtopicTitle}</option>
                      `)}
                    </select>
                  </div>

                `:html`
                  <input id="subtopic-i" type="hidden" name="subtopicId" value="${this.subTopic}" />
                `}

                  <div id="other-input" class="input-group hidden">
                  <p id="other-wrn" class="input-require">*No special characters & max 50 </p>
                    <textarea id="other" class="para" rows="1" name="otherText" placeholder="Description (optional)" maxlength="50"></textarea>
                  </div>


                <div class="input-group">
                  <p id="subject-wrn" class="input-require">*No special characters & max 50 </p>
                  <input id="subject" class="para" name="threadTitle" placeholder="Thread title..." @keyup=${Validate.clearWarning.bind(Validate)} maxlength="50">
                </div>
                <div class="input-group">
                  <p id="post-wrn" class="input-require">*No special characters</p>
                  <textarea id="post" class="para" rows="6" name="threadText" placeholder="First post text..." @keyup=${Validate.clearWarning.bind(Validate)} maxlength="3000"></textarea>
                </div>

                <div class="agree-wrap">
                  <div id="sub-check" class="select-item para select-active" @click="${this.subSelect}">
                    I would like to recieve emails for new posts
                  <div class="s-point"></div></div>
                </div>

                <div class="divide-line"></div>

                <div title="submit button" class="redirect-button forum-action-btn action" @click=${this.newThreadHandler.bind(this)}><strong>Create Thread</strong></div>
              </form>
            `}
          </div>
          <div id="status-tab"></div>
        </div> 
      </div> 
    `
    render(template, App.rootEl)
  }
}

export default new NewthreadView()