[{
  "topicTitle": "Healing Strategies", 
  "topicId": "Tt01940c4c1d"
},{
  "topicTitle": "Shared Experiences", 
  "topicId": "Tt02191c2864"
},{
  "topicTitle": "Parents and Guardians’ Corner", 
  "topicId": "Tt0359476ee3"
},{
  "topicTitle": "General Discussions", 
  "topicId": "Tt04fda37fb3"
}]