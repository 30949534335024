[{
  "category": "Survivor",
  "fields": [{
    "fieldName": "survivorAge",
    "btnName": "Age"
  },{
    "fieldName": "survivorGender",
    "btnName": "Gender"
  },{
    "fieldName": "survivorDisability",
    "btnName": "Disability"
  },{
    "fieldName": "survivorImpact",
    "btnName": "Impact"
  }]
},{
  "category": "Incident",
  "fields": [{
    "fieldName": "caseYear",
    "btnName": "Year"
  },{
    "fieldName": "caseReported",
    "btnName": "Reported"
  },{
    "fieldName": "caseFrequency",
    "btnName": "Frequency"
  },{
    "fieldName": "caseType",
    "btnName": "Type"
  },{
    "fieldName": "caseReaction",
    "btnName": "Response"
  }]
},{
  "category": "Place",
  "fields": [{
    "fieldName": "caseCountry",
    "btnName": "Country"
  },{
    "fieldName": "caseLocation",
    "btnName": "Location"
  }]
},{
  "category": "Perpetrator",
  "fields": [{
    "fieldName": "perpAge",
    "btnName": "Age"
  },{
    "fieldName": "perpPosition",
    "btnName": "Position"
  },{
    "fieldName": "perpKnown",
    "btnName": "Familiarity"
  },{
    "fieldName": "perpGender",
    "btnName": "Gender"
  }]
}]
