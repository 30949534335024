import {LitElement, html} from 'lit'
import Data from '../../Data' // for app variables
import Utils from '../../Utils' 

customElements.define('mi-privacy-policy', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: { type: String }
    }
  }

  firstUpdated(){
    super.firstUpdated()
    this.fontUpdate()
  }

  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = Utils.myfont +'em';
    })
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }
        .mi-box { font-size: 1.2em; max-height: 80vh; max-width: 600px; overflow-y: scroll;  margin-top: 20px; } 
        .mi-text { margin-bottom: 30px;  width: 250px; }
        p { text-align: left; }
        .app-info { margin: 10px 0; }
        .policy-img { width: 250px; margin: auto; }
        h2, h3, h4, h5 { margin-top: 15px; margin-bottom: 7px; }
        h4, h5 { text-align: left; }

        ::-webkit-scrollbar {width: 15px;}
        ::-webkit-scrollbar-track {
          background: var(--bginfo-color);
          border-radius: 500px;
        }
        ::-webkit-scrollbar-thumb {
          background: var(--brand-color);
          border-radius: 500px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: var(--two-color);
        }

        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }
          



      </style>
      <div class="mi-box para">
        <h2>Forum Rules</h2>
        <h3 class="h-center">${Data.apptitle} PRIVACY POLICY</h3>
        <div class="h-center">Last updated: 30th April 2022 </div>
        <div class="h-center">
          <img class="policy-img" src="./../images/icons/privacy.svg" alt="Privacy Policy Badge">
        </div>
        <p class="app-info">
          ${Data.apptitle} respects the privacy of its users.
        </p> 
        <p class="app-info">
          This Privacy Policy explains how ${Data.apptitle} collects, uses, discloses, and safeguards your information when you visit ${Data.apptitle}. 
        </p>

        <p class="app-info">
          Please read this Privacy Policy carefully. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO NOT ACCESS THE APPLICATION.
        </p>
            
        <p class="app-info">
          ${Data.apptitle} reserves the right to make changes to this Privacy Policy at any time and for any reason. 
          ${Data.apptitle} will alert you about any changes by updating the “Last updated” date of this Privacy Policy. 
          You are encouraged to periodically review this Privacy Policy to stay informed of updates. 
          You will be deemed to have been made aware of, will be subject to, 
          and will be deemed to have accepted the changes in any revised Privacy Policy by your continued use of the Application after the date such revised Privacy Policy is posted.
        </p>
        <p class="app-info">
          This Privacy Policy does not apply to the third-party online/mobile store from which you install the Application or make payments, which may also collect and use data about you. 
          ${Data.apptitle} is not responsible for any of the data collected by any such third party.
        </p>
        <p class="app-info"><i>
          *This Privacy Policy was created using Termly’s Privacy Policy Generator. 
        </i></p>


        <h3>COLLECTION OF YOUR INFORMATION </h3>
        <p class="app-info">
          ${Data.apptitle} may collect information about you in a variety of ways. 
          The information ${Data.apptitle} may collect via the Application depends on the content and materials you use, and includes:
        </p>

        <h4>Personal Data</h4> 
        <p class="app-info">
          Demographic and other personally identifiable information (such as your email address, country and state) that you give to us when choosing to participate in various activities related to the Application, such as chat, posting messages in comment sections or in our forums, liking posts, sending feedback, and responding to surveys. 
          If you choose to share data about yourself via your profile, online chat, or other interactive areas of the Application, please be advised that all data you disclose in these areas is public and your data will be accessible to anyone who accesses the Application.
        </p> 

        <h4>Derivative Data</h4> 
        <p class="app-info">
          Information our servers automatically collect when you access the Application, such as your native actions that are integral to the Application, including liking, re-blogging, or replying to a post, as well as other interactions with the Application and other users via server log files.
        </p>


        <h3>USE OF YOUR INFORMATION</h3>
        <p class="app-info">
          Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, ${Data.apptitle} may use information collected about you via the Application to: 
        </p>
        <p>
          - Create and manage your account.
        </p>
        <p>
          - Provide user features within the application such as forum posts.
        </p>

        <h3>DISCLOSURE OF YOUR INFORMATION</h3>
        <p class="app-info">
          Although we provide an anonymous chat forum, ${Data.apptitle} may share information it has collected about you in certain situations. 
          Your information may be disclosed as follows:
        </p>

        <h4>By Law or to Protect Rights</h4>
        <p class="app-info">
          If we believe the release of information about you is necessary to respond to legal process, 
          to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, 
          we may share your information as permitted or required by any applicable law, rule, or regulation. 
          This includes exchanging information with other entities for fraud protection and credit risk reduction.
        </p>

        <h4>Third-Party Service Providers</h4>
        <p class="app-info">
          We may share your information with third parties that perform services for us or on our behalf, 
          including payment processing and hosting services.
        </p>

        <h4>Online Postings</h4>
        <p class="app-info">When you post comments, contributions or other content to the Applications, your posts may be viewed by all users 
          and may be publicly distributed outside the Application in perpetuity</p>

        <h4>Affiliates</h4>
        <p class="app-info">
          We may share your information with our affiliates, in which case we will require those affiliates to honor this Privacy Policy. 
          Affiliates include any subsidiaries, joint venture partners or other companies that we control or that are under common control with us.
        </p>

        <h4>Other Third Parties</h4>
        <p class="app-info">
          We may share your information with third parties for the purpose of conducting general business analysis.
        </p>

        <h4>Sale or Bankruptcy</h4>
        <p class="app-info">
          If we reorganize or sell all or a portion of our assets, undergo a merger, or are acquired by another entity, 
          we may transfer your information to the successor entity. If we go out of business or enter bankruptcy, 
          your information would be an asset transferred or acquired by a third party. 
          You acknowledge that such transfers may occur and that the transferee may decline honor commitments we made in this Privacy Policy.
        </p>

        <p class="app-info">
          We are not responsible for the actions of third parties with whom you share personal or sensitive data, 
          and we have no authority to manage or control third-party solicitations. 
          If you no longer wish to receive correspondence, emails or other communications from third parties, 
          you are responsible for contacting the third party directly.
        </p>

        <h3>TRACKING TECHNOLOGIES</h3>
        <h4>Cookies and Web Beacons</h4>
        <p class="app-info">
          We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Application to help customize the 
          Application and improve your experience. When you access the Application, your personal information is not collected through the use of tracking technology. 
          Most browsers are set to accept cookies by default. You can remove or reject cookies, 
          but be aware that such action could affect the availability and functionality of the Application. 
          You may not decline web beacons. However, they can be rendered ineffective by declining all cookies 
          or by modifying your web browser’s settings to notify you each time a cookie is tendered, 
          permitting you to accept or decline cookies on an individual basis.
        </p>

        <h3>THIRD-PARTY WEBSITES</h3>
        <p class="app-info">
          The Application may contain links to third-party websites and applications of interest, including payment services and external services, 
          that are not affiliated with us. Once you have used these links to leave the Application, 
          any information you provide to these third parties is not covered by this Privacy Policy, 
          and we cannot guarantee the safety and privacy of your information. 
          Before visiting and providing any information to any third-party websites, 
          you should inform yourself of the privacy policies and practices (if any) of the third party responsible for that website, 
          and should take those steps necessary to, in your discretion, protect the privacy of your information. 
          We are not responsible for the content or privacy and security practices and policies of any third parties, 
          including other sites, services or applications that may be linked to or from the Application.
        </p>

        <h3>SECURITY OF YOUR INFORMATION</h3>
        <p class="app-info">
          We use administrative, technical, and physical security measures to help protect your personal information. 
          While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, 
          no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse. 
          Any information disclosed online is vulnerable to interception and misuse by unauthorized parties. 
          Therefore, we cannot guarantee complete security for your personal information.
        </p>

        <h3>OPTIONS REGARDING YOUR INFORMATION</h3>
        <h4>Account Information</h4>
        <p class="app-info">
          You may update your email, however, some details cannot be changed due to our policy of "integrity of information".
          In the event you wish to change your user information, please create a new account.
        </p>

        <h4>Emails and Communications</h4>
        <p class="app-info">
          If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by logging into your account settings and updating your preferences.
        </p>
        <p class="app-info">
          If you no longer wish to receive correspondence, emails, or other communications from third parties, you are responsible for contacting the third party directly.
        </p>

        <h3>CONTACT US</h3>
        <p class="app-info">
          If you have questions or comments about this Privacy Policy, please contact ${Data.apptitle} via the feedback page (available after logging in).
        </p>

      </div>

    `
  }
  
})
