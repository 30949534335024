import App from '../../../App'
import {render, html} from 'lit/html.js'
import { navClick } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import UserApi from '../../../auth/UserApi'


class StatisticsView {
  init(){    
    document.title = 'Statistics'    
    this.render()  
  }

  async noCaseHandler(){
    let user = {}
    user.nocases = true
    try {
      const userObject = JSON.stringify(user);
      const updated = await UserApi.updateUser(Auth.currentUser._id, userObject)
      if(updated == false){
        console.log('Problem updating profile')
      } else {
        if( document.title !== 'Statistics' ){return}
        this.render()  
      }
    }catch(err){
      console.log('Catch error: Problem updating profile')
      console.log(err)
    }
  }


  refreshData(e){
    Data.statUsers = null
    Data.statCases = null
    const disableBtn = e.target.closest('nav')
    disableBtn.classList.remove('primary', 'forum-btn')
    disableBtn.classList.add('disabled')
  }

 
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>
        <div class="content-panel">  
          <br><br>

          ${Auth.currentUser.nocases == true ||  Auth.currentUser.cases >= 1 ? html`
              <nav class="home-btn primary access-stat" nav-link="/stats/usermenu" @click="${navClick}">
                <div class="hbi-box">
                  <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
                </div>
                <div class="hb-title">By Users</div>
              </nav>

              <nav class="home-btn primary access-stat" nav-link="/stats/casemenu" @click="${navClick}">
                <div class="hbi-box">
                  <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
                </div>
                <div class="hb-title">By Incidents</div>
              </nav><br>

              <nav class="forum-button forum-btn primary access-stat" @click=${this.refreshData}>
                Refresh Data
                <img class="back-img" alt="back" src="./../images/icons/sw00-back.svg">
              </nav>
            `:html`
              <nav class="home-btn disabled access-stat" nav-link="/stats/usermenu">
                <div class="hbi-box">
                  <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
                </div>
                <div class="hb-title">By Users</div>
              </nav>

              <nav class="home-btn disabled access-stat" nav-link="/stats/casemenu">
                <div class="hbi-box">
                  <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
                </div>
                <div class="hb-title">By Incidents</div>
              </nav><br>

              <nav class="forum-button forum-btn primary" @click=${this.noCaseHandler.bind(this)}>
                I have no incidents. 
              </nav>
            `}

          <br>
          <div class="divide-line"></div>

            ${Auth.currentUser.cases && Auth.currentUser.cases >= 10 ? html`
              *Limit of 10 incidents per user.
              <nav class="home-btn disabled" nav-link="/stats/newcase">
                <div class="hbi-box">
                  <img class="back-img img-padding" alt="pen" src="./../images/icons/sw04-pencil.svg">
                </div>
                <div class="hb-title">Add Incident</div>
              </nav>
            `:html`
              <nav class="home-btn action" nav-link="/stats/newcase" @click="${navClick}">
                <div class="hbi-box">
                  <img class="back-img img-padding" alt="pen" src="./../images/icons/sw04-pencil.svg">
                </div>
                <div class="hb-title">Add Incident</div>
              </nav>
            `}

        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new StatisticsView()