[{
  "lesson": 1,
  "lessonTitle": "Introduction"
},{
  "lesson": 2,
  "lessonTitle": "Consent"
},{
  "lesson": 3,
  "lessonTitle": "Responses"
},{
  "lesson": 4,
  "lessonTitle": "Sexual Harassment"
},{
  "lesson": 5,
  "lessonTitle": "Sexual Assault"
},{
  "lesson": 6,
  "lessonTitle": "What to do"
},{
  "lesson": 7,
  "lessonTitle": "Advice and Signs of Abuse"
},{
  "lesson": 8,
  "lessonTitle": "Prevention"
}]