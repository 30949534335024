import {LitElement, html} from 'lit'


customElements.define('c-spinner', class Features extends LitElement {
  constructor(){
    super()    
  } 

  firstUpdated(){
    super.firstUpdated()
  }

  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .spinner{
          border-top: 4px solid var(--brand-color);
          border-right: 4px solid transparent;
          border-radius: 50%;
          animation: rotation .8s linear infinite;
          margin: 20px auto;
          height: 70px; width: 70px;
        }

        @keyframes rotation{
          from{
            transform: rotate(0deg);
          }
          to{
            transform: rotate(360deg);
          }
        }

      </style>

      <div class="spinner"></div>

    `
  }
  
})
