import Auth from './Auth';

const countrydata = require('./data/countryflags.json');
const lessondata = require('./data/lessons.json');
const questiondata = require('./data/quizzes.json');
const leadersdata = require('./data/leaderlevels.json');
const topicsdata = require('./data/forumtopics.json');
const subtopicsdata = require('./data/forumsubtopics.json');
const statisticsFields = require('./data/casefields.json');
const statisticsOptions = require('./data/caseoptions.json');
const purchaseItems = require('./data/purchaseprices.json');

class Data {

    constructor(){
      //app details -----------------------------------------
      this.appcode = "mt"
      this.apptitle = "Hashtag Metoo"  

      //Modal text
      this.notice = null

      //purchase data
      this.itemPrices = purchaseItems //this is for displaying items & calculating amount 
      this.purchase = []
      this.purchaseCodes = []
      this.purchaseTotal = 0
      this.coCode = null

      //To prevent checkout from triggering twice
      // this.initiated = false //issue fixed
      

      //TOPICS LINK----------------------------------------------------
      //---VERSION 1
      this.forumlink = "/forum/threads"
      //---VERSION 2
  //    this.forumlink = "/forum/topics"

      //country info -----------------------------------------
      this.myCountryCode = null
      this.myCountry = null
      this.allCountries = countrydata
      this.priority = [{ "countryName": "Australia", "countryCode": "aus", "emergNumber": "000", "flag": true },
        { "countryName": "New Zealand", "countryCode": "nzl", "emergNumber": "111", "flag": true },
        { "countryName": "United Kingdom", "countryCode": "gbr", "emergNumber": "999 or 112", "flag": true },
        { "countryName": "United States of America", "countryCode": "usa", "emergNumber": "911", "flag": true},
        { "countryName": "Canada", "countryCode": "can", "emergNumber": "911", "flag": true}]


      //lesson data ------------------------------------------- 
      this.lessons = lessondata

      //Quiz data ------------------------------------------- 
      this.quizzes = questiondata 
      this.addScore = null  // this gets added to the user's score

      //leaderboard data -----------------------------------------
      this.leaders = leadersdata.sort((a, b) => b.level -a.level) //sorted from highest to lowest
      this.leaderUsers = null

      // forum data -----------------------------------------
      this.topics = topicsdata
      this.subTopics = subtopicsdata
      this.mysubtopic = null
      this.mysubtitle = null
      this.myThread = {} 
      this.myreplypost = {} 

      //stats data ----------------------------------------- 
      this.statisticsFields = statisticsFields
      this.statisticsOptions = statisticsOptions
      this.ustatField = null   
      this.statTitle = null
      this.statName = null

      this.statType = null  // "users" or "cases" - condition for which data set
      this.statUsers = null 
      this.statCases = null 

      this.colours = ["#FF9100", "#68D895", "#9F69F4", "#F487F1", "#A58C62", "#67B1F4", "#EA5C67", "#C7D685"]

      this.users = null
      this.cases = null

    } //end of constructor

//https://stackoverflow.com/questions/12623272/how-to-check-if-a-string-array-contains-one-string-in-javascript
    inArray(needle, haystack) {
      const length = haystack.length;
      for(let i = 0; i < length; i++) {
          if(haystack[i] == needle)
              return true;
      }
      return false;
    }

     initCountry(){
       if(Auth.currentUser.countryCode){ this.myCountryCode = Auth.currentUser.countryCode } 
       else { this.myCountryCode = "usa" }
       const countriesobj = this.allCountries 
       const country = countriesobj.find(country => country.countryCode == this.myCountryCode)
       this.myCountry = country
     }

     updateCountry(newId){
      const countriesobj = this.allCountries 
      const country = countriesobj.find(country => country.countryCode == newId)
      this.myCountry = country
     }

  
  }
  
  export default new Data()