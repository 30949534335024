[{
  "subtopicTitle": "Lifestyle Changes", 
  "subtopicId": "940c4c1d",
  "topicId": "Tt01940c4c1d"
},{
  "subtopicTitle": "Therapies", 
  "subtopicId": "191c2864",
  "topicId": "Tt01940c4c1d"
},{
  "subtopicTitle": "Other Healing Topic", 
  "subtopicId": "59476ee3",
  "topicId": "Tt01940c4c1d"
},{
  "subtopicTitle": "Ongoing Abuse", 
  "subtopicId": "fda37fb3",
  "topicId": "Tt02191c2864"
},{
  "subtopicTitle": "One Time Abuse", 
  "subtopicId": "38d581a1",
  "topicId": "Tt02191c2864"
},{
  "subtopicTitle": "Shared Personal Images (revenge porn)",
  "subtopicId": "0436f976",
  "topicId": "Tt02191c2864"
},{
  "subtopicTitle": "Home Safety", 
  "subtopicId": "940c191c",
  "topicId": "Tt0359476ee3"
},{
  "subtopicTitle": "School Issues", 
  "subtopicId": "28645947",
  "topicId": "Tt0359476ee3"
},{
  "subtopicTitle": "Online Predators", 
  "subtopicId": "6ee3fda3",
  "topicId": "Tt0359476ee3"
},{
  "subtopicTitle": "Other Parents' Topic", 
  "subtopicId": "7fb338d5",
  "topicId": "Tt0359476ee3"
},{
  "subtopicTitle": "Lessons", 
  "subtopicId": "81a10436",
  "topicId": "Tt04fda37fb3"
},{
  "subtopicTitle": "Leader boards", 
  "subtopicId": "f9764c1d",
  "topicId": "Tt04fda37fb3"
},{
  "subtopicTitle": "Other General Topic", 
  "subtopicId": "4c191c1d",
  "topicId": "Tt04fda37fb3"
}]
