import App from '../App'
import Toast from '../Toast'


class SubscribeAPI {

    //subscribeData =
      //getting via backend --- emails: [array],  
      //getting via backend --- title:  [threadTitle],  
      //message:  [postText] 
      //group:  
      //threadId:   
      //thisUserEmail: (so we don't send)


//call function via /sign/token-validation  ---  "unsubscribe"
  async unsubscribe(token, fail = false){
    try{
      const response = await fetch(`${App.apiBase}/subscribe`, { 
        method: 'PUT', headers: { "Authorization": `Bearer ` + token } 
      })
      if(!response.ok){      
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      Toast.show(data.message)
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }
    
}

export default new SubscribeAPI()
