import {LitElement, html} from 'lit'
import Utils from '../../Utils' 

customElements.define('mi-forum-rules', class AppHeader extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: {
        type: String
      }
    }
  }


  firstUpdated(){
    super.firstUpdated()
    this.fontUpdate()
  }
  

  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => {
      fontPref.style.fontSize = Utils.myfont +'em';
    })
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .mi-box { max-height: 80vh;
          max-width: 600px;
          overflow-y: scroll; 
          margin-top: 20px; }
        .mi-text { margin-bottom: 30px;  width: 250px;  }
        .para { text-align: left; font-size: 1.2em;  }
        .app-info { margin: 10px; }
        .warning-text { color: red; }

        ::-webkit-scrollbar {width: 15px;}
        ::-webkit-scrollbar-track {
          background: var(--bginfo-color);
          border-radius: 500px;
        }
        ::-webkit-scrollbar-thumb {
          background: var(--brand-color);
          border-radius: 500px;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: var(--two-color);
        }

        @media all and (max-width: 350px){
          .mi-text { width: 200px; }
        }
          
      </style>
      <div class="mi-box">
        <h2>Forum Rules</h2>
        <p class="app-info para"><i>
          To protect users’ safety and wellbeing, our moderators ensure all posts comply with the following guidelines:
          </i></p>

        <p class="app-info para">
          <strong>1. Posts and responses must be respectful and supportive: </strong>
          Many users may be dealing with trauma so we need to ensure that all communication supports the healing process.
        </p>

        <p class="app-info para">
          <strong>2. No sharing personal details: </strong>
          Statistics show that many youth are vulnerable to online predators.  
          Therefore, prohibiting exchange of contact and personal information will prevent online predators from targeting our users.
          Links are also not allowed to ensure our users stay safe.
        </p>

        <p class="app-info para">
          <strong>3. No curse words: </strong>
          We want to create a positive place for our users. A place where everyone feels respected and part of a caring community.
        </p>

        <p class="app-info para">
          <strong>4. No specifying people: </strong>
          This a place for healing and not reporting crimes.  If you need to report an incident, please contact your local police.
        </p>

        <p class="app-info para">
          <strong>5. Help each other: </strong>
          Our chat forum is a place where users can seek support, share and help each other.
          <br>
          *Please note that we do not provide counselling services. 
          If you need counselling services, please contact your relevant local services.
        </p>

        <p class="app-info para">
          <strong>6. Integrity of information: </strong>
          Due to the fact that some users may be emotionally distraught, it is not permissible to 
          edit User Names or post content etc.  This will create a stable and secure environment for all users.  
        </p>

        <p class="app-info para">
          <strong>7. No preaching: </strong>
          Although faith has been known to benefit some survivors, preachers from different factions have been known 
          to get into heated discussions and this does not support the healing process.
          Therefore, anything other than the term "God" will be censored.
        </p>

        <p class="app-info para">
          <strong>8. No blaming: </strong>
          Blaming will not benefit any user and may increase some users' distress.  
          We all make mistakes at some point during our lives, but regardless of what someone does, they never deserve to be sexually abused in any form.
        </p>

        <p class="app-info para">
          <i> Posts which go against the rules will be censored ####.</i>
        </p>

        <p class="app-info para warning-text">
          <strong>Warning: </strong>
          Some posts may contain discussions of self-harm.  
          We need to be supportive toward other users and help guide them to positive behaviours.
        </p>

      </div>
    `
  }
  
})
