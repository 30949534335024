import {LitElement, html} from 'lit'
import {gotoRoute} from '../../Router'
import Data from '../../Data' // for app variables


customElements.define('forum-thread', class ForumComponents extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      thread: { type: Object },
      user: { type: Object },
      typeInfo: { type: String },
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
  }
  
  openPost(){
    Data.myThread = this.thread
		gotoRoute('/forum/posts?id=' + Data.myThread._id)
  }
  
//   <div class="fc-info">
  //   ${JSON.stringify(this.thread.user.userName)} <br>
  //   <div class="date">${new Date(this.thread.createdAt).toLocaleDateString()}</div>
  // </div>

  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }
      .fc-thread { 
        color: white; margin: 10px ; border-radius: 5px;
        display: flex; justify-content: space-between;  
        background-color: var(--brand-color); 
        cursor: pointer; transition: background-color 0.4s ease-out;
      }
      .fc-thread:hover { background-color: var(--two-color);  }
      .fc-title { max-width: 80%;  font-size: 1.4em;  
        padding: 12px 8px 12px 20px; word-break: break-word; }
      span { font-size: .6em; }
      
      .fc-info { width: 20%;  font-size: 0.8em;  padding: 10px; text-align: center; }
      /* .date {font-size: .8em; padding: .3em 0; } */
      
        
      /* ------------------- RESPONSIVE ------------------- */

      @media all and (max-width: 450px){ 
        .fc-thread { margin: 10px 0; }
        .fc-title { width: 75%;  font-size: 1.3em;  padding: 10px 8px 10px 10px; }
        .fc-info { width: 25%;  font-size: 0.75em; }
      }



      </style>

      ${this.typeInfo == "this-user" ? html`
        <nav class="fc-thread" > 
          <div class="fc-title"> ${this.thread.threadTitle} 
            <br><span>*Waiting for approval</span>
          </div>
          <div class="fc-info">
            ${JSON.stringify(this.thread.user.userName)} <br>
          </div>
        </nav>
      `:html`
        <nav class="fc-thread" @click="${this.openPost}"> 
          <div class="fc-title"> ${this.thread.threadTitle} </div>
          <div class="fc-info">
            ${JSON.stringify(this.thread.user.userName)} <br>
          </div>
        </nav>
      `}

    `
  }
  
})
