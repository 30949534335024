[{

  "countryName": "Afghanistan",
  "countryCode": "afg",
  "emergNumber": "119",
  "flag": false
},{

  "countryName": "Aland Islands",
  "countryCode": "ala",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Albania",
  "countryCode": "alb",
  "emergNumber": "129",
  "flag": false
},{

  "countryName": "Algeria",
  "countryCode": "dza",
  "emergNumber": "1548",
  "flag": false
},{

  "countryName": "American Samoa",
  "countryCode": "asm",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Andorra",
  "countryCode": "and",
  "emergNumber": "110",
  "flag": false
},{

  "countryName": "Angola",
  "countryCode": "ago",
  "emergNumber": "113",
  "flag": false
},{

  "countryName": "Anguilla",
  "countryCode": "aia",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Antarctica",
  "countryCode": "ata",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Antigua and Barbuda",
  "countryCode": "atg",
  "emergNumber": "911 or 999",
  "flag": true
},{

  "countryName": "Argentina",
  "countryCode": "arg",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Armenia",
  "countryCode": "arm",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Aruba",
  "countryCode": "abw",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Australia",
  "countryCode": "aus",
  "emergNumber": "000",
  "flag": true,
  "state": ["New South Wales","Queensland","South Australia","Tasmania","Victoria","Western Australia"]
},{

  "countryName": "Austria",
  "countryCode": "aut",
  "emergNumber": "112 or 133",
  "flag": true
},{

  "countryName": "Azerbaijan",
  "countryCode": "aze",
  "emergNumber": "102",
  "flag": false
},{

  "countryName": "Bahamas",
  "countryCode": "bhs",
  "emergNumber": "911 or 919",
  "flag": true
},{

  "countryName": "Bahrain",
  "countryCode": "bhr",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Bangladesh",
  "countryCode": "bgd",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Barbados",
  "countryCode": "brb",
  "emergNumber": "211",
  "flag": true
},{

  "countryName": "Belgium",
  "countryCode": "bel",
  "emergNumber": "101 or 112",
  "flag": true
},{

  "countryName": "Belarus",
  "countryCode": "blr",
  "emergNumber": "102",
  "flag": false
},{

  "countryName": "Belize",
  "countryCode": "blz",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Benin",
  "countryCode": "ben",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Bermuda",
  "countryCode": "bmu",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Bhutan",
  "countryCode": "btn",
  "emergNumber": "113",
  "flag": false
},{

  "countryName": "Bolivia",
  "countryCode": "bol",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Bonaire, Sint Eustatius and Saba",
  "countryCode": "bes",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Bosnia and Herzegovina",
  "countryCode": "bih",
  "emergNumber": "122",
  "flag": false
},{

  "countryName": "Botswana",
  "countryCode": "bwa",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Brazil",
  "countryCode": "bra",
  "emergNumber": "190",
  "flag": true
},{

  "countryName": "British Virgin Islands",
  "countryCode": "vgb",
  "emergNumber": "911 or 999",
  "flag": false
},{

  "countryName": "British Indian Ocean Territory",
  "countryCode": "iot",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Brunei Darussalam",
  "countryCode": "brn",
  "emergNumber": "993",
  "flag": true
},{

  "countryName": "Bulgaria",
  "countryCode": "bgr",
  "emergNumber": "112 or 166",
  "flag": false
},{

  "countryName": "Burkina Faso",
  "countryCode": "bfa",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Burundi",
  "countryCode": "bdi",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Cambodia",
  "countryCode": "khm",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Cameroon",
  "countryCode": "cmr",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Canada",
  "countryCode": "can",
  "emergNumber": "911",
  "flag": true,
  "state": ["Alberta","British Columbia","Manitoba","Quebec","New Brunswick","Newfoundland and Labrador",
	"Northwest Territories","Nova Scotia","Nunavut","Ontario","Prince Edward Island","Saskatchewan","Yukon"]
},{

  "countryName": "Cape Verde",
  "countryCode": "cpv",
  "emergNumber": "132",
  "flag": false
},{

  "countryName": "Cayman Islands",
  "countryCode": "cym",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Central African Republic",
  "countryCode": "caf",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Chad",
  "countryCode": "tcd",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Chile",
  "countryCode": "chl",
  "emergNumber": "133",
  "flag": false
},{

  "countryName": "China",
  "countryCode": "chn",
  "emergNumber": "110",
  "flag": true
},{

  "countryName": "Hong Kong, SAR China",
  "countryCode": "hkg",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Christmas Island",
  "countryCode": "cxr",
  "emergNumber": "000",
  "flag": false
},{

  "countryName": "Cocos (Keeling) Islands",
  "countryCode": "cck",
  "emergNumber": "000",
  "flag": false
},{

  "countryName": "Colombia",
  "countryCode": "col",
  "emergNumber": "123",
  "flag": false
},{

  "countryName": "Comoros",
  "countryCode": "com",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Congo (Brazzaville)",
  "countryCode": "cog",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Congo (Kinshasa)",
  "countryCode": "cod",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Cook Islands",
  "countryCode": "cok",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Costa Rica",
  "countryCode": "cri",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Croatia",
  "countryCode": "hrv",
  "emergNumber": "112 or 192",
  "flag": false
},{

  "countryName": "Cuba",
  "countryCode": "cub",
  "emergNumber": "106",
  "flag": false
},{

  "countryName": "Curacao",
  "countryCode": "cuw",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Cyprus",
  "countryCode": "cyp",
  "emergNumber": "112 or 199",
  "flag": false
},{

  "countryName": "Czech Republic",
  "countryCode": "cze",
  "emergNumber": "112 or 158",
  "flag": false
},{

  "countryName": "Denmark",
  "countryCode": "dnk",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Djibouti",
  "countryCode": "dji",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Dominica",
  "countryCode": "cdma",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Dominican Republic",
  "countryCode": "dom",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Ecuador",
  "countryCode": "ecu",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Egypt",
  "countryCode": "egy",
  "emergNumber": "122",
  "flag": false
},{

  "countryName": "El Salvador",
  "countryCode": "slv",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Equatorial Guinea",
  "countryCode": "gnq",
  "emergNumber": "122",
  "flag": false
},{

  "countryName": "Eritrea",
  "countryCode": "eri",
  "emergNumber": "113",
  "flag": false
},{

  "countryName": "Estonia",
  "countryCode": "est",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Ethiopia",
  "countryCode": "eth",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Falkland Islands (Malvinas)",
  "countryCode": "flk",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Faroe Islands",
  "countryCode": "fro",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Fiji",
  "countryCode": "fji",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Finland",
  "countryCode": "fin",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "France",
  "countryCode": "fra",
  "emergNumber": "112 or 17",
  "flag": true
},{

  "countryName": "French Guiana",
  "countryCode": "guf",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "French Polynesia",
  "countryCode": "pyf",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Gabon",
  "countryCode": "gab",
  "emergNumber": "1730",
  "flag": false
},{

  "countryName": "Gambia",
  "countryCode": "gmb",
  "emergNumber": "117",
  "flag": true
},{

  "countryName": "Georgia",
  "countryCode": "geo",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Germany",
  "countryCode": "deu",
  "emergNumber": "110",
  "flag": true
},{

  "countryName": "Ghana",
  "countryCode": "gha",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Gibraltar",
  "countryCode": "gib",
  "emergNumber": "199 or 112 or 999",
  "flag": false
},{

  "countryName": "Greece",
  "countryCode": "grc",
  "emergNumber": "100",
  "flag": false
},{

  "countryName": "Greenland",
  "countryCode": "grl",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Grenada",
  "countryCode": "grd",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Guadeloupe",
  "countryCode": "glp",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Guam",
  "countryCode": "gum",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Guatemala",
  "countryCode": "gtm",
  "emergNumber": "110",
  "flag": false
},{

  "countryName": "Guernsey",
  "countryCode": "ggy",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Guinea",
  "countryCode": "gin",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Guinea-Bissau",
  "countryCode": "gnb",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Guyana",
  "countryCode": "guy",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Haiti",
  "countryCode": "hti",
  "emergNumber": "114",
  "flag": false
},{

  "countryName": "Honduras",
  "countryCode": "hnd",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Hungary",
  "countryCode": "hun",
  "emergNumber": "112 or 107",
  "flag": false
},{

  "countryName": "Iceland",
  "countryCode": "isl",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "India",
  "countryCode": "ind",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Indonesia",
  "countryCode": "idn",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Iran",
  "countryCode": "irn",
  "emergNumber": "110",
  "flag": false
},{

  "countryName": "Iraq",
  "countryCode": "irq",
  "emergNumber": "112 or 911",
  "flag": false
},{

  "countryName": "Ireland",
  "countryCode": "irl",
  "emergNumber": "112 or 999",
  "flag": true
},{

  "countryName": "Isle of Man",
  "countryCode": "imn",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Israel",
  "countryCode": "isr",
  "emergNumber": "100",
  "flag": false
},{

  "countryName": "Italy",
  "countryCode": "ita",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Jamaica",
  "countryCode": "jam",
  "emergNumber": "119",
  "flag": false
},{

  "countryName": "Japan",
  "countryCode": "jpn",
  "emergNumber": "110",
  "flag": true
},{

  "countryName": "Jersey",
  "countryCode": "jey",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Jordan",
  "countryCode": "jor",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Kazakhstan",
  "countryCode": "kaz",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Kenya",
  "countryCode": "ken",
  "emergNumber": "112 or 999 or 911",
  "flag": true
},{

  "countryName": "Kiribati",
  "countryCode": "kir",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Korea (North)",
  "countryCode": "code",
  "flag": false
},{

  "countryName": "Korea (South)",
  "countryCode": "kor",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Kosovo",
  "countryCode": "scg",
  "emergNumber": "192",
  "flag": false
},{

  "countryName": "Kuwait",
  "countryCode": "kwt",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Kyrgyzstan",
  "countryCode": "kgz",
  "emergNumber": "102",
  "flag": false
},{

  "countryName": "Lao PDR",
  "countryCode": "lao",
  "emergNumber": "191",
  "flag": false
},{

  "countryName": "Latvia",
  "countryCode": "lva",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Lebanon",
  "countryCode": "lbn",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Lesotho",
  "countryCode": "lso",
  "emergNumber": "123",
  "flag": false
},{

  "countryName": "Liberia",
  "countryCode": "lbr",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Libya",
  "countryCode": "lby",
  "emergNumber": "1515",
  "flag": false
},{

  "countryName": "Liechtenstein",
  "countryCode": "lie",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Lithuania",
  "countryCode": "ltu",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Luxembourg",
  "countryCode": "lux",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Macedonia",
  "countryCode": "mkd",
  "emergNumber": "192",
  "flag": false
},{

  "countryName": "Macau",
  "countryCode": "mac",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Madagascar",
  "countryCode": "mdg",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Malawi",
  "countryCode": "mwi",
  "emergNumber": "997",
  "flag": true
},{

  "countryName": "Malaysia",
  "countryCode": "mys",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Maldives",
  "countryCode": "mdv",
  "emergNumber": "119",
  "flag": false
},{

  "countryName": "Mali",
  "countryCode": "mli",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Malta",
  "countryCode": "mlt",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Marshall Islands",
  "countryCode": "mhl",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Martinique",
  "countryCode": "mtq",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Mauritania",
  "countryCode": "mrt",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Mauritius",
  "countryCode": "mus",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Mayotte",
  "countryCode": "myt",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Mexico",
  "countryCode": "mex",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Micronesia",
  "countryCode": "fsm",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Moldova",
  "countryCode": "mda",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Monaco",
  "countryCode": "mco",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Mongolia",
  "countryCode": "mng",
  "emergNumber": "105",
  "flag": false
},{

  "countryName": "Montenegro",
  "countryCode": "mne",
  "emergNumber": "122",
  "flag": false
},{

  "countryName": "Montserrat",
  "countryCode": "msr",
  "emergNumber": "911 or 999",
  "flag": false
},{

  "countryName": "Morocco",
  "countryCode": "mar",
  "emergNumber": "19",
  "flag": false
},{

  "countryName": "Mozambique",
  "countryCode": "moz",
  "emergNumber": "119",
  "flag": false
},{

  "countryName": "Myanmar",
  "countryCode": "mmr",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Namibia",
  "countryCode": "nam",
  "emergNumber": "10 111",
  "flag": false
},{

  "countryName": "Nauru",
  "countryCode": "nru",
  "emergNumber": "110",
  "flag": false
},{

  "countryName": "Nepal",
  "countryCode": "npl",
  "emergNumber": "100",
  "flag": false
},{

  "countryName": "Netherlands",
  "countryCode": "nld",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Netherlands Antilles",
  "countryCode": "ant",
  "flag": false
},{

  "countryName": "New Caledonia",
  "countryCode": "ncl",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "New Zealand",
  "countryCode": "nzl",
  "emergNumber": "111",
  "flag": true,
  "state": ["Auckland","Canterbury"," Wellington","Waikato","Bay of Plenty","Manawatu-Wanganui","Otago","Hawke's Bay","Northland","Taranaki","Southland","Nelson","Gisborne","Marlborough","Tasman","West Coast"] 
},{

  "countryName": "Nicaragua",
  "countryCode": "nic",
  "emergNumber": "118",
  "flag": false
},{

  "countryName": "Niger",
  "countryCode": "ner",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Nigeria",
  "countryCode": "nga",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Niue",
  "countryCode": "niu",
  "flag": false
},{

  "countryName": "Norfolk Island",
  "countryCode": "nfk",
  "flag": false
},{

  "countryName": "Northern Mariana Islands",
  "countryCode": "mnp",
  "flag": false
},{

  "countryName": "Norway",
  "countryCode": "nor",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Oman",
  "countryCode": "omn",
  "emergNumber": "9999",
  "flag": false
},{

  "countryName": "Pakistan",
  "countryCode": "pak",
  "emergNumber": "15",
  "flag": false
},{

  "countryName": "Palau",
  "countryCode": "plw",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Palestine",
  "countryCode": "pse",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Panama",
  "countryCode": "pan",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Papua New Guinea",
  "countryCode": "png",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Paraguay",
  "countryCode": "pry",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Peru",
  "countryCode": "per",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Philippines",
  "countryCode": "phl",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Pitcairn",
  "countryCode": "pcn",
  "flag": false
},{

  "countryName": "Poland",
  "countryCode": "pol",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Portugal",
  "countryCode": "prt",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Puerto Rico",
  "countryCode": "pri",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Qatar",
  "countryCode": "qat",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Reunion",
  "countryCode": "reu",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Romania",
  "countryCode": "rou",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Russia",
  "countryCode": "rus",
  "emergNumber": "102 or 112",
  "flag": true
},{

  "countryName": "Rwanda",
  "countryCode": "rwa",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Saint-Barthelemy",
  "countryCode": "blm",
  "flag": false
},{

  "countryName": "Saint Helena",
  "countryCode": "shn",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Saint Kitts and Nevis",
  "countryCode": "kna",
  "emergNumber": "911",
  "flag": true
},{

  "countryName": "Saint Lucia",
  "countryCode": "lca",
  "emergNumber": "911 or 999",
  "flag": true
},{

  "countryName": "Saint-Martin (French part)",
  "countryCode": "maf",
  "flag": false
},{

  "countryName": "Saint Pierre and Miquelon",
  "countryCode": "spm",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Saint Vincent and Grenadines",
  "countryCode": "vct",
  "emergNumber": "999 or 911 or 112",
  "flag": true
},{

  "countryName": "Samoa",
  "countryCode": "wsm",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "San Marino",
  "countryCode": "smr",
  "emergNumber": "113",
  "flag": false
},{

  "countryName": "Sao Tome and Principe",
  "countryCode": "stp",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Saudi Arabia",
  "countryCode": "sau",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Senegal",
  "countryCode": "sen",
  "emergNumber": "17",
  "flag": false
},{

  "countryName": "Serbia",
  "countryCode": "srb",
  "emergNumber": "192 or 112",
  "flag": false
},{

  "countryName": "Seychelles",
  "countryCode": "syc",
  "emergNumber": "112 or 999",
  "flag": false
},{

  "countryName": "Sierra Leone",
  "countryCode": "sle",
  "emergNumber": "019",
  "flag": true
},{

  "countryName": "Singapore",
  "countryCode": "sgp",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Slovakia",
  "countryCode": "svk",
  "emergNumber": "158",
  "flag": false
},{

  "countryName": "Slovenia",
  "countryCode": "svn",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Solomon Islands",
  "countryCode": "slb",
  "emergNumber": "911 or 999",
  "flag": true
},{

  "countryName": "Somalia",
  "countryCode": "som",
  "emergNumber": "888",
  "flag": false
},{

  "countryName": "South Africa",
  "countryCode": "zaf",
  "emergNumber": "10 111",
  "flag": true
},{

  "countryName": "South Georgia and the South Sandwich Islands",
  "countryCode": "sgs",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "South Sudan",
  "countryCode": "ssd",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Spain",
  "countryCode": "esp",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Sri Lanka",
  "countryCode": "lka",
  "emergNumber": "119",
  "flag": false
},{

  "countryName": "Sudan",
  "countryCode": "sdn",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Suriname",
  "countryCode": "sur",
  "emergNumber": "115",
  "flag": false
},{

  "countryName": "Svalbard and Jan Mayen Islands",
  "countryCode": "sjm",
  "flag": false
},{

  "countryName": "Swaziland",
  "countryCode": "swz",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "Sweden",
  "countryCode": "swe",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Switzerland",
  "countryCode": "che",
  "emergNumber": "117",
  "flag": true
},{

  "countryName": "Syria",
  "countryCode": "syr",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Taiwan",
  "countryCode": "twn",
  "emergNumber": "110",
  "flag": false
},{

  "countryName": "Tajikistan",
  "countryCode": "tjk",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Tanzania",
  "countryCode": "tza",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Thailand",
  "countryCode": "tha",
  "emergNumber": "191 or 911",
  "flag": false
},{

  "countryName": "Timor-Leste (East)",
  "countryCode": "tls",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Togo",
  "countryCode": "tgo",
  "emergNumber": "117",
  "flag": false
},{

  "countryName": "Tokelau",
  "countryCode": "tkl",
  "flag": false
},{

  "countryName": "Tonga",
  "countryCode": "ton",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Trinidad and Tobago",
  "countryCode": "tto",
  "emergNumber": "999 or 911",
  "flag": true
},{

  "countryName": "Tunisia",
  "countryCode": "tun",
  "emergNumber": "197",
  "flag": false
},{

  "countryName": "Turkey",
  "countryCode": "tur",
  "emergNumber": "112",
  "flag": true
},{

  "countryName": "Turkmenistan",
  "countryCode": "tkm",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Turks and Caicos Islands",
  "countryCode": "tca",
  "flag": false
},{

  "countryName": "Tuvalu",
  "countryCode": "tuv",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Uganda",
  "countryCode": "uga",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Ukraine",
  "countryCode": "ukr",
  "emergNumber": "102",
  "flag": false
},{

  "countryName": "United Arab Emirates",
  "countryCode": "are",
  "emergNumber": "999",
  "flag": false
},{

  "countryName": "United Kingdom",
  "countryCode": "gbr",
  "emergNumber": "999 or 112",
  "flag": true,
  "state": ["Bath","Bedfordshire","Blackburn","Blackpool","Bournemouth","Bracknell Forest","Brighton",
"Bristol","Buckinghamshire","Cambridgeshire","Cheshire","Cleveland","Cornwall","Cumbria","Darlington",
"Darwen","Derby","Derbyshire","Devon","Dorset","Durham","East Riding of Yorkshire","East Sussex",
"Essex","Gloucestershire","Greater Manchester","Halton","Hampshire","Hartlepool","Herefordshire",
"Hertfordshire","Hove","Isle of Wight","Kent","Kingston upon Hull","Lancashire","Leicester",
"Leicestershire","Lincolnshire","London","Luton","Maidenhead","Merseyside","Middlesbrough",
"Milton Keynes","Norfolk","North East Lincolnshire","North East Somerset","North Lincolnshire",
"North Somerset","North Yorkshire","Northamptonshire","Northumberland","Nottingham","Nottinghamshire",
"Oxfordshire","Peterborough","Plymouth","Poole","Portsmouth","Reading","Redcar","Rutland","Shropshire",
"Slough","Somerset","South Gloucestershire","South Yorkshire","Southampton","Southend-on-Sea",
"Staffordshire","Stockton-on-Tees","Stoke-on-Trent","Suffolk","Surrey","Swindon","Torbay","Tyne",
"Warrington","Warwickshire","Wear","West Berkshire","West Midlands","West Sussex","West Yorkshire",
"Wiltshire","Windsor","Wokingham","Worcestershire","York"]
},{

  "countryName": "United States of America",
  "countryCode": "usa",
  "emergNumber": "911",
  "flag": true,
  "state": ["Alabama","Alaska","Arizona","Arkansas","California","Colorado","Connecticut","Delaware",
"Florida","Georgia","Hawaii","Idaho","Illinois","Indiana","Iowa","Kansas","Kentucky","Louisiana",
"Maine","Maryland","Massachusetts","Michigan","Minnesota","Mississippi","Missouri","Montana",
"Nebraska","Nevada","New Hampshire","New Jersey","New Mexico","New York","North Carolina",
"North Dakota","Ohio","Oklahoma","Oregon","Pennsylvania","Rhode Island","South Carolina","South Dakota",
"Tennessee","Texas","Utah","Vermont","Virginia","Washington","West Virginia","Wisconsin","Wyoming"]
},{

  "countryName": "Uruguay",
  "countryCode": "ury",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Uzbekistan",
  "countryCode": "uzb",
  "emergNumber": "102",
  "flag": false
},{

  "countryName": "Vanuatu",
  "countryCode": "vut",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Vatican City",
  "countryCode": "vat",
  "emergNumber": "112",
  "flag": false
},{

  "countryName": "Venezuela",
  "countryCode": "ven",
  "emergNumber": "911 and 171",
  "flag": false
},{

  "countryName": "Viet Nam",
  "countryCode": "vnm",
  "emergNumber": "113",
  "flag": false
},{

  "countryName": "Virgin Islands",
  "countryCode": "vir",
  "emergNumber": "911",
  "flag": false
},{

  "countryName": "Western Sahara",
  "countryCode": "esh",
  "emergNumber": "150",
  "flag": false
},{

  "countryName": "Yemen",
  "countryCode": "yem",
  "emergNumber": "194",
  "flag": false
},{

  "countryName": "Zambia",
  "countryCode": "zmb",
  "emergNumber": "999",
  "flag": true
},{

  "countryName": "Zimbabwe",
  "countryCode": "zwe",
  "emergNumber": "999",
  "flag": true
}]