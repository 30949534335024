import App from '../../../App'
import {render, html} from 'lit/html.js'
import {navClick } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'


class LessonsView {
  init(){    
    document.title = 'Lessons'    
    this.render()  
  }

  quizleadModal(){ Utils.modalPquizlead() }
  unlockQuizModal(){Utils.modalPrevQuiz() }

  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lessons"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>

        <div class="content-panel">    
          
            ${Auth.currentUser.quizLevel ? html` 
              <nav class="home-btn primary" nav-link="/quiz/leaderboards" @click="${navClick}">
                <div class="hbi-box">
                  <img class="nav-img" alt="Leader boards link" src="./../images/icons/sw07-leaderboards.svg">
                </div>
                <div class="hb-title">Leader boards</div>
              </nav>
            ` : html`
              <nav class="home-btn disabled" @click="${this.quizleadModal}">
                <div class="hbi-box">
                  <img class="nav-img" src="./../images/icons/sw07-leaderboards.svg">
                </div>
                <div class="hb-title">Leader boards</div>
                <img class="m-locked" alt="Leader boards locked" src="/images/icons/${Data.appcode}03-unlock.svg">
              </nav>
            `}

          ${Data.lessons.map(alesson => html `
            <div class="info-btn" >
              <nav class="title-wrapper" nav-link="/lesson/${alesson.lesson}" @click="${navClick}">
                <div class="info-title"> ${alesson.lesson}. ${alesson.lessonTitle} </div>
              </nav>
              ${Auth.currentUser.quizLevel ? html`

                ${alesson.lesson  == Auth.currentUser.quizLevel? html` 
                  <nav class="qi-link" nav-link="/quiz/lesson?level=${alesson.lesson}" @click="${navClick}">
                    <img class="qi-img" alt="Quiz link" src="./../images/icons/quiz-active-${Data.appcode}.svg">
                  </nav>
                `:html`

                  ${alesson.lesson  >= Auth.currentUser.quizLevel? html` 
                    <nav class="qi-link" @click="${this.unlockQuizModal}">
                      <img class="qi-img" alt="Quizzes locked" src="./../images/icons/quiz-disabled.svg">
                    </nav>

                  `:html`
                    <nav class="qi-link" nav-link="/quiz/lesson?level=${alesson.lesson}" @click="${navClick}">
                      <img class="qi-img qi-img-space" alt="Quiz passed" src="./../images/icons/tick-${Data.appcode}.svg">
                    </nav>
                  `}
                `}
                        
              `:html`
                <nav class="qi-link" @click="${this.quizleadModal}">
                  <img class="qi-img" alt="Quizzes locked" src="./../images/icons/quiz-disabled.svg">
                </nav>
              `}

            </div> 
          `)}

          <nav class="info-btn" nav-link="/lesson/bibliography" @click="${navClick}">
              <div class="info-title bibl"> Bibliography </div>
          </nav>

        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new LessonsView()