import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class PaymentsView {
  init(){    
    document.title = 'My Payments'    
    this.render()  
    Utils.initFont()
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">      

          Page contents here- my payments - history records.
          Version 2

        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new PaymentsView()