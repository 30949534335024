import {LitElement, html} from 'lit'
import Data from '../Data' // for app variables


customElements.define('my-accordian', class BoardUsers extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      accordianName: { type: String },
      accordianType: { type: String },
      dataId: { type: String }
    }
  }
  

  firstUpdated(){
    super.firstUpdated()
  }


  accordianClick(e){
    e.preventDefault()
    const accordionBtn =  e.target
    const panel = e.target.nextElementSibling;
    if (panel.style.maxHeight) {
       panel.style.maxHeight = null;
       accordionBtn.classList.toggle("ac-active")     
    } else {
      panel.style.maxHeight = Math.max(panel.scrollHeight) + "px";
      accordionBtn.classList.toggle("ac-active")
    } 
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .ac-title {
          position: relative;
          padding: 10px;
          display: flex;
          justify-content: space-between; 
          align-items: center;
          margin-top: 10px;
          cursor: pointer;
          width: 100%;
          background-color: white;
          text-align: left;
          font-size: 1.3em; 
          color: var(--two-color);
          border: 2px solid var(--two-color);
        }
        
        .ac-titleb { background-color: white; width: 100%; padding: 1em; font-size: 1.3em; }
        
        .ac-title:after {
          content: '';
          height: 7px;
          width: 7px;
          margin: 5px;
          flex: 0 0 auto;
          border-bottom: 3px solid var(--brand-color);
          border-right: 3px solid var(--brand-color);
          right: 5px;
          transition: transform .4s ease-out;
          transform: rotate(-45deg);
        }
        
        .ac-active { color: var(--three-color); transition: transform .4s ease-out;  }
        .ac-active:after { transform: rotate(45deg) !important; }
        
        .ac-body {
          position: relative;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.4s ease-out;
          margin-bottom: 10px;
        }

      </style>


      <button class="ac-title" @click="${this.accordianClick}">
        ${this.accordianName}
      </button>

      <div id="ac-insert" class="ac-body">

        ${this.accordianType == "stats"? html`
          ${Data.statisticsFields
            .find(stat => stat.category == this.dataId)
            .fields
            .map(stat => html ` 
              <stat-data-btn statType="cases" statField="${stat.fieldName}" statTitle="${this.dataId}" statName="${stat.btnName}"></stat-data-btn>
          `)}
        `: html``}

        ${this.accordianType == "forum"? html`
          ${Data.subTopics
            .filter(subtopic => subtopic.topicId == this.dataId )
            .map(subtopic => html ` 
            <forum-subtopics subtopicId="${subtopic.subtopicId}" subtopicTitle="${subtopic.subtopicTitle}"></forum-subtopics>
          `)}
        `: html``}     

      </div>

    `
  }
  
})
