

class Element {
    // constructor(){
    // //   this.name = "Element"
    // //   this.myEl = document.getElementById("my-el")
    // } 
  
    createNewElement(el, id, classes) {
        const newEl = document.createElement(el)
        if(id) { newEl.id = id }
        if(classes) {  
            if(Array.isArray(classes)){
                classes.forEach(newClass => {
                    newEl.classList.add(newClass)
                })
            } else {
                newEl.classList.add(classes)
            }
         }
        return newEl
    }
    // const newEmptyEl = Element.createNewElement('div', 'unique-div-id', 'append-div')



    appendTextNodes(el, content) {
        if(Array.isArray(content)){
            content.forEach(contentItem => {
                const newTextEl = document.createTextNode(contentItem);
                el.appendChild(newTextEl)
            })
        } else {
            const newTextEl = document.createTextNode(content);
            el.appendChild(newTextEl)
        }
        return el
    }
    // PASS IN ARRAY OR SINGLE ITEM & Empty Element or with text:
    // const elWithText = Element.addTextToEl(occupiedEl, [' I am added', ' me too'])
    // const elWithText = Element.addTextToEl(newEmptyEl, ' Just me added')

    replaceTextItem(el, content) {
        this.removeAllChildren(el)
        this.appendTextNodes(el, content)
        // const newTextEl = document.createTextNode(content);
        
        // el.firstChild.replaceWith(newTextEl)
        // return el
    }
    

    removeAllChildren(parent) {
        while (parent.lastChild) {
            parent.removeChild(parent.lastChild)
        }
    }
        // OTHER: // testList.deleteContents() - works on ranges?!?!
    //CALL FUNCTION -----------------------------------------------------
    // removeAllContents(){
    //     const testList = document.getElementById('test-remove-list')
    //     Element.removeAllChildren(testList)
    // }

  }
  
  export default new Element()