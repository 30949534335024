import App from '../App'
import Toast from '../Toast'
import Data from '../Data'

//import {loadStripe} from '@stripe/stripe-js';
//https://github.com/stripe/stripe-js

import {loadStripe} from '@stripe/stripe-js/pure';
//https://github.com/stripe/stripe-js/issues/43

//https://stackoverflow.com/questions/37833319/stripe-js-complaining-about-being-included-twice


// try this please, for me it works

// componentWillMount() {
//         const stripeExists = document.getElementById('stripev3');
//         if (!stripeExists) {
//             const stripeScript = document.createElement('script');
//             stripeScript.src = 'https://js.stripe.com/v3/';
//             stripeScript.async = true;
//             stripeScript.id = 'stripev3';
//             document.head.appendChild(stripeScript);
//         }
//     }



let stripe; 
let elements;


class CheckoutAPI {
  
  async initiateStripe(){
    try {
      stripe = await loadStripe(process.env.BRANCH_NAME)
      //console.log('stripe initiation success')
    } catch {
      console.log('stripe initiation failed')
    }
  }

  //initCheckout -- get payment intent - clientSecret
  async setUpPayment(initData, fail = false){
    console.log('initPayment - begin')
    this.initiateStripe()
    try{
      console.log('initPayment - try')
      const response = await fetch(`${App.apiBase}/checkout/`, {
          method: 'POST',      
          headers: {
                  "Authorization": `Bearer ${localStorage.accessToken}`,
                  "Content-Type" : "application/json", 
                  "Origin": App.origin
              },
          body: initData
      })
      if(!response.ok){      
        const err = await response.json()
        if(err) console.log(err)  
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      Data.coCode = data
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }

  }

  async initPayment(fail = false){ 
      const clientSecret = Data.coCode
      const appearance = { theme: 'stripe', };
    try{
      elements = stripe.elements({ appearance, clientSecret });
      const paymentElement = elements.create("payment");
      paymentElement.mount("#payment-element");
      return true
    } catch(err) {
        console.log(err)
        Toast.show(err, 'error')
        return false
    }
  }


  async handleSubmit(){
    //Data.initiated - to prevent from triggering twice
    // if(Data.initiated == true){return}
    // else { Data.initiated = true }
    this.setLoading(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: App.origin + "/success",
      },
    });
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message);
    } else {
      this.showMessage("An unexpected error occurred.");
    }
    this.setLoading(false);
  }


  async checkStatus() {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
      if (!clientSecret) { return }
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      switch (paymentIntent.status) {
        case "succeeded":
          this.showMessage("Payment succeeded!");
          break;
        case "processing":
          this.showMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          this.showMessage("Your payment was not successful, please try again.");
          break;
        default:
          this.showMessage("Something went wrong.");
          break;
      }
  }


  showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
      setTimeout(function () {
        messageContainer.classList.add("hidden");
        messageText.textContent = "";
      }, 4000);
  }

  
  setLoading(isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("#submit").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("#submit").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
  }


}

export default new CheckoutAPI()