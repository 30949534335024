import Validate from '../Validate'
import App from '../App'
import Auth from '../Auth'
import Toast from '../Toast'
import {gotoRoute, anchorRoute } from '../Router'

class RegisterApi {

  async registerLink(userData, fail = false) {
    try{
      const response = await fetch(`${App.apiBase}/register/`, {
        method: 'POST',      
        headers: {"Content-Type" : "application/json", "Origin": App.origin},
        body: userData
      })
      if(!response.ok){
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const mailMessage = await response.json()
      Toast.show(`${mailMessage.message}`)
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }

  }

  async verifyRegisterToken(token, fail = false){
    try{
      const response = await fetch(`${App.apiBase}/register/validatetoken`, { 
        method: 'GET', headers: { "Authorization": `Bearer ` + token } })
      if(!response.ok){      
        const err = await response.json()
        if(err) console.log(err)
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      Validate.newToken = data.token
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }

  } 

  async registerNewUser(userData, fail = false){  
    try{
      const response = await fetch(`${App.apiBase}/register/register`, {
        method: 'POST',      
        headers: {"Content-Type" : "application/json", "Origin": App.origin},
        body: userData
      })

      if(!response.ok){      
        const err = await response.json()
        if(err) console.log(err)  
        Toast.show(`${response.status}: ${err.message}`, 'error')   
        if(typeof fail == 'function') fail()
        return false
      }
      const data = await response.json()
      Toast.show(`Account created, welcome ${data.user.userName}`)   
      localStorage.setItem('accessToken', data.accessToken)
      Auth.currentUser = data.user  
      gotoRoute('/')
      return true
    } catch(err) {
      console.log(err)
      Toast.show(err, 'error')
      return false
    }
  }

}

export default new RegisterApi()