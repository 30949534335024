import {LitElement, html} from 'lit'
import {anchorRoute, gotoRoute} from '../../Router'
import Auth from '../../Auth' // for user auth
import Utils from '../../Utils'
import Nstat from '../../Nstat'
import CaseAPI from '../../api/CaseAPI'
import UserApi from '../../auth/UserApi'

//If revising code to add return to previous tabs functioning
//... then don't forget to reset all validation consts for each tab
customElements.define('cmi-casefunction', class CaseHandler extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      modalType: {
        type: String
      }
    }
  }


  firstUpdated(){
    super.firstUpdated()
  }


  showTab(){
    Nstat.showEditTab(4)
    Utils.closeModal()
  }

  hideViews(){
    const allViews = this.shadowRoot.querySelectorAll('.cm-view')
    allViews.forEach(view => {
      if(!view.classList.contains('hidden')){ view.classList.add('hidden') }
    });
  }

  showView(x){
    this.hideViews()
    const newView = this.shadowRoot.getElementById('cm-view' + x)
    newView.classList.remove('hidden')
  }

  showDetailTab(){
    this.showView('1')
  }

  homeButton(){
    gotoRoute('/')
    Utils.closeModal()
  }

  viewIncidents(){
    gotoRoute('/user/cases')
    Utils.closeModal()
  }

  async updateHandler(){
    this.showView(2)
    try {
      const caseObject = JSON.stringify(Nstat.caseUpdates)
      const caseStatus = await CaseAPI.updateCase(Auth.caseId, caseObject)
      if(caseStatus == false){
        this.showView(4)
      } else { 
        const objIndex = Auth.myCases.findIndex((obj => obj.id == caseStatus.incident._id ))
        Auth.myCases[objIndex] = caseStatus.incident
        Nstat.resetCase()
        this.showView(3)
      }
    } catch(err) {
      //console.log('a catch error occured')
      console.log(err)
      this.showView(4)
    }
  }


  async addIncidentHandler(){
    this.showView(2)
    Nstat.caseData.user = Auth.currentUser._id
    try {
      //add to cases number in database
      let addUserCase = {}
      if(Auth.currentUser.cases){
        addUserCase.cases = Auth.currentUser.cases + 1
      } else {
        addUserCase.cases = 1
      }
      const userObject = JSON.stringify(addUserCase);
      await UserApi.updateUser(Auth.currentUser._id, userObject)
      //And post the new case
      const caseObject = JSON.stringify(Nstat.caseData);
      const caseStatus = await CaseAPI.newCase(caseObject)
      if(caseStatus == false){
        //console.log('an error occured')
        this.showView(4)
      } else { 
        //console.log('successfully added')
        Nstat.resetCase()
        this.showView(3)
      }
    }catch(err){
      //console.log('a catch error occured')
      console.log(err)
      this.showView(4)
    }
  }
  


  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      .mi-box { max-width: 500px; font-size: 1.2em; 
        max-height: 80vh; overflow-y: auto; padding-right: 15px; }
      .mi-text { margin-bottom: 30px;  width: 250px;  }
      .para { text-align: left; }
      .app-info { margin: 10px; }

      ::-webkit-scrollbar {width: 15px;}
      ::-webkit-scrollbar-track {
        background: var(--bginfo-color);
        border-radius: 500px;
      }
      ::-webkit-scrollbar-thumb {
        background: var(--brand-color);
        border-radius: 500px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: var(--two-color);
      }

      #warn{ width: 100px; }
      .subt{color: var(--two-color); font-weight: bold; text-decoration: underline; }

      .redirect-button {
        color: white;
        border: none;
        border-radius: 5px;
        padding: 7px;
        margin: 20px auto;
        text-decoration: none;
        width: 200px; 
        cursor: pointer;
        font-size: 1.1em;
      }
      .success{font-size: 2em;}
      #tick{ width: 100px; }
      .primary { background-color: var(--brand-color); transition: .2s; }
      .primary:hover { background-color: var(--two-color);  }
      .action { background-color: var(--action-color) ; transition: .2s; }
      .action:hover { background-color: rgb(243, 183, 18); }
      .disabled { background-color: var(--disabled-color); cursor: none; }
      .hidden { display: none; }



      @media all and (max-width: 768px){
        .mi-box { max-width: 80vw; }
      } 

       

    </style>

        <div class="mi-box">



       
<!-- Detail view 1 -->
          <div id="cm-view1" class="cm-view">

            <h3>New details of incident:</h3>
            <p class="app-info para">
              <strong>Incident Title: </strong>${Nstat.caseData.caseTitle}
            </p>

            <p class="app-info para">
              <span class="subt">USER:</span>
              <br><strong>Age: </strong>${Nstat.caseData.survivor.survivorAge}
              <br><strong>Disability: </strong>${Nstat.caseData.survivor.survivorDisability}
              <br><strong>Impacted: </strong>${Nstat.caseData.survivor.survivorImpact}
              
              ${Nstat.caseData.survivor.impactType.length >= 1 ? html`
                <br><strong>Impacted by: </strong>
                ${Nstat.caseData.survivor.impactType.map(impact => html`
                  <br> - ${impact} 
                `)}
              `:html``}
            </p>

            <p class="app-info para">
              <span class="subt">INCIDENT:</span>
              <br><strong>Incident Year: </strong>${Nstat.caseData.incident.caseYear}
              <br><strong>Reported status: </strong>${Nstat.caseData.incident.caseReported}
              <br><strong>Frequency: </strong>${Nstat.caseData.incident.caseFrequency}
              ${Nstat.caseData.incident.ongoingDuration ? html`
                <br><strong>Duration: </strong> ${Nstat.caseData.incident.ongoingDuration}
              `:``}
              <br><strong>Incident type: </strong>${Nstat.caseData.incident.caseType}
              <br><strong>Main response: </strong>${Nstat.caseData.incident.caseReaction}
            </p>

            <p class="app-info para">
              <span class="subt">PLACE:</span>
              <br><strong>Country: </strong>${Nstat.caseData.place.caseCountry}
              ${Nstat.caseData.place.caseState ? html`
                <br><strong>State: </strong> ${Nstat.caseData.place.caseState}
              `:``}
              <br><strong>Location: </strong>${Nstat.caseData.place.caseLocation}
              ${Nstat.caseData.place.subdivision ? html`
                <br><strong>Subdivision: </strong> ${Nstat.caseData.place.subdivision}
              `:``}
              ${Nstat.caseData.place.locationSector ? html`
                <br><strong>Sector: </strong> ${Nstat.caseData.place.locationSector}
              `:``}
            </p>

            <p class="app-info para">
              <span class="subt">PERPETRATOR:</span>
              <br><strong>Age: </strong>${Nstat.caseData.perpetrator.perpAge}
              <br><strong>Gender: </strong>${Nstat.caseData.perpetrator.perpGender}
              <br><strong>Position: </strong>${Nstat.caseData.perpetrator.perpPosition}
              <br><strong>Were they known: </strong>${Nstat.caseData.perpetrator.perpKnown}
            </p>

            ${this.modalType == "editIncident" ? html`
             <div class="redirect-button action" @click=${this.updateHandler.bind(this)}>Confirm Update</div>
            `:``}
            ${this.modalType == "addIncident" ? html`
              <div class="redirect-button action" @click=${this.addIncidentHandler.bind(this)}>Confirm New Incident</div>
            `:``}

            <div class="redirect-button primary" @click=${Utils.closeModal.bind(Utils)}>Cancel</div>

          </div>

<!-- Spinner view 2 -->
          <div id="cm-view2" class="cm-view hidden">
              ${this.modalType == "editIncident" ? html`Updating incident ...`:``}
              ${this.modalType == "addIncident" ? html`Adding new incident ...`:``}
            <br><br>
            <c-spinner></c-spinner>
          </div>

<!-- Success view 3 -->
          <div id="cm-view3" class="cm-view hidden">
            <p class="mi-text success">
             Success
            </p>
            <img id="tick" alt="Success Tick" src="/images/icons/tick-circle.svg">
            <br><br>
            <div class="redirect-button action" @click=${this.viewIncidents.bind(this)}>View Incidents</div>
            <div class="redirect-button primary" @click=${this.homeButton.bind(this)}>Home</div>
          </div>

<!-- Error view 4 -->
          <div id="cm-view4" class="cm-view hidden">
            <p class="mi-text"> Something went wrong </p>
            <img id="warn" alt="Error Exclamation" src="/images/icons/warning.svg">
            <br>
            <div class="redirect-button action" @click=${this.showDetailTab.bind(this)}>Show detail tab</div>
            <div class="redirect-button primary" @click=${Utils.closeModal.bind(Utils)}>Cancel</div>

          </div>

        </div>
    `
  }
  
})
