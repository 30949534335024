[{
    "lesson": 1,
    "questions": [{
      "question": "When does the survivor deserve sexual assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "When they are naked"},
          {"answerStatus": "wrong", "answer": "When they insulted the perpetrator (attacker)"},
          {"answerStatus": "wrong", "answer": "When they stand close to the perpetrator (attacker)"},
          {"answerStatus": "correct", "answer": "Never"}
          ] 
      },{
      "question": "How should the survivor feel after sexual assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "They should blame themselves"},
          {"answerStatus": "wrong", "answer": "Ashamed of themselves"},
          {"answerStatus": "wrong", "answer": "Think of what they did to cause it"},
          {"answerStatus": "correct", "answer": "They should feel like a survivor"}
          ] 
      },{
      "question": "Which one of the following is NOT sexual assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "When a 10 year old agrees to do a sexual act"},
          {"answerStatus": "wrong", "answer": "When the survivor is threaten to do a sexual act"},
          {"answerStatus": "wrong", "answer": "When the survivor is too drunk to say no"},
          {"answerStatus": "correct", "answer": "When two adult partners agree"}
          ] 
      },{
      "question": "Who could be a perpetrator (attacker)?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Someone you know"},
          {"answerStatus": "wrong", "answer": "Someone from the internet"},
          {"answerStatus": "wrong", "answer": "A stranger on the street"},
          {"answerStatus": "correct", "answer": "All of the given options"}
          ] 
      },{
      "question": "Which of the following IS sexual assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Holding hands"},
          {"answerStatus": "wrong", "answer": "Brushing someone’s hair"},
          {"answerStatus": "wrong", "answer": "Massaging someone’s feet"},
          {"answerStatus": "correct", "answer": "Sharing nude or revealing images without permission"}
          ] 
      }]
  },{
    "lesson": 2,
    "questions": [{
      "question": "Why must someone be over the age of consent?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Because younger people can make their own decisions"},
          {"answerStatus": "wrong", "answer": "Because younger people understand exactly what is happening"},
          {"answerStatus": "wrong", "answer": "Because younger people can easily express themselves"},
          {"answerStatus": "correct", "answer": "Because younger people don’t understand the full extent of what is happening and aren’t aware of possible consequences"}
          ] 
      },{
      "question": "Which of the following is NOT an example of consent?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Saying “Yes”"},
          {"answerStatus": "wrong", "answer": "Returning touches"},
          {"answerStatus": "wrong", "answer": "Undressing"},
          {"answerStatus": "correct", "answer": "Silence (not speaking) and not moving"}
          ] 
      },{
      "question": "Who from among the following is aware and conscious?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Someone asleep"},
          {"answerStatus": "wrong", "answer": "Someone is too drunk to move"},
          {"answerStatus": "wrong", "answer": "Someone passed out on drugs"},
          {"answerStatus": "correct", "answer": "Someone who responds with speech or gestures"}
          ] 
      },{
      "question": "Which of the following statements is NOT acceptable to check if there is consent?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Asking: Do you want to … ?"},
          {"answerStatus": "wrong", "answer": "Asking: Would you like to continue?"},
          {"answerStatus": "wrong", "answer": "Asking: Are you OK?"},
          {"answerStatus": "correct", "answer": "Saying: Take off your clothes."}
          ] 
      },{
      "question": "What is an example of coercion?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Telling someone to do something else you won’t be their friend"},
          {"answerStatus": "wrong", "answer": "Telling someone to do something else you will tell on them for something wrong they did"},
          {"answerStatus": "wrong", "answer": "Telling someone to do something or you will share a private image or video with all their friends"},
          {"answerStatus": "correct", "answer": "All of the given examples"}
          ] 
      }]
  },{
    "lesson": 3,
    "questions": [{
      "question": "What type of response is the following: Kneeing an attacker in the between the legs when trying to rape",
      "answers": [
          {"answerStatus": "wrong", "answer": "Flight"},
          {"answerStatus": "wrong", "answer": "Freeze"},
          {"answerStatus": "wrong", "answer": "Call for help"},
          {"answerStatus": "correct", "answer": "Fight"}
          ] 
      },{
      "question": "When would “call for help” be the best response?",
      "answers": [
          {"answerStatus": "wrong", "answer": "When there is no one else in the house"},
          {"answerStatus": "wrong", "answer": "When you are in a place with deafening noise"},
          {"answerStatus": "wrong", "answer": "When you will be too ashamed if someone saw you"},
          {"answerStatus": "correct", "answer": "When there is someone nearby that you want help from"}
          ] 
      },{
      "question": "What is an example of the Deter response? Saying …",
      "answers": [
          {"answerStatus": "wrong", "answer": "Why won’t my body work..."},
          {"answerStatus": "wrong", "answer": "Help, someone help me"},
          {"answerStatus": "wrong", "answer": "Get off me or I’ll smash your face"},
          {"answerStatus": "correct", "answer": "I feel sick, I think I’m going to vomit"}
          ] 
      },{
      "question": "What is an example of the Flight response?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Pushing the abuser against the wall and grabbing them forcefully"},
          {"answerStatus": "wrong", "answer": "Saying to the abuser: Stop, you’re hurting me"},
          {"answerStatus": "wrong", "answer": "Dissociating yourself and watching everything from above"},
          {"answerStatus": "correct", "answer": "Running out the house when under threat of being attacked"}
          ] 
      },{
      "question": "What is a sign that someone has entered the Freeze state?",
      "answers": [
          {"answerStatus": "wrong", "answer": "They can kick their attacker"},
          {"answerStatus": "wrong", "answer": "They can call for help"},
          {"answerStatus": "wrong", "answer": "They run away"},
          {"answerStatus": "correct", "answer": "Their body will not do or say anything to stop it"}
          ] 
      }]
  },{
    "lesson": 4,
    "questions": [{
      "question": "Which of the following statements is correct about sexual harassment?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Sexual harassment is nothing to be concerned about"},
          {"answerStatus": "wrong", "answer": "It is only illegal when you actually touch someone"},
          {"answerStatus": "wrong", "answer": "There is no punishment for sexual harassment"},
          {"answerStatus": "correct", "answer": "The punishment for sexual harassment is a fine and can include a jail sentence"}
          ] 
      },{
      "question": "Sexual harassment includes which of the following … ",
      "answers": [
          {"answerStatus": "wrong", "answer": "Asking about your family"},
          {"answerStatus": "wrong", "answer": "Asking about your qualifications"},
          {"answerStatus": "wrong", "answer": "Asking about your hobbies"},
          {"answerStatus": "correct", "answer": "Asking about your sex life"}
          ] 
      },{
      "question": "Which of the following is NOT sexual harassment?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Staring or looking their body up and down"},
          {"answerStatus": "wrong", "answer": "Repeatedly asking for dates or inappropriate pictures"},
          {"answerStatus": "wrong", "answer": "Catcalling or whistling"},
          {"answerStatus": "correct", "answer": "Offering someone help"}
          ] 
      },{
      "question": "Which of the following statements is correct?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Sexual harassment should be ignored"},
          {"answerStatus": "wrong", "answer": "Sexual harassment is not punishable by law"},
          {"answerStatus": "wrong", "answer": "Sexual harassment is wanted behaviour"},
          {"answerStatus": "correct", "answer": "Sexual harassment is often a pre-cursor to sexual assault"}
          ] 
      },{
      "question": "What form can sexual harassment be?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Gestures"},
          {"answerStatus": "wrong", "answer": "Texts"},
          {"answerStatus": "wrong", "answer": "Sending inappropriate images"},
          {"answerStatus": "correct", "answer": "All the given choices"}
          ] 
      }]
  },{
    "lesson": 5,
    "questions": [{
      "question": "What is an example of Averted sexual assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Where the survivor freezes"},
          {"answerStatus": "wrong", "answer": "Where the survivor is trapped"},
          {"answerStatus": "wrong", "answer": "Where the survivor is assaulted repetitively"},
          {"answerStatus": "correct", "answer": "Where the survivor was able to escape an attack"}
          ] 
      },{
      "question": "Which of the following are examples of Rape?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Spouse rape"},
          {"answerStatus": "wrong", "answer": "Date rape"},
          {"answerStatus": "wrong", "answer": "Waking in a strange place after being pricked with signs of assault"},
          {"answerStatus": "correct", "answer": "All of the given options"}
          ] 
      },{
      "question": "When do orgasms occur?",
      "answers": [
          {"answerStatus": "wrong", "answer": "When someone want them to"},
          {"answerStatus": "wrong", "answer": "When someone is with a person they love"},
          {"answerStatus": "wrong", "answer": "When someone are enjoying sexual activities"},
          {"answerStatus": "correct", "answer": "When someone’s sexual organs are physically stimulated"}
          ] 
      },{
      "question": "Which of the following is NOT an example of ongoing assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Sex trafficking"},
          {"answerStatus": "wrong", "answer": "Spouse rape"},
          {"answerStatus": "wrong", "answer": "Sex slavery"},
          {"answerStatus": "correct", "answer": "Attempted rape"}
          ] 
      },{
      "question": "How might one try to avert an attempt to sexually assault them?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Run if you feel any fear"},
          {"answerStatus": "wrong", "answer": "Call for help"},
          {"answerStatus": "wrong", "answer": "Hit the attacker in a vulnerable area"},
          {"answerStatus": "correct", "answer": "All of the other options"}
          ] 
      }]
  },{
    "lesson": 6,
    "questions": [{
      "question": "If you feel threatened that someone is about to attack you, what should you do?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Just ignore it"},
          {"answerStatus": "wrong", "answer": "Ask the person what they want"},
          {"answerStatus": "wrong", "answer": "Take selfies"},
          {"answerStatus": "correct", "answer": "Move to an area with other people"}
          ] 
      },{
      "question": "Which is NOT a benefit of reporting an attack to the police straight away?",
      "answers": [
          {"answerStatus": "wrong", "answer": "They will able to get evidence such as DNA"},
          {"answerStatus": "wrong", "answer": "The attacker will still be wearing the same clothes"},
          {"answerStatus": "wrong", "answer": "You will remember more details"},
          {"answerStatus": "correct", "answer": "Because the attacker will be at the police station"}
          ] 
      },{
      "question": "What is the difference between rape and a covert assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "There is no difference, they are both the same."},
          {"answerStatus": "wrong", "answer": "You know when are about to be assaulted covertly"},
          {"answerStatus": "wrong", "answer": "You are not sure when you are getting raped"},
          {"answerStatus": "correct", "answer": "Rape is obvious when it starts but you might doubt a covert assault in the beginning"}
          ] 
      },{
      "question": "Why do some people get assaulted?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Because they deserve to be punished"},
          {"answerStatus": "wrong", "answer": "Because they asked for it"},
          {"answerStatus": "wrong", "answer": "Because of how they look"},
          {"answerStatus": "correct", "answer": "Because the attacker is evil"}
          ] 
      },{
      "question": "What are some methods to help survivors deal with trauma?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Private therapy sessions with a specialised counsellor"},
          {"answerStatus": "wrong", "answer": "Group therapy sessions with a specialised moderator"},
          {"answerStatus": "wrong", "answer": "Talking to someone trusted"},
          {"answerStatus": "correct", "answer": "All of the given options"}
          ] 
      }]
  },{
    "lesson": 7,
    "questions": [{
      "question": "How can friends help a survivor?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Threaten to kill the attacker"},
          {"answerStatus": "wrong", "answer": "Order them to go to the police"},
          {"answerStatus": "wrong", "answer": "Ignore them until they go away"},
          {"answerStatus": "correct", "answer": "Believe them and be there for them"}
          ] 
      },{
      "question": "Which is something parents of survivors should try NOT to do?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Believe them"},
          {"answerStatus": "wrong", "answer": "Be strong and calm in front of them"},
          {"answerStatus": "wrong", "answer": "Get support for yourself if you need it"},
          {"answerStatus": "correct", "answer": "Cry and lose your temper in front them"}
          ] 
      },{
      "question": "Which of the following is NOT a sign of abuse?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Flashbacks"},
          {"answerStatus": "wrong", "answer": "Drug abuse"},
          {"answerStatus": "wrong", "answer": "Depression"},
          {"answerStatus": "correct", "answer": "Sweet dreams"}
          ] 
      },{
      "question": "How do most survivors feel after an assault?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Confident"},
          {"answerStatus": "wrong", "answer": "Happy"},
          {"answerStatus": "wrong", "answer": "Powerful"},
          {"answerStatus": "correct", "answer": "Ashamed"}
          ] 
      },{
      "question": "Which of the following is NOT a sign of child abuse?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Show sexual behaviour which is age inappropriate"},
          {"answerStatus": "wrong", "answer": "Signs of trauma"},
          {"answerStatus": "wrong", "answer": "Self-harming behaviours"},
          {"answerStatus": "correct", "answer": "They feel happy and confident with other people"}
          ] 
      }]
  },{
    "lesson": 8,
    "questions": [{
      "question": "Which of the following will help keep you safe when you go out?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Studying hard"},
          {"answerStatus": "wrong", "answer": "Eat an apple"},
          {"answerStatus": "wrong", "answer": "Take a tissue"},
          {"answerStatus": "correct", "answer": "Watch your drink to prevent anyone from spiking it"}
          ] 
      },{
      "question": "Which of the following is NOT from the list: Keep yourself safe?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Stay in busy areas"},
          {"answerStatus": "wrong", "answer": "Don’t travel or go to gatherings alone"},
          {"answerStatus": "wrong", "answer": "Know how to use your phone’s emergency features"},
          {"answerStatus": "correct", "answer": "Travel alone at night"}
          ] 
      },{
      "question": "How can you help keep others safe?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Ignore everyone else"},
          {"answerStatus": "wrong", "answer": "Don’t tell your friends if you leave"},
          {"answerStatus": "wrong", "answer": "Get very drunk"},
          {"answerStatus": "correct", "answer": "Keep an eye on your friends"}
          ] 
      },{
      "question": "How can you prevent revenge porn?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Ask for images of others"},
          {"answerStatus": "wrong", "answer": "Share funny clips"},
          {"answerStatus": "wrong", "answer": "Make a video"},
          {"answerStatus": "correct", "answer": "Don’t let anyone take revealing pictures of you"}
          ] 
      },{
      "question": "If you see someone being assaulted what should you do?",
      "answers": [
          {"answerStatus": "wrong", "answer": "Call the police"},
          {"answerStatus": "wrong", "answer": "Try to stop it from happening"},
          {"answerStatus": "wrong", "answer": "Stay with the victim"},
          {"answerStatus": "correct", "answer": "All of the given options"}
          ] 
      }]
  }] 