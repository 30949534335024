import {LitElement, html} from 'lit'
import Auth from '../../Auth' // for user auth
import Data from '../../Data' // for app variables
import Utils from '../../Utils'
import Validate from '../../Validate'
import ThreadAPI from '../../api/ThreadAPI'
import PostAPI from '../../api/PostAPI'


customElements.define('new-post', class ForumComponents extends LitElement {
  constructor(){
    super()    
  }

  static get properties(){
    return {
      typeInfo: { type: String },
    }
  }


  firstUpdated(){
    super.firstUpdated()
    this.fontUpdate()
  }

  fontUpdate(){
    const fontPrefs = this.shadowRoot.querySelectorAll('.para')
    fontPrefs.forEach(fontPref => { 
      fontPref.style.fontSize = Utils.updatedfont 
    })
  }

  inputWarn(id, warning){
    const thisWarn = this.shadowRoot.getElementById(id + '-wrn')
    thisWarn.style.maxHeight = Math.max(thisWarn.scrollHeight) + "px"
    thisWarn.innerHTML = warning
  }

  removeWarn(id){
    const thisWarn = this.shadowRoot.getElementById(id + '-wrn')
    if(thisWarn.style.maxHeight == null){return}
    else{thisWarn.style.maxHeight = null}
  }

  clearWarning(e){
    e.preventDefault()
    const myInput = e.target.getAttribute('id')
    this.removeWarn(myInput)  
  } 

  async newPostHandler(){
    let validated = true
    const postinput = this.shadowRoot.getElementById("post")
    const subCheck = this.shadowRoot.getElementById("sub-check")
    if (postinput.value == "") {
      this.inputWarn(postinput.id, "*Required")
      validated = false
    } else {
      const pStatus = Validate.safeCheck(postinput.value)
      if (pStatus == false){
        this.inputWarn(postinput.id, "*No special characters")
        validated = false
      } else if (postinput.value.length < 1 || postinput.value.length > 3000 ){
        this.inputWarn(postinput.id, "*Enter 1 to 3000 characters")
        validated = false
      } 
    }
  
    if(validated == true){ 
      const mainTab = this.shadowRoot.getElementById('main-tab')
      const statusTab = this.shadowRoot.getElementById('status-tab')
      mainTab.classList.add('hidden')
      statusTab.innerHTML = '<c-waiting waitSource="forum-post"></c-waiting>'

      let newPost = {}
      let threadUpdate = {} //if new subscriber replyPost
      newPost.postText = JSON.stringify(postinput.value) 
      newPost.user = Auth.currentUser._id
      if(this.typeInfo == 'reply-post'){
        newPost.replyId = Data.myreplypost._id
        const replyFormatted = Utils.formatParagraphText(Data.myreplypost.postText, 'reply')
        newPost.replyShort = replyFormatted.slice(0, 30) + ' ...'
        newPost.threadId = Data.myreplypost.threadId
      } else {
        newPost.threadId = Data.myThread._id
      }
      if(subCheck.classList.contains('select-active')){
        const subbed = this.testSub() // check if user already subscribed
        if(subbed !== true){
          threadUpdate.subscriber = Auth.currentUser.email
          threadUpdate.id = Data.myThread._id
          Data.myThread.subscribers.push(Auth.currentUser.email) 
        }
      }
      try {
        const postObject = JSON.stringify(newPost);
        const registered = await PostAPI.newPost(Auth.currentUser.usergroup, postObject)
        if(threadUpdate.subscriber){
          const threadObject = JSON.stringify(threadUpdate);
          const registeredT = await ThreadAPI.newSubscriber(Auth.currentUser.usergroup, threadObject)
          if(registeredT == true){
            console.log('update subscribers success')
          }
        } 
        if(registered == false){
          statusTab.innerHTML = '<c-error errorSource="new-post"></c-error>'
        } else { 
          statusTab.innerHTML = '<c-success successSource="post"></c-success>'
        }
      }catch(err){
        console.log(err)
        statusTab.innerHTML = '<c-error errorSource="new-post"></c-error>'
      }
     }
  }

  
  subSelect(e){
    const selectnew = e.target.closest('.select-item')
    selectnew.classList.toggle('select-active')
  }
  
  testSub(){
    if(Data.myThread.subscribers.indexOf(Auth.currentUser.email) > -1){
      return true
    } else { return false }
  }


  render(){    
    return html`
    <style>      
    
      * { box-sizing: border-box; margin: 0px; padding: 0px; }

      .input-group { margin: 10px 0 20px 0; padding: 0; }
      .input-require {
        color: red;
        margin: 2px;
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: .3s;
      } 

      .agree-wrap{ margin: 20px 0; }

      .select-item {
        margin: 5px ;
        width: 90%;
        padding: 5px 5px 7px  30px ;
        position: relative;
        cursor: pointer;
      }
      .select-item:hover { color: var(--brand-color); }
      .select-active { font-weight: 300; }

      .select-active::before {
        content: '';
        position: absolute;
        width: 15px;
        height: 15px;
        top: 2px;
        left: 0;
        margin: 4px;
        border-radius: 500px;
        background-color: var(--brand-color);
      }

      .s-point {
        position: absolute;
        width: 23px;
        height: 23px;
        top: 2px;
        left: 0;
        border: solid 2px var(--disabled-color);
        border-radius: 500px;
      }

      .divide-line {
        width: 80%;
        margin: 30px auto;
        border-bottom: 1px solid var(--two-color);
      }

      .redirect-button {
        color: white;
        border: none;
        border-radius: 5px;
        padding: 7px;
        margin: 20px auto;
        text-decoration: none;
        width: 200px; 
        cursor: pointer;
        font-size: 1.1em;
        text-align: center;
      }

      .action { background-color: var(--action-color) ; }
      .forum-action-btn { transition: .4s; }
      .forum-action-btn:hover { background-color: rgb(243, 183, 18); }

      input, textarea, select { border: 2px solid var(--brand-color); border-radius: 5px; padding: .5em; width: 100%; 
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; }
      input:focus, input:active, textarea:focus, textarea:active, select:focus, select:active   { 
        outline: none; border: solid 2px var(--two-color); border-color: var(--two-color); }

      .hidden { display: none; }
      .para { font-size: 1.2em;  }

    
      
    /* ------------------- RESPONSIVE ------------------- */

    /* Mobile styles */
    @media all and (max-width: 350px){
      .select-item { width: 85%;}
    } /* Close - Mobile styles */
 
  </style>

      <div id="main-tab">
        <form id="new-form" method="post">

          <div class="input-group">
            <p id="post-wrn" class="input-require">*Post required</p>
            <textarea id="post" class="para" rows="5" cols="50" name="textPost" placeholder="Post text..."  @keyup=${this.clearWarning.bind(this)} maxlength="3000"></textarea>
          </div>

          <div class="agree-wrap">
            <div id="sub-check" class="select-item para select-active" @click="${this.subSelect}">
              I would like to recieve emails for new posts
            <div class="s-point"></div></div>
          </div>
          <div class="redirect-button forum-action-btn action" tabindex="0" @click=${this.newPostHandler.bind(this)}><strong>Add Post</strong></div>

          ${this.typeInfo == 'reply-post' ? html``:html `
            <div class="divide-line"></div>
          `}
        </form>
      </div>
      <div id="status-tab"></div>


    `
  }
  
})
