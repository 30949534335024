import App from '../../App'
import {render, html} from 'lit/html.js'


class SuccessPayView {
  init(){    
    document.title = 'Successful Payment'    
    this.render()  
  }


  render(){
    const template = html`
      <div class="sign-content v-center">     
        <div class="form-box"> 
          <c-success successSource="purchase"></c-success>
          <p>Please contact us if you have any payment queries.</p>
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new SuccessPayView()