import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class PreventView { 
  init(){    
    document.title = 'Prevention'  
    this.currentlesson = 8 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }



  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/8-together.svg"> 
          </div>
          <section class="lesson-section">

            <p class="app-info para">
              While it would be nice “in a perfect world” to have the freedom to do and wear what we want.
              But the truth is that we don’t live in that perfect world.  
              Rather, unfortunately we live in a world where too many evil people are harming the innocent.  
              Therefore, it is recommended to take precautionary steps to try and stay safe.
            </p>

            <p class="app-info para space-above">
              <strong>Keep yourself safe</strong>
              <ul class="para">
                <li>Stay in busy areas</li>
                <li>Don’t travel or go to gatherings alone</li>
                <li>Watch your drink and keep your hand over the top while not drinking </li>
                <li>Keep an eye out on strange behavior from others who might intend to prick someone</li>
                <li>Know how to use your phone’s emergency features</li>
                <li>Use a safety app</li>
                <li>Take self-defense training or rape defense classes</li>
                <li>Train yourself to react proactively to inappropriate behavior</li>
                <li>Do not sexually tease someone who has had too much to drink</li>
                <li>Don’t wear clothes which are too revealing</li>
                <li>Keep your car, home or dorm locked</li>
                <li>Report sexual harassment before it becomes more serious</li>
                <li>Avoid people who you don’t feel comfortable around</li>
                <li>Avoid areas where you don’t feel safe</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              At all times we should <strong>trust our instincts</strong> and if things don’t feel right, don’t take a chance. 
              Group up with others and make sure everyone stays safe.  
              If you see someone being assaulted, try to intervene if you feel it is appropriate, or call the police.
            </p>

            <p class="app-info para space-above">
              <strong>Keep others safe</strong>
              <ul class="para">
                <li>Keep an eye on your friends</li>
                <li>Be aware of the behavior of those around you</li>
                <li>If you suspect someone has been drugged, call the police immediately</li>
                <li>Stay with potential victims</li>
                <li>Speak out against all forms of sexual harassment </li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              <strong>Revenge Porn</strong>
              <ul class="para">
                <li>Don’t let anyone take revealing pictures of you</li>
                <li>Don’t give out personal details or share inappropriate content</li>
                <li>Screenshot any threats or online evidence</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
              <strong>Stay safe!</strong>
            </p>


          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div> 
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new PreventView() 