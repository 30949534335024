import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute } from '../../../Router'


class StatsUserMenuView {
  init(){    
    document.title = 'User Statistics'    
    this.render()  
  }
  
  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>

        <div class="content-panel">  
          
          <br>
          <stat-data-btn statType="users" statField="age" statTitle="Users" statName="Age Groups"></stat-data-btn>
          <stat-data-btn statType="users" statField="usergroup" statTitle="Users" statName="Gender"></stat-data-btn>
          <stat-data-btn statType="users" statField="countryCode" statTitle="Users" statName="Country"></stat-data-btn>

          <stat-data-btn statType="users" statField="cases" statTitle="Users" statName="No. Cases"></stat-data-btn>
          <br>
          <a href="/stats/main" class="forum-button forum-btn primary" @click=${anchorRoute}>
            Back to Main
            <img class="back-img" alt="back" src="./../images/icons/sw00-back.svg">
          </a>
      
        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new StatsUserMenuView()