import App from '../../App'
import {render, html} from 'lit/html.js'
import {navClick } from '../../Router'
import Auth from '../../Auth'
import Data from '../../Data'
import Utils from '../../Utils'


class HomeView {
  init(){    
    document.title = 'Home'    
    this.render() 
    Utils.initFont()   
  }

  
  moreless(){
    const spans = document.querySelectorAll('.moreless')
    spans.forEach(span => {
      span.classList.toggle('hidden')
    })
  }

  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="${document.title}"></main-app-header>
        <div class="content-panel">  

          <p class="app-info para moreless-parent">A new pandemic has spread throughout the world, but this time of a different nature.
            <span class="moreless left-space" @click="${this.moreless}">
             ...Read More
            </span> 

            <span class="moreless hidden">  
              Sexual assault in all its forms has reached unprecedented levels causing millions of victims to suffer in silence.
              At ${Data.apptitle}, we provide information and an anonymous forum for survivors to help each other through.
              <span class="left-space" @click="${this.moreless}">
              ...Show Less
              </span>
            </span>
          </p>
          <div class="app-info para space-rm">... Read More</div>

          <nav class="home-btn primary" nav-link="/info" @click="${navClick}">
            <div class="hbi-box">
              <img class="nav-img" alt="information icon" src="./../images/icons/sw02-info.svg">
            </div>
            <div class="hb-title">Information</div>
          </nav>
          
          <nav class="home-btn primary" nav-link="/lessons" @click="${navClick}">
            <div class="hbi-box">
              <img class="nav-img" alt="lessons icon" src="./../images/icons/sw03-lessons.svg">
            </div>
            <div class="hb-title">Lessons</div>
          </nav>

  <!-- Forum  -->
          ${Auth.currentUser.forumSubExpiry && Auth.currentUser.forumSubExpiry >= Auth.thisDay ? html` 
            <nav class="home-btn primary" nav-link="${Data.forumlink}" @click="${navClick}">
              <div class="hbi-box">
                <img class="nav-img" alt="forum icon" src="./../images/icons/sw01-forum.svg">
              </div>
              <div class="hb-title">Chat Forum</div>
            </nav>   <!-- forum unlocked -->
          `:html` 
            <nav class="home-btn disabled"  @click="${Utils.modalPforum.bind(Utils)}">
              <div class="hbi-box">
                <img class="nav-img" alt="forum icon" src="./../images/icons/sw01-forum.svg">
              </div>
              <div class="hb-title">Chat Forum</div>
              <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
            </nav>  <!-- Forum Locked -->
          `}

<!-- STATS  -->
          ${!Auth.currentUser.score && !Auth.currentUser.forumSubExpiry ? html` <!-- Locked -->
            <nav class="home-btn disabled" @click="${Utils.modalPstats.bind(Utils)}">
              <div class="hbi-box">
                <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
              </div>
              <div class="hb-title">Statistics</div>
              <img class="m-locked" alt="locked icon" src="/images/icons/${Data.appcode}03-unlock.svg">
            </nav>
          `:html` <!-- unlocked -->
            <nav class="home-btn primary" nav-link="/stats/main" @click="${navClick}">
              <div class="hbi-box">
                <img class="nav-img" alt="statistics icon" src="./../images/icons/sw09-stats.svg">
              </div>
              <div class="hb-title">Statistics</div>
            </nav>
          `}
          <side-widget></side-widget>
        </div> 
        
      </div>
    `

    render(template, App.rootEl)
  }
}

export default new HomeView()