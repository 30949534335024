import App from '../../../App'
import {render, html} from 'lit/html.js'
import { navClick } from '../../../Router'
import Auth from '../../../Auth'
import Data from '../../../Data'
import Utils from '../../../Utils'


class QuizzesView {
  init(){    
    document.title = 'Quizzes'    
    this.render()  
  }

  unlockQuizModal(){Utils.modalPrevQuiz() }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="Quizzes"></main-app-header>
        <feedback-widget class="feedback-widget"></feedback-widget>

        <div class="content-panel">   
          
          <nav class="home-btn primary" nav-link="/quiz/leaderboards" @click="${navClick}">
            <div class="hbi-box">
              <img class="nav-img" alt="Leader boards link" src="./../images/icons/sw07-leaderboards.svg">
            </div>
            <div class="hb-title">Leader boards</div>
          </nav>

          ${Data.lessons.map(alesson => html `
            ${alesson.lesson  == Auth.currentUser.quizLevel? html` 
              <nav class="quiz-btn action" nav-link="/quiz/lesson?level=${alesson.lesson}" @click="${navClick}">
                <img class="quiz-q" alt="Quiz link" src="./../images/icons/quiz-qu.svg">
                ${alesson.lesson} ${alesson.lessonTitle}
                <img class="quiz-next" alt="Quiz link" src="./../images/icons/sw10-next.svg">
              </nav>
            `:html`
              ${alesson.lesson  >= Auth.currentUser.quizLevel? html` 
                <nav class="quiz-btn disabled" @click="${this.unlockQuizModal}">
                  <img class="quiz-q" alt="Quiz link" src="./../images/icons/quiz-qu.svg">
                  ${alesson.lesson} ${alesson.lessonTitle}
                  <img class="quiz-next" alt="Quiz link" src="./../images/icons/sw10-next.svg">
                </nav>
              `:html` 
                <nav class="quiz-btn action" nav-link="/quiz/lesson?level=${alesson.lesson}" @click="${navClick}">
                  <img class="quiz-q" alt="Quiz link" src="./../images/icons/quiz-qu.svg">
                  ${alesson.lesson} ${alesson.lessonTitle}
                  <img class="quiz-next" alt="Quiz link" src="./../images/icons/tick-wh.svg">
                </nav>
              `}
            `}
          `)}

        </div> 
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new QuizzesView()