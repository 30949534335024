// TO DO
// 1) Remove all EventListeners in case of navigating! via Events
	// document.removeEventListener('keyup', this.signEnter) ...  
	// post view & sign view... (revise all forms to improve UX)

import Toast from './Toast'

// import MAIN views
import fourOFourView from './views/pages/404'
import homeView from './views/pages/1home'
import infoView from './views/pages/2info'
import settingsView from './views/pages/4settings'

// SIGN views = NO PREFIX PATH = OPEN    
import signupView from './views/pages/sign/1signup'
import signinView from './views/pages/sign/2signin'
import registerView from './views/pages/sign/3register'
import resetView from './views/pages/sign/5reset'
import tokenValidationView from './views/pages/sign/9token-validation'

// LESSON views = NO PREFIX PATH = OPEN    	
import lessonMenuView from './views/pages/lessons/a0lessonmenu'
import lessonOneView from './views/pages/lessons/a1Introduction'
import lessontwoView from './views/pages/lessons/a2consent'
import lessonthreeView from './views/pages/lessons/a3responses'
import lessonfourView from './views/pages/lessons/a4harrassment'
import lessonfiveView from './views/pages/lessons/a5assault'
import lessonsixView from './views/pages/lessons/a6todo'
import lessonsevenView from './views/pages/lessons/a7signs'
import lessoneightView from './views/pages/lessons/a8prevent'
import bibliographyView from './views/pages/lessons/a9bibliography'

// FORUM views - RESTRICTED  
import TopicsView from './views/pages/forum/1forumtopics'
import ThreadsView from './views/pages/forum/2forumthreads'
import PostsView from './views/pages/forum/3forumposts'
import NewthreadView from './views/pages/forum/4newthread'

// QUIZ views - RESTRICTED
import quizzesView from './views/pages/quiz/1quizmenu'
import quizView from './views/pages/quiz/2quiz'
import leaderboardsView from './views/pages/quiz/3leaderboards'
import leaderboardLevelView from './views/pages/quiz/4leaderboardlevels'

// STATISTICS views - RESTRICTED
import statisticsView from './views/pages/stats/1astatmenu'
import statsUserMenuView from './views/pages/stats/1b1usermenu'
import statsCaseMenuView from './views/pages/stats/1c1casemenu'
import userstatsView from './views/pages/stats/1b2userstats'
import casestatsView from './views/pages/stats/1c2casestats'
	//case form views
import newcaseView from './views/pages/stats/2anewcase'
import editcaseView from './views/pages/stats/2beditcase'

// USER views - RESTRICTED - to users being logged in ( but free access)
	//profile views
import profileView from './views/pages/user/1aprofile'
import paymentsView from './views/pages/user/1bpayments'
import progressView from './views/pages/user/1cprogress'
import mypostsView from './views/pages/user/1dmyposts'
import mycasesView from './views/pages/user/1emycases'
import editprofileView from './views/pages/user/1feditprofile'
	//purchase views views
import purchasesView from './views/pages/3apurchases' // not in user folder so all can access page
import checkoutView from './views/pages/3bcheckout'
import successPayView from './views/pages/3csuccesspay'

import newfeedbackView from './views/pages/user/3feedback'

import Auth from './Auth'

// define routes
const routes = {
	'404' : fourOFourView,
	'/': homeView,	// 1
	'/info': infoView, // 2
	'/settings': settingsView, // 4 
	'/purchases': purchasesView, // 3purchases  
	'/checkout': checkoutView, // 2bcheckout  
	'/success': successPayView, // 2csuccess

	// SIGN routes = NO PREFIX
	'/signup': signupView, // 1
	'/signin': signinView, // 2
	'/register': registerView, // 3 
	'/reset': resetView, // 5
	'/token-validation': tokenValidationView, // 9
	
	// LESSON views = NO PREFIX
	'/lessons': lessonMenuView,  // a0
	'/lesson/1': lessonOneView, // a1 
	'/lesson/2': lessontwoView, // a2consent
	'/lesson/3': lessonthreeView, // a3responses
	'/lesson/4': lessonfourView, // a4harrassment
	'/lesson/5': lessonfiveView, // a5assault
	'/lesson/6': lessonsixView, // a6todo
	'/lesson/7': lessonsevenView, // a7signs and advice
	'/lesson/8': lessoneightView, // a8prevent
	'/lesson/bibliography': bibliographyView, // a9bibliography 

	// FORUM views - RESTRICTED   - access
	'/forum/topics': TopicsView, // 1forumtopics
	'/forum/threads': ThreadsView, // 2forumthreads 
	'/forum/posts': PostsView, // 3forumposts
	'/forum/newthread': NewthreadView, // 4newthread


	// QUIZ views - RESTRICTED - access
	'/quiz/menu': quizzesView, // 1 menu
	'/quiz/lesson': quizView, // 2quiz
	// leaderboards - RESTRICTED via quiz
	'/quiz/leaderboards': leaderboardsView, // 3 - leader this user position
	'/quiz/leadermain': leaderboardLevelView, // 4 - leaderboardlevels

	// STATISTICS views - RESTRICTED - access
	'/stats/main': statisticsView, // 1amenu
	'/stats/usermenu': statsUserMenuView, // 1b1usermenu
	'/stats/casemenu': statsCaseMenuView, // 1b1usermenu
	'/stats/users': userstatsView, // 1b2userstats
	'/stats/cases': casestatsView, // 1b2userstats

	'/stats/newcase': newcaseView, // 2anewcase - accessed via /user/profile pages
	'/stats/editcase': editcaseView, // 2beditcase


	// USER routes - RESTRICTED  - must be logged in
	'/user/profile': profileView, // 1aprofile  
	'/user/payments': paymentsView, // 1bpayments 
	'/user/progress': progressView, // 1cprogress 
	'/user/posts': mypostsView, // 1dmyposts 
	'/user/cases': mycasesView, // 1emycases 
	'/user/editprofile': editprofileView, // 1feditprofile 
	'/user/feedback': newfeedbackView, // 3feedback


}

class Router {
	constructor(){
		this.routes = routes
		this.lastRoute = null
		this.authRequired = ["user", "stats", "quiz", "forum", "mod", "admin"]
	}
	
	
	init(){
		// initial call
		const newRoute = window.location.pathname
		if (this.lastRoute == newRoute){
			return
		} else {
			this.route(newRoute)
			this.lastRoute = newRoute
		}
		// on back/forward
		window.addEventListener('popstate', () => {
			this.route(window.location.pathname)
		})
	}
	
	async route(fullPathname){
		// extract path without params
		let pathname = fullPathname.split('?')[0]
		const accessPath = fullPathname.split('/')[1];
		//console.log(accessPath)
		//console.log(pathname)

		if(this.authRequired.includes(accessPath)){

			if(accessPath == 'user' && !Auth.currentUser._id) { 
				pathname = '/'
				Toast.show("Sign in to access page")
				window.history.pushState({}, pathname, window.location.origin + pathname);
				console.log('user redirected')
			}
			if(accessPath == 'quiz' && !Auth.currentUser.quizLevel) { 
				pathname = '/'
				window.history.pushState({}, pathname, window.location.origin + pathname);
				console.log('quiz redirected')
			}
			if(accessPath == 'forum' && (!Auth.currentUser.forumSubExpiry || Auth.currentUser.forumSubExpiry <= Auth.thisDay) ) { 
				pathname = '/'
				window.history.pushState({}, pathname, window.location.origin + pathname);
				console.log('forum redirected')
			}
			if(accessPath == 'stats' && !Auth.currentUser.quizLevel && !Auth.currentUser.forumSubExpiry ) { 
				pathname = '/'
				window.history.pushState({}, pathname, window.location.origin + pathname);
				console.log('stats redirected')
			}
			//window.history.pushState({}, pathname, window.location.origin + pathname);
		} 
		const route = this.routes[pathname]
		
		if(route){
			// if route exists, run init() of the view
			this.routes[pathname].init()
			
		}else{			
			// show 404 view instead
			this.routes['404'].init()			
		}
		const scroll = window.pageYOffset
		if (scroll > 20) {
		  window.scrollTo(0, 0)
		}
	}

	gotoRoute(pathname){
		window.history.pushState({}, pathname, window.location.origin + pathname);
		this.route(pathname)
	}	

	navClick(e){
		e.preventDefault()
		const pathname = e.target.closest('nav').getAttribute("nav-link")
		gotoRoute(pathname)
	}
}

// create appRouter instance and export
const AppRouter = new Router()
export default AppRouter


// programmatically load any route
export function gotoRoute(pathname){
	AppRouter.gotoRoute(pathname)
}

// programmatically load any nav-link route
export function navClick(e){
	AppRouter.navClick(e)
}


// allows anchor <a> links to load routes
export function anchorRoute(e){
	e.preventDefault()	
	const pathname = e.target.closest('a').pathname
	AppRouter.gotoRoute(pathname)
}

