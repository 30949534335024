import App from '../../../App'
import {render, html} from 'lit/html.js'
import Utils from '../../../Utils'


class SignsView { 
  init(){    
    document.title = 'Advice, Signs & Safe Environments'  
    this.currentlesson = 7 
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }



  render(){
    const template = html`
      
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">     
          <div class="lesson-title-box">${document.title}</div> 
          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/7-prevent.svg"> 
          </div>
          <section class="lesson-section">

            <h2 class="para">Advice for others</h2>

            <p class="app-info para">
            If someone you know has been assaulted, give them the support that they need.
            </p>

            <p class="app-info para">
            <strong>Advice for friends and family of survivors:</strong>
              <ul class="para">
                <li>Remain calm – they need your support</li>
                <li>Believe the survivor – and tell them that it is not their fault</li>
                <li>Give the survivor control – guide them to make the decisions they are comfortable with.</li>
                <li>Be supportive and respond appropriately to their reactions.  They may cry, scream, get angry or even stay silent.  Be there for them. Be there to listen.</li>
                <li>Assure they have your support.  They need to know that you will be there for them.</li>
                <li>Avoid making threats against the attacker as they may feel more threatened.</li>
                <li>Be patient. Healing can take a long time.</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
            <strong>Parents or caregivers of child survivors:</strong>
              <ul class="para">
                <li>Believe them.</li>
                <li>Be strong and calm in front of them.  They need your strength.</li>
                <li>Get support for yourself if you need it.</li>
                <li>Keep in mind that signs of abuse (as follows) are normal and children should not be punished for them.</li>
              </ul> 
            </p>


            <h2 class="para">Signs</h2>

            <p class="app-info para">
              If you see any of the following signs of abuse in someone, it may be that they have been a victim of assault or another traumatic experience.  
              Be gentle and try to find the underlying source of trouble so they can receive the help they need.  
              If you know a survivor who develops any of the following, this is most likely an indicator that they need trauma therapy.
            </p>

            <p class="app-info para">
            <strong>Signs of abuse:</strong>
              <ul class="para">
                <li>Drinking and drug abuse to numb the memories</li>
                <li>Pregnancy and abortion</li>
                <li>Rebellion and violence</li>
                <li>Nightmares and flashbacks</li>
                <li>Depression</li>
                <li>Eating disorders</li>
                <li>Low self-esteem / Bad self-image</li>
                <li>Anxiety</li>
                <li>Trouble concentrating</li>
                <li>Difficulty sleeping</li>
                <li>Amnesia (no memory of the event)</li>
                <li>Avoiding things associated with the abuse</li>
                <li>Don’t like to people to get too close</li>
                <li>Perfectionism </li>
                <li>Get angry easily (to hide worry)</li>
                <li>Feel nothing is safe</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
            <strong>Emotional signs of abuse:</strong>
              <ul class="para">
                <li>Shame and embarrassment</li>
                <li>Feeling crushed and helpless</li>
                <li>Guilt and self-blame</li>
                <li>Fear and distrust</li>
                <li>Sadness and lack of control</li>
                <li>Numbness and confusion</li>
                <li>Isolation and anger</li>
                <li>Shock and disbelief</li>
              </ul> 
            </p>

            <p class="app-info para space-above">
            <strong>Signs of child abuse:</strong>(in addition to the above points)
              <ul class="para">
                <li>Signs of trauma in the genital region (bleeding/bruising) or stained underwear </li>
                <li>Regressive behaviour like wetting the bed and sucking their thumb</li>
                <li>Drop of grades at school or decreased interest</li>
                <li>Keeping secrets</li>
                <li>Talk about sexual topics/behaviour which is age inappropriate</li>
                <li>Avoid removing clothing to bathe</li>
                <li>Self-harming behaviours</li>
                <li>Excessive fear</li>
              </ul> 
            </p>

            <p class="app-info para">
              *These signs do not mean that an abuse has definitely taken place. 
              Some signs could be related to other issues or exposure to other events.  
              For example sexual behaviour which is age inappropriate could also occur after viewing adult content on television.  
              Children also learn by copying others and may be imitating behaviours they have seen in someone else.  
              However, these signs should not be ignored.  
              Speak to a specialist for guidance on how to approach the situation. 
            </p>


            <h2 class="para">Safe environments</h2>

            <p class="app-info para">
            <strong>Protecting Children</strong>
              <ul class="para">
                <li>Teach your child about body boundaries.</li>
                <li>Teach your child how to talk about their bodies.</li>
                <li>Be available and give them freedom to talk to you about anything.</li>
                <li>Let them know they won’t get in trouble if they share their secrets with you.  Offenders often use secrets and threats to manipulate them.</li>
                <li>Beware of strange behaviour patterns in other adults.</li>
              </ul> 
            </p>

            <p class="app-info para">
              <strong>Engaged Bystanders</strong>
              Be an engaged bystander by intervening before, during, or after a situation when you see or hear behaviours that promote sexual violence. 
              “It is common for people to witness situations where someone makes an inappropriate sexual comment or innuendo, tells a rape joke, or touches someone in a sexual manner. 
              Bystanders may also witness other forms of sexual violence. 
              Bystanders who witness the behaviour or hear the comment, can intervene in a positive way that will help create a safer environment.”
            </p>


          </section>
          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new SignsView() 