import {LitElement, html} from 'lit'
import {navClick} from '../Router'
import Auth from '../Auth' // for user auth
import Data from '../Data' // for app variables
import Utils from '../Utils'

customElements.define('side-widget', class AppHeader extends LitElement {
  constructor(){
    super()    
  }


  firstUpdated(){
    super.firstUpdated()
  }


  render(){    
    return html`
      <style>      
      
        * { box-sizing: border-box; margin: 0px; padding: 0px; }

        .widget-link {
          position: relative;
          display: flex; 
          align-items: center; 
          justify-content: center; 
          transition: border .5s, color .5s;
          width: 150px;
          border: solid 1px white;
          cursor: pointer;
        }
        
        .widget-link:hover { border: solid 1px var(--two-color); }
        .widget-center { text-align: center; }
        .disabled { color: var(--disabled-color); }
        .disabled:hover { color: #949393; }

        .icon-circle {
          border-radius: 500px;
          display: flex; 
          align-items: center; 
          justify-content: center;
          margin: auto;
        }

        .ic-active { background-color: var(--brand-color); }
        .ic-disabled { background-color: var(--disabled-color); }
        .active { color: var(--brand-color); }
        .active:hover { color: var(--two-color); }
        .m-locked { position: absolute; }

        @media all and (max-width: 768px){       
          .widget-box { 
            display: flex; 
            justify-content: space-around;
            margin: auto;
            width: 300px;
          }

          .widget-link {
            height: 80px;
            border-radius: 20px;
            margin-top: 5px; 
            box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
            width: 130px;
            margin: 5px 0;
          }

          .icon-circle {
            height: 48px;
            width: 48px;
            margin-bottom: 3px;
          }

          .circle-icon { width: 35px; height: 35px; padding-top: 2px; }
          .widget-icon { width: 46px; height: 46px; padding: 0;}
          .m-locked {
            width: 30px;
            top: 10px;
            right: 10px;
          }

        }

        @media all and (min-width: 769px){  

          .widget-box {
            position: fixed;
            bottom: 10vh;
            right: -10px;
            align-items: left;
            width: 150px;
          }

          .widget-link {
            height: 130px;
            border-radius: 20px 0 0 20px;
            margin-top: 20px; 
            box-shadow: -4px 4px 6px rgba(0,0,0,0.3);
            width: 150px;
          }

          .widget-center { padding-right: 10px; }

          .icon-circle {
            height: 60px;
            width: 60px;
          }
          .circle-icon { max-width: 40px; max-height: 40px; }
          .widget-icon { max-width: 60px; max-height: 60px; }
          .m-locked {
            width: 35px;
            top: 10px;
            right: 20px;
          }

        }

      </style>
      
      <aside class="widget-box">
        ${Auth.currentUser.quizLevel ? html` <!-- A) user has access --> 
          <nav class="widget-link active" nav-link="/quiz/leaderboards" @click="${navClick}">
            <div class="widget-center"> 
              <div class="icon-circle ic-active">
                <img class="circle-icon" alt="Leader boards Link" src="/images/icons/sw07-leaderboards.svg">
              </div>
              <p class="widget-text">Leader boards</p>
            </div>
          </nav>

          <nav class="widget-link active" nav-link="/quiz/menu" @click="${navClick}">
            <div class="widget-center"> 
              <img class="widget-icon" alt="Quiz Link" src="/images/icons/quiz-active-${Data.appcode}.svg">
              <p class="widget-text">Quizzes</p>
            </div>
          </nav>

        `:html` <!-- NO access -->
          <nav class="widget-link disabled" @click="${Utils.modalPquizlead.bind(Utils)}" >
            <div class="widget-center"> 
              <div class="icon-circle ic-disabled">
                <img class="circle-icon" alt="Leader boards Link disabled" src="/images/icons/sw07-leaderboards.svg">
              </div>
              <p class="widget-text">Leader boards</p>
              <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
            </div>
          </nav>

          <nav class="widget-link disabled" @click="${Utils.modalPquizlead.bind(Utils)}">
            <div class="widget-center"> 
              <img class="widget-icon" alt="Quiz Link" src="/images/icons/quiz-disabled.svg">
              <p class="widget-text">Quizzes</p>
              <img class="m-locked" alt="locked" src="/images/icons/${Data.appcode}03-unlock.svg">
            </div>
          </nav>
        `}



      </aside>

    `
  }
  
})
