import App from '../../../App'
import {render, html} from 'lit/html.js'
import {anchorRoute} from '../../../Router'
import Utils from '../../../Utils'


class IntroView {
  init(){    
    document.title = 'Introduction'  
    this.currentlesson = 1
    this.nextlesson = this.currentlesson + 1
    this.render()  
    Utils.initFont()  
  }


  render(){
    const template = html`
      <div class="main-content">
        <main-app-header pagename="Lesson ${this.currentlesson}"></main-app-header>
        <div class="content-panel">      
          <div class="lesson-title-box">${document.title}</div> 

          <div class="lesson-image-box">
            <img class="li-img" src="./../images/lessons/1-about.svg">
          </div>

          <section class="lesson-section">
            <p class="app-info para">
              <i> <strong>Disclaimer:</strong> Terms and definitions may vary from country to country.  If necessary, check the legal definitions and laws in your country or state.
              </i>
            </p>

            <p class="app-info para">
              Unfortunately, abuse is on the rise worldwide. Therefore, this conversation has become necessary so we can educate our families and help our survivors.
            </p>

            <p class="app-info para">
              Throughout these lessons we refer to a survivor as someone who has lived through any form of sexual assault.  It is a crime that has been done to them and they shouldn’t be ashamed. But in reality, we feel it is the most shameful thing in the world, and that is only natural.
            </p>

            <p class="app-info para">
              Sexual abuse, sexual assault and sexual violence (or whatever you want to name it) is never acceptable. 
              No one deserves to be treated with such humiliation… ever.  
              Whether someone is dressed modestly at work or naked on a hippy ranch, no one has any right to tough them except as they permit 
              (see <a href="/lesson/2" @click="${anchorRoute}">consent</a> section).  
              Although one must admit, it would be difficult for a little chubby lolly lover, not to eat from the bowl of sweets next to him if no one else was watching 
              (see <a href="/lesson/8" @click="${anchorRoute}">prevention</a> section).  
            </p>

            <p class="app-info para space-above">
              <strong>There are many ways sexual assault occurs:</strong>
              <ul class="para">
                <li>
                  It could be with the survivor’s permission such as a minor who doesn’t realize what is happening, or against their will such as rape.
                </li>
                <li>
                It could be by force or by coercion where the survivor is threatened.
                </li>
                <li>
                It could be overt and obvious like reaching out to grope, or it could be covert and hidden such as someone pretending not to realize where their hand is.
                </li>
                <li>
                It could be without the survivor being aware, such as the case with prickings and spiked drinks.
                </li>
                <li>
                It could even be sharing a revealing image or video referred to as revenge porn.
                </li>
                <li>
                And it could even be someone showing their private parts to you.
                </li>
              </ul> 
            </p>


            <p class="app-info para">
            <strong>But ALL of it is criminal and punishable by law.</strong>
            </p>

            <p class="app-info para">
              Even if the survivor didn’t say no or don’t try to stop them, it still sexual assault.  
              In the case where the survivor freezes, they are unable to respond as they want to 
              (See <a href="/lesson/3" @click="${anchorRoute}">response</a> section). 
              Furthermore, in the case of Major Sexual Assault it is a natural reaction for the body to orgasm in response to prolonged physical stimulation. 
              Survivors should never blame themselves or their bodies for how they react; it was a crime against their will.
            </p>

            <p class="app-info para space-above">
            <strong>But who would do such a thing?</strong>
            </p>
            <p class="app-info para">
              <ul class="para">
                <li>
                The perpetrator (attacker) might be someone you know like a relative or it could be someone unknown from the internet or a stranger on the street.              
              </li>
                <li>
                It could be a teacher, religious leader, doctor, nurse, your boss at work or even your partner.                
              </li>
                <li>
                The perpetrator might be young or old.
                </li>
                <li>
                And the perpetrator could be any gender attacking a survivor of any gender.
                </li>
              </ul>
            </p>

            <p class="app-info para">
              Anyone could potentially be a perpetrator (attacker). 
            </p>

            <p class="app-info para">
              Throughout the following lessons we will cover some more examples and what survivors might do to try and stop the assault or prevent it from happening.  
              However, sometimes it isn’t possible, in which case we move onto the healing process.
            </p>

          </section>

          <lesson-nav lessonnumber=${this.currentlesson} nextlesson=${this.nextlesson}></lesson-nav>
        </div>  
      </div> 
    `

    render(template, App.rootEl)
  }
}

export default new IntroView()